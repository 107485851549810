import React, { useEffect, useState } from 'react';
import { HiMiniChevronUp } from 'react-icons/hi2';
import { useOrderTypeContext } from '../../Contexts/OrderTypeContext';

const ButtonScrollToTop = () => {

    const [isVisibleTopButton, setIsVisibleTopButton] = useState(false);
    const { orderType } = useOrderTypeContext();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const toggleVisibilityTopButton = () => {
        if (window.scrollY > 500) {
            setIsVisibleTopButton(true);
        } else {
            setIsVisibleTopButton(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibilityTopButton);
        return () => {
          window.removeEventListener('scroll', toggleVisibilityTopButton);
        };
    }, []);

    return (
        <button
            type="button"
            onClick={scrollToTop}
            className={`
            fixed z-10 right-6 rounded-full p-1 
            shadow-sm ring-4 text-gray-300 ring-gray-200 md:hover:ring-gray-300 active:ring-gray-400 
            transition-color ease-in-out duration-200
            ${isVisibleTopButton ? 'opacity-100' : 'opacity-0'}
            ${orderType === 'store_counter' ? 'bottom-20 md:bottom-10' : 'bottom-36 md:bottom-8'}
            `}
            >
            <HiMiniChevronUp aria-hidden="true" size={25} />
        </button>
    );
};

export default ButtonScrollToTop;
