import React, { useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { MdOutlineDeliveryDining } from 'react-icons/md';
import { TbBuildingSkyscraper, TbHash, TbMapPin, TbShoppingBagPlus, TbTicket, TbZoom } from 'react-icons/tb';
// import CartValueDetails from './CartValueDetails';
import CardProductCart from './CardProductCart';
import CartEmpty from './CartEmpty';
import Button from '../../Form/Button';
import ButtonPrimary from '../../Form/ButtonPrimary';
import { useOrderTypeContext } from '../../../Contexts/OrderTypeContext';
import Drawer from '../Drawer';
import Badge from '../Badge';
import { HiXMark } from 'react-icons/hi2';
import CardCoupon from './CardCoupon';
import { useLocation, useNavigate } from 'react-router-dom';
import CardProductSuggestion from './CardProductSuggestion';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { Autoplay, Keyboard, Mousewheel, Navigation, Pagination } from 'swiper/modules';

interface CartProps {
    cart: any;
    onCloseCart: CallableFunction;
}

const Cart = ({cart, onCloseCart}: CartProps) => {

    const [showCoupon, setShowCoupon] = useState(false)
    const [searchCoupon, setSearchCoupon] = useState('')
    const { setShowOrderTypeChoice } = useOrderTypeContext();

    const swiperRef = useRef<SwiperRef>(null);
    // const swiperRefPrincipal = useRef<SwiperRef>(null);

    const handleDeleteProduct = (product: any) => {
        console.log('handleDeleteProduct', product.id)
    }
    const handleDec = (product: any) => {
        console.log('handleDec', product)
    }
    const handleInc = (product: any) => {
        console.log('handleInc', product)
    }
    const handleSearchCoupon = () => {
        setSearchCoupon('')
        setShowCoupon(false)
    }
    const handleCancelSearchCoupon = () => {
        setSearchCoupon('')
        setShowCoupon(false)
    }

    const navigate = useNavigate()
    const location = useLocation()

    const [isCheckout] = useState(location.pathname === '/checkout')

    const handleCheckout = () => {
        onCloseCart(false)
        navigate('/checkout')
    }

    const handleResetCart = () => {
        alert('resetCart')
    }

    return (
        <section className='p-4 pt-0 h-full'>

            {cart.empty 
            ?
                <CartEmpty onCloseCart={() => onCloseCart(false)} />
            :
                <section className="flex flex-col justify-between h-full gap-4">
                    <div className='flex flex-col gap-3 w-full h-full md:overflow-y-auto'>

                        {!isCheckout && 
                            <>
                                <div className='flex items-center gap-2 justify-center w-full'>
                                    <div className="w-full flex text-xs justify-between gap-0.5 flex-col">
                                        <div className="flex gap-0.5 w-full items-center">
                                            <TbMapPin />
                                            <span>Praia de Botafogo, 300 <span className="hidden md:inline">- 22250040</span></span>
                                            {/* Clique aqui e informe seu endereço */}
                                        </div>
                                        <div className="flex items-center gap-0.5 text-nowrap items-center font-bold">
                                            <TbBuildingSkyscraper />
                                            <span>Bob's Praia de Botafogo</span>
                                        </div>
                                    </div>

                                    <div className='flex flex-col gap-0.5 text-xs justify-center items-center mr-0.5'>
                                        <div className="flex gap-0.5 text-nowrap items-center">
                                            Pedido nº <span className='font-bold'>{cart.id}</span>
                                        </div>
                                        <div>
                                            <Button onClick={() => setShowOrderTypeChoice(true)} className='flex items-center gap-0.5 h-2.5'>
                                                <MdOutlineDeliveryDining size={16} />
                                                Delivery
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex flex-col items-center gap-0.5 my-2'>
                                    <div className="overflow-hidden rounded-full bg-gray-100 shadow-inner h-1 w-full">
                                        <div style={{ width: `43.1%` }} className="h-full rounded-full bg-custom-primary" />
                                    </div>
                                    <div className='text-[11px] text-gray-400 w-full'>Faltam R$ 3,00 para o pedido mínimo</div>
                                </div>
                            </>
                        }

                        <div className='h-full min-h-32 gap-10'>
                            <Scrollbars autoHide={true}>
                                <div className='flex flex-col gap-1 divide-y divide-gray-100'>
                                    {cart.products.map((product: any, index: number) => 
                                        <CardProductCart product={product} onDec={handleDec} onInc={handleInc} onDelete={handleDeleteProduct} key={index} />
                                    )}
                                </div>

                                {/* PEÇA TAMBÉM */}
                                {!isCheckout && 
                                <div className='grid p-3 mt-4 gap-3 bg-gray-50 rounded-xl'>
                                    <div className='text-xs font-medium'>Peça também</div>
                                    {/* <section className='grid grid-cols-3 gap-3 transition-width ease-in-out duration-200'>
                                        {cart.products.map((product: any, index: number) => 
                                            <CardProductSuggestion product={product} key={index} />
                                        )}
                                    </section> */}


                                    <Swiper
                                        ref={swiperRef}
                                        spaceBetween={0}
                                        modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
                                        slidesPerView={3}
                                        centeredSlides={false}
                                        keyboard={{enabled: true}}
                                        className='grid w-full swiperBullets swiperNavigation'
                                        onSlideChange={(swiper: any) => {
                                            // setCurrentSwiperSlide(swiper.activeIndex)
                                            // handleChangeSlide(swiper.activeIndex)
                                        }}
                                        autoplay={{
                                            delay: 3500,
                                            disableOnInteraction: true,
                                        }}
                                        pagination={false}
                                        navigation={false}
                                        >
                                        {
                                            cart.products.map((product: any, index: number) => 
                                                <SwiperSlide
                                                    key={index} data-hash={`slide1${index}`}
                                                    className='p-1 h-full'
                                                >
                                                <CardProductSuggestion product={product} key={index} />
                                            </SwiperSlide>
                                            )
                                        }
                                    </Swiper>
                                </div>
                                }



                                




                            </Scrollbars>
                        </div>

                        
                        {/* LIMPAR */}
                        <button onClick={() => handleResetCart()} className="p-2 text-xs font-medium w-full text-right text-gray-400 hover:text-custom-primary transition-all ease-in-out duration-200">
                            Limpar carrinho
                        </button>


                    </div>



                    <div className="grid gap-4 w-full">

                        <section className='grid gap-4'>

                            <Button onClick={() => setShowCoupon(true)} className='gap-2 justify-between text-sm items-center text-nowrap'>
                                <span className="flex items-center gap-1 w-full">
                                    <TbTicket size={20} />
                                    {cart.coupon ? 'Cupom aplicado' : 'Cupom de desconto'}
                                </span>
                                {!cart.coupon 
                                    ?
                                        <Badge className='text-xs flex items-center gap-1 rounded-full -mr-1' type='required'>
                                            {cart.coupon}
                                            <HiXMark aria-hidden="true" type='required' />
                                        </Badge>
                                    :
                                        <TbZoom size={20} />
                                }
                            </Button>

                            <Drawer 
                                open={showCoupon} 
                                title='Cupom de desconto' 
                                subtitle='Lorem ipsum dolor sit amet consectetur' 
                                backdrop={true} 
                                onClose={handleCancelSearchCoupon}
                                >
                                <div className='flex flex-col h-full gap-2 bg-gray-50'>
                                    <div className="flex w-full gap-2 bg-gray-100 p-6">
                                        <div className="relative flex w-full items-center">
                                            <TbHash size={20} className='absolute left-3 text-gray-400' />
                                            <input
                                                id="searchCoupon"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchCoupon(e.target.value)}
                                                name="search"
                                                value={searchCoupon}
                                                type="text"
                                                placeholder="Digite o código do cupom"
                                                className="
                                                    flex w-full rounded-lg
                                                    text-lg
                                                    border-0 
                                                    px-4 py-2.5 pl-10 pr-28 
                                                    font-extrabold
                                                    placeholder:text-gray-400
                                                    placeholder:font-normal 
                                                    ring-1
                                                    ring-gray-200
                                                    outline-none
                                                    focus:ring-custom-primary 
                                                    focus:ring-2 
                                                    focus:outline-none 
                                                    transition-all ease-in-out duration-200"
                                            />
                                            <Button 
                                                onClick={handleSearchCoupon}
                                                className={`absolute right-1 text-custom-primary items-center text-sm rounded-lg ring-0
                                                transition 
                                                ${searchCoupon ? 'opacity-100' : 'opacity-0'}
                                            `}>Adicionar</Button>
                                        </div>
                                    </div>

                                    <div className='h-full'>
                                        <Scrollbars autoHide={true}>
                                            <div className='grid md:grid-cols-2 p-4 px-6 gap-4'>
                                                {cart.coupons_available.map((coupon: any, index: number) => 
                                                    <CardCoupon coupon={coupon} onClick={() => setShowCoupon(false)} key={index} />
                                                )}
                                            </div>
                                        </Scrollbars>
                                    </div>


                                </div>
                            </Drawer>

                            {/* <CartValueDetails cart={cart.values} /> */}
                        </section>

                        {!isCheckout &&
                            <div className='flex gap-2'>
                                <Button onClick={() => onCloseCart(false)} className='justify-center items-center text-sm text-nowrap gap-0.5'>
                                    <TbShoppingBagPlus size={20} />
                                </Button>
                                <ButtonPrimary onClick={() => handleCheckout()} className='justify-center items-center w-full text-sm'>
                                    Escolha a forma de pagamento
                                </ButtonPrimary>
                                <Button onClick={() => onCloseCart(false)} className='justify-center items-center text-nowrap text-sm'>
                                    <HiXMark size={20} />
                                </Button>
                            </div>
                        }
                    </div>
                </section>
            }
        </section>
    );
};

export default Cart;
