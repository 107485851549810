import React from 'react';
import { Link } from 'react-router-dom';
import { useCartContext } from '../../Contexts/CartContext';
import { formatCartId } from '../../Utils/Formatters';
import { TbHash } from 'react-icons/tb';
import { useLayoutContext } from '../../Contexts/LayoutContext';
import Badge from './Badge';

interface HeaderMinimalProps {
    title?: string;
    subtitle?: string;
}

const HeaderMinimal = ({title, subtitle} : HeaderMinimalProps) => {

    const { cartDisplay } = useCartContext();
    const { company } = useLayoutContext();

    return (
        <>
            {/* HEADER */}
            <header className="flex flex-col w-full justify-between sticky top-0 bg-custom-header z-30 text-custom-header-text">
                <section className='flex p-4 justify-center ring-1 ring-gray-100'>
                    <div className="flex items-center justify-between w-full md:max-w-[1460px] gap-4 md:gap-6">

                        {/* LOGO */}
                        <Link to={'/'}>
                            <img src={company?.vector_image ?? company?.image} alt="" className="min-w-20 w-20 sm:w-24 md:28 transition-width ease-in-out duration-200" />
                        </Link>

                        {(title || subtitle) && 
                            <div className="flex flex-col text-sm leading-4 flex-grow">
                                {title && <h2 className="text-lg font-bold">{title}</h2>}
                                {subtitle && <span className="text-gray-400">{subtitle}</span>}
                            </div>
                        }

                        { cartDisplay && <Badge type="custom" className='text-sm'><TbHash />{formatCartId(cartDisplay.id)}</Badge> }

                    </div>
                </section>
            </header>
        </>
    )
}

export default HeaderMinimal;
