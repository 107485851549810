import React from 'react';
import ButtonsIncDec from '../ButtonsIncDec';
import { TbMessage2Plus, TbMessage2Question } from "react-icons/tb";
import { formatCurrency } from '../../../Utils/Formatters';

interface CardProductCartConferenceProps {
  product: any;
  onInc: CallableFunction;
  onDec: CallableFunction;
  onDelete?: CallableFunction;
  showButtonsIncDec?: boolean
  onCardClick?: CallableFunction;
}

const CardProductCartConference = ({
  product,
  onInc = () => {},
  onDec = () => {},
  onDelete = () => {},
  onCardClick = () => {},
  showButtonsIncDec = true,
}: CardProductCartConferenceProps) => {

  const handleDelete = () => {
    onDelete()
  }
  const handleDec = (quantity: number) => {
    onDec(quantity)
  }
  const handleInc = (quantiy: number) => {
    onInc(quantiy)
  }

  return (
    <div className='flex justify-between items-center gap-2 py-3'>

      <div className='flex items-center w-full gap-2'>
        <div className={`flex flex-col w-full ${onCardClick ? 'cursor-pointer' : ''}`} onClick={() => onCardClick()}>
          <span className='leading-4 font-semibold leading-4'>{product.name}</span>
          {product.steps &&
            <div className='pt-1 pb-0.5'>
              {product.steps.map((step: any, index: number) => 
                <div key={`${step.id}_${index}`}>
                  {
                    step.complements.map((complement: any, index: number) => 
                      <div className='flex gap-1 text-gray-400 md:text-sm leading-4' key={index}>
                          <span>{complement.quantity}x</span>
                          <span>
                            <span className='mr-1'>{complement.name}</span>
                            <span>({formatCurrency(complement.price)})</span>
                          </span>
                      </div>
                    )
                  }
                  {/* <div className='text-nowrap text-gray-500 md:text-sm mb-0.5'>
                    { step.complements.length > 1 &&
                      //* Exibindo valor do produto composto (com base nos complementos)
                      formatCurrency(getCalculateTotalPriceFromComplements(step.complements) * product.quantity)
                    }
                  </div> */}
                </div>
              )}
            </div>
          }
          {!product.steps &&
            //* Exibindo valor do produto simples
            <div className='text-nowrap text-gray-500 md:text-sm mt-0.5'>
              {formatCurrency(product.price * product.quantity)}
            </div>
          }

          <div className={`flex gap-1 text-sm ${product.observation ? 'font-medium' : 'text-gray-300'}`}>
            <div className='md:mt-0.5'>{product.observation ? <TbMessage2Question /> : <TbMessage2Plus />}</div>
            <span className='leading-4'>{product.observation ? product.observation : 'Observação'}</span>
          </div>

        </div>

        {showButtonsIncDec &&
          <div className='text-sm'>
            <ButtonsIncDec
              onDec={handleDec}
              onInc={handleInc}
              onDelete={handleDelete} showDeleteOnZero quantityMin={0} initialQuantity={product.quantity} 
              product={product}
            />
          </div>
        }
      </div>
  </div>
  )
}

export default CardProductCartConference;