import React, { useCallback, useEffect, useState } from 'react';
import ButtonsIncDec from '../ButtonsIncDec';
import { useLoginContext } from '../../../Contexts/LoginContext';
import { useCartContext } from '../../../Contexts/CartContext';
import DOMPurify from 'dompurify';
import Img from '../Img';
import ProductPrice from './ProductPrice';
import Badge from '../Badge';
import { TbCircleCheckFilled } from 'react-icons/tb';
import Modal from '../Modal';
import ButtonClose from '../ButtonClose';
import Button from '../../Form/Button';

interface SlotStepProductProps {
  product: any;
  complement: any;
  className?: string;
  step?: any;
}

type TriggerInc = () => void;

const SlotStepProduct = ({
  product,
  complement,
  className,
  step

}: SlotStepProductProps) => {

  const { updateCurrentSteps, getTotalSelectedComplements, cartDisplay } = useCartContext();
  const { token, setShowBasicLogin } = useLoginContext();
  const [ triggerInc, setTriggerInc ] = useState<TriggerInc | null>(null);
  const [ enlargedProduct, setEnlargedProduct ] = useState<any | null>(null);
  
  const handleClick = () => {
    if (!token) {
      setShowBasicLogin(true);
      return;
    }
    if (step?.type === 'unica') {
      handleComplement();
    }
    triggerInc?.();
  }

  const handleIncOutside = useCallback((method: TriggerInc) => {
    setTriggerInc(() => method);
  }, []);

  const [ forcedQuantity, setForcedQuantity ] = useState<number | null>(null);
  const handleComplement = (quantity: number = 1) => {
    setForcedQuantity(quantity);
    updateCurrentSteps(step.id, complement.id, quantity, step.type)
  }

  const [complementDescription, setComplementDescription] = useState('');
  
  useEffect(() => {
    setComplementDescription(DOMPurify.sanitize(enlargedProduct?.description));
  }, [enlargedProduct]);

  const sanitizeText = (text: string) => {
    return DOMPurify.sanitize(text);
  }

  return (
    <>
      <section className='relative flex items-center justify-between'>
        <label 
          htmlFor={complement.id}
          onClick={handleClick}
          className={`
            flex items-center w-full p-3 gap-2 justify-between hover:ring-gray-200 hover:rounded-lg ring-inset ring-inset
            active:ring-2 active:rounded-lg active:ring-custom-primary transition-all ease-in-out duration-200 cursor-pointer
            ${(!cartDisplay || (step.type === 'unica' && complement.quantity > 0) || (step.type === 'multipla' && getTotalSelectedComplements(step.id) >= step.quantity)) && 'pointer-events-none'}
            ${className}
          `} 
        >
          <div className={`grid gap-0.5 w-full ${cartDisplay && 'pr-14'}`}>
            <h4 className='font-bold leading-4'>{complement.name}</h4>
            <div className='text-sm text-gray-400' dangerouslySetInnerHTML={{ __html: sanitizeText(complement.description) }} />
            <div className='text-sm text-gray-500 font-medium flex items-center gap-1'>
              <ProductPrice product={complement} />
              {(complement.from_price > 0 && complement.from_price !== product.from_price) && <Badge type='success' outline className='leading-3 rounded-full'>Promoção</Badge>}
            </div>
          </div>
        </label>

        {complement.image &&
          <div className={`${cartDisplay ? 'pr-14' : 'p-1 pr-4'}`} onClick={() => setEnlargedProduct(complement)}>
            <Img src={complement.image}  alt={product.name}  className="w-full max-w-16 max-h-16 rounded cursor-pointer transition-all hover:opacity-80" />
          </div>
        }

        {cartDisplay &&
          <div className="absolute right-3 flex items-center justify-center">
            {step.type === 'unica' 
              ? 
                complement.quantity > 0 
                  ? <TbCircleCheckFilled size={20} className='text-custom-primary -mr-0.5 cursor-pointer' onClick={() => handleComplement()} />
                  : <input id={complement.id} name={step.id} type="radio" onChange={() => handleComplement()} />
              : null
            }
            {step?.type === 'multipla' &&
              <ButtonsIncDec
                onInc={handleComplement} onDec={handleComplement}
                quantityMin={0}
                disabledInc={getTotalSelectedComplements(step.id) >= step.quantity}
                showTrash
                className='absolute z-10 right-0 text-sm h-8'
                onClickIncOutside={handleIncOutside}
                forcedQuantity={forcedQuantity}
              />
            }
          </div>
        }
      </section>

      {enlargedProduct &&
        <Modal open={enlargedProduct} className="w-auto max-w-sm m-4 p-2" onClose={() => setEnlargedProduct(null)}>
          <ButtonClose onClick={() => setEnlargedProduct(null)} />
          <div className='flex flex-col gap-2 justify-center items-center'>
            <Img src={enlargedProduct.image} alt={enlargedProduct.name} className='w-full rounded' />

            <div className='flex gap-2 items-center justify-between w-full'>
              <div className='flex flex-col text-left w-full p-2'>
                <span className='font-bold leading-5'>{enlargedProduct.name}</span>
                <span className='text-gray-500 leading-5 text-sm' dangerouslySetInnerHTML={{ __html: complementDescription }} />
                <span className='text-sm text-gray-500 leading-5 font-medium mt-0.5'><ProductPrice product={enlargedProduct} /></span>

                {cartDisplay &&
                  <div className="flex gap-4 items-center justify-end mt-4">
                    {step?.type === 'multipla' &&
                      <>
                        <ButtonsIncDec
                          onInc={handleComplement} onDec={handleComplement}
                          quantityMin={0}
                          disabledInc={getTotalSelectedComplements(step.id) >= step.quantity}
                          showTrash
                          className='text-sm h-8'
                          forcedQuantity={forcedQuantity}
                          />
                          <Button onClick={() => setEnlargedProduct(false)} className={`text-sm`}>Fechar</Button>
                      </>
                    }
                  </div>
                }
              </div>
            </div>
          </div>
        </Modal>
      }
    </>
  )
}

export default SlotStepProduct;