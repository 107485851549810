import React from 'react';
import { TbInfoSquareRounded } from 'react-icons/tb';
import ButtonPrimary from '../Form/ButtonPrimary';

interface ConfirmProps {
    onConfirm: CallableFunction;
    onCancel: CallableFunction;
    title: string;
    message?: any;
    labelConfirm?: string;
    labelCancel?: string;
  }

  const Confirm = ({
    onConfirm = () => {},
    onCancel = () => {},
    title,
    message,
    labelConfirm = "Sim",
    labelCancel = "Cancelar",
  }: ConfirmProps) => {

    return (
      <section className='flex flex-col items-center w-full p-6 gap-8'>
        <h4 className='flex flex-col items-center'>
            <span className='mb-2'><TbInfoSquareRounded size={45} className="text-shopfood-alert zoomInIcon" /></span>
            <span className='font-extrabold text-2xl text-gray-700 mb-1 leading-6'>{ title }</span>
            {message && <span className='leading-5'>{ message }</span>}
        </h4>

        <div className='flex justify-center gap-2'>
            <ButtonPrimary onClick={() => onConfirm()} className='justify-center items-center text-sm'>{ labelConfirm }</ButtonPrimary>
            <ButtonPrimary outline onClick={() => onCancel()} className='justify-center items-center text-sm'>{ labelCancel }</ButtonPrimary>
        </div>
      </section>
    );
}

export default Confirm;
