import { QRCodeCanvas } from 'qrcode.react';
import React, { useState } from 'react';
import { TbCheck, TbCopy } from 'react-icons/tb';
import { convertDateToTimestamp, copyToClipboard, vibrate } from '../../../Utils/Helpers';
import Badge from '../Badge';
import CountdownExpirationDate from '../CountdownExpirationDate';
import ProgressBar from '../ProgressBar';
import Button from '../../Form/Button';
import Confirm from '../Confirm';
import Modal from '../Modal';
import { useCartHook } from '../../../Hooks/CartHook';
import ButtonPrimary from '../../Form/ButtonPrimary';

interface QrCodeProps {
    onClose: CallableFunction
    order: any
    onExpire: CallableFunction
}
const QRCodePix = ({ order, onClose = () => {}, onExpire = () => {} }: QrCodeProps) => {

    const [copied, setCopied] = useState(false);
    const [showConfirmCancel, setShowConfirmCancel] = useState(false);
    const { cancelPayment } = useCartHook();

    const handleCopy = async () => {
        vibrate();
        const success = await copyToClipboard(order.payment_method.pix_qr_code);
        if (success) {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        }
    }

    const handleCancel = () => {
        vibrate();
        cancelPayment();
    }

    return (
        <div className="py-4 flex gap-3 flex-col">

            <div className='p-1 grid gap-2'>
                <h2 className='flex flex-col gap-3 items-center text-gray-500 px-4'>
                    <span className='text-xl font-extrabold text-custom-primary leading-5'>Pagamento por PIX</span>
                </h2>
                <div className='flex gap-0.5 px-4 flex-col items-center justify-center leading-5'>
                    <span className='font-medium text-center'>Utilize o QRCode para efetuar o pagamento ou copie e cole o código abaixo.</span>
                </div>
            </div>

            <div className='flex items-center justify-center'>
                <div className='p-3 my-4 ring-8 ring-black rounded shadow-lg shadow-black'>
                    <div className={`flex justify-center ${copied ? 'pointer-events-none' : 'cursor-pointer'}`} onClick={handleCopy}><QRCodeCanvas size={160} value={order.payment_method.pix_qr_code}  /></div>
                </div>
            </div>

            <div className={`flex flex-col gap-4 p-4 py-0 ${copied && 'pointer-events-none'}`}>
                <span className='text-gray-400 text-sm break-all'>{ order.payment_method.pix_qr_code }</span>

                <div className='flex items-center gap-3 px-1'>
                    <Badge type='required' className='flex items-center text-sm font-extrabold w-[85px] text-center'>
                        <CountdownExpirationDate timestamp={order.payment_method.pix_expiration_date_timestamp * 1000} onComplete={onExpire()} />
                    </Badge>
                    <ProgressBar 
                        initialTimestamp={convertDateToTimestamp(order.timezone_created_at)} 
                        expirationTimestamp={order.payment_method.pix_expiration_date_timestamp * 1000} 
                    />
                </div>

                <div className='flex items-center justify-between gap-2'>
                    <ButtonPrimary onClick={handleCopy} 
                        className={`flex items-center gap-0.5 w-full justify-center
                            transition
                            ${copied ? 'text-white bg-shopfood-success ring-shopfood-success hover:ring-shopfood-success' : ''}
                        `}>
                        {!copied ? <TbCopy size={20} /> : <TbCheck size={20} />}
                        <span className='text-nowrap'>{!copied ? 'Copiar código' : 'Código copiado'}</span>
                    </ButtonPrimary>

                    <Button outline onClick={() => setShowConfirmCancel(true)} className='w-full justify-center'>
                        Cancelar
                    </Button>

                </div>
            </div>

            <Modal open={showConfirmCancel} className="max-w-sm m-4" onClose={() => setShowConfirmCancel(false)}>
                <Confirm
                    onConfirm={handleCancel} 
                    onCancel={() => setShowConfirmCancel(false)} 
                    title='Tem certeza'
                    message='Deseja realmente cancelar o pagamento?'
                    labelConfirm='Sim, quero cancelar'
                    labelCancel='Não'
                />
            </Modal>

        </div>
    )
};

export default QRCodePix;
