import React from 'react';
import Badge from '../Badge';
import { formatCurrency } from '../../../Utils/Formatters';
import { useOrderTypeContext } from '../../../Contexts/OrderTypeContext';

interface CartValueDetailsProps {
  cart: any;
}

const CartValueDetails = ({
  cart,
}: CartValueDetailsProps) => {

  const { orderType } = useOrderTypeContext();

  return (
    <div className='grid gap-0.5 text-sm'>

        <div className='flex justify-between '><span>Subtotal</span><span>{ formatCurrency(cart.total) }</span></div>

        {(cart.total_discount > 0 || cart.coupon_code) &&
          <div className='flex justify-between'>
            <span className='flex items-center gap-1'>
              Descontos
              {cart.coupon_code && <Badge type='success' outline className='py-1 leading-3 font-bold text-xs'>{cart.coupon_code}</Badge>}
            </span>
            <span>- { formatCurrency(cart.total_discount) }</span>
          </div>
        }

        <div className='flex justify-between'>
          <span>{orderType === 'store_counter' ? 'Taxa de serviço' : 'Taxa de entrega'}</span>
          <span>{cart.extra_tax > 0 ? formatCurrency(cart.extra_tax) : <Badge type={'success'} outline className='py-0 leading-4 px-2'>Grátis</Badge>}</span>
        </div>

        {cart.extra_value > 0 &&
          <div className='flex justify-between'>
            <span>Taxa extra</span>
            <span>{ formatCurrency(cart.extra_value) }</span>
          </div>
        }

        <div className='flex justify-between text-base font-extrabold text-gray-600'><span>Total</span><span>{formatCurrency(cart.total_with_discount + cart.extra_tax)}</span></div>

    </div>
  )
}

export default CartValueDetails;