import React, { PropsWithChildren } from 'react';
import { TbCreditCard, TbDots, TbPlus } from 'react-icons/tb';
import Img from '../Layout/Img';
import Badge from '../Layout/Badge';

interface ButtonMethodPaymentProps {
  onClick: CallableFunction;
  disabled?: boolean;
  className?: string;
  payCard?: boolean;
  add?: boolean;
  active?: boolean;
  image_url?: string;
  icon?: any;
  label: string;
  title: string;
  number?: string;
  loading?: boolean;
  main?: boolean;
}

const ButtonMethodPayment = ({
  children,
  onClick = () => {},
  disabled = false,
  className,
  payCard = false,
  add = false,
  active = false,
  image_url,
  icon,
  label,
  title,
  number,
  loading,
  main = false

}: PropsWithChildren<ButtonMethodPaymentProps>) => {

  const handleClick = () => {
    onClick();
  }

  return (
    loading 
      ?
      <div className="flex items-center gap-2 w-full p-4 rounded-lg ring-1 ring-gray-100 animate-pulse">
          <div className="flex">
              <div className="self-start flex bg-gray-200 min-w-6 min-h-6 md:min-w-24 md:min-h-24 lg:min-w-28 lg:min-h-28 rounded-lg"></div>
          </div>
          <div className="flex flex-col gap-1.5 w-full">
              <div className="flex bg-gray-200 h-1.5 w-14 rounded-lg"></div>
              <div className="flex bg-gray-200 h-3 w-full rounded-lg"></div>
          </div>
      </div>
      :
        <button
          type="button"
          onClick={handleClick}
          disabled={disabled}
          className={`
          ${className}
          relative flex rounded-md p-2.5 ring-inset h-full w-full
          ${active && !add ? 'shadow-md bg-white ring-2 ring-custom-primary pointer-events-none' : 'ring-1 ring-gray-100 active:ring-2 ring-custom-primary'}
          ${add ? 'ring-none border-2 border-gray-300 border-dashed hover:border-custom-primary' : 'shadow bg-white'}
          transition-color ease-in-out duration-200
          `}
        >

          <div className='flex items-center gap-1.5 w-full'>
            <div className='self-start'>
              { add && <TbPlus size={20} className="text-custom-primary" />}
              { payCard && <TbCreditCard size={25} className={`${active && 'text-custom-primary'}`} />}
              { (!payCard && !add) && icon && icon
              }
              {image_url && <Img src={image_url} alt={title} className='w-6 min-w-6' /> }
            </div>
            <div className={`flex flex-col w-full ${active && 'text-custom-primary'}`}>
                <span className="text-xs text-left text-gray-400 leading-4">{ label }</span>
                <span className={`font-extrabold leading-5 text-left text-lg`}>{ title }</span>
                <div className='flex items-center justify-between gap-0.5'>
                  { number && 
                    <Badge className={`font-medium text-xs mt-2 gap-0.5`}>
                      <TbDots /> { number }
                    </Badge>
                  }
                  { (number && main) && 
                    <Badge outline className={`font-medium text-xs mt-2`}>
                      <span className='capitalize'>Padrão</span>
                    </Badge>
                  }
                </div>
            </div>
          </div>
          {children}
        </button>
  )
}

export default ButtonMethodPayment;