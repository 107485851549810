import { IMask } from "react-imask";

// Formata um número como moeda brasileira
export const formatCurrency = (value: number): string => {
    return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    }).format(value);
};

// Formata o código da comanda
export const formatCartId = (value: number | null | undefined): string => {
    if (!value) return '';
    const stringValue = value.toString();
    return stringValue.length > 4 ? stringValue.slice(-4) : stringValue;
};

// Formata CPF
export const formatCpf = (value: string | null | undefined): string => {
    if (!value) return '';
    const cpfMask = IMask.createMask({
        mask: '000.000.000-00',
      });
      cpfMask.resolve(value);
      return cpfMask.value;
};

// Formata CEP
export const formatZipcode = (value: string | null | undefined): string => {
    if (!value) return '';
    const zipcodeMask = IMask.createMask({
        mask: '00000-000',
      });
      zipcodeMask.resolve(value);
      return zipcodeMask.value;
};
