import React from 'react';
import ButtonsIncDec from '../ButtonsIncDec';
import { TbMessage2Question, TbPhotoX, TbUser, TbUserSquareRounded } from "react-icons/tb";
import Img from '../Img';
import { formatCurrency } from '../../../Utils/Formatters';

interface CardProductCartProps {
  product: any;
  onInc: CallableFunction;
  onDec: CallableFunction;
  onDelete?: CallableFunction;
  showButtonsIncDec?: boolean
}

const CardProductCart = ({
  product,
  onInc = () => {},
  onDec = () => {},
  onDelete = () => {},
  showButtonsIncDec = true,
}: CardProductCartProps) => {

  const handleDelete = () => {
    onDelete()
  }
  const handleDec = (quantity: number) => {
    onDec(quantity)
  }
  const handleInc = (quantiy: number) => {
    onInc(quantiy)
  }

  const isCanceled = product.status === 0;
  const classCanceled = isCanceled && 'line-through text-gray-400';

  return (
    <div className={'flex justify-between items-center gap-3 py-1.5 w-full'}>

      {showButtonsIncDec 
        ? <div className='hidden self-start justify-center w-12'><Img src={product.photos?.thumbnail} fallback={<TbPhotoX size={35} className='w-full text-gray-200' />} alt={product.name} className="w-full rounded" /></div>
        : <div className={`self-start leading-4 text-right w-8 font-bold ${classCanceled}`}>{ product.quantity }x</div>
      }

      <div className='flex items-center w-full gap-2'>
        <div className='flex flex-col w-full'>
          <span className={`font-semibold leading-4 mb-0.5 ${classCanceled}`}>{product.name}</span>
          {product.subitems &&
            <div className='mb-0.5'>
              {product.subitems?.map((item: any, index: number) => 
                  <div className='flex gap-1 text-gray-500 text-sm leading-4' key={index}>
                    <span>{item.quantity}x</span>
                    <span className='w-full leading-4'>
                      <span className="mr-1">{item.name}</span>
                      <span>({formatCurrency(item.total_with_discount)})</span>
                    </span>
                </div>
              )}
            </div>
          }
          {(product.observation && !isCanceled) && 
            <div className='flex gap-0.5 text-gray-400 text-xs md:text-sm'>
              {!isCanceled && <div className='mt-1'><TbMessage2Question /></div>}
              <span className='leading-5'>{product.observation}</span>
            </div>
          }
          <span className='flex items-center gap-0.5 text-xs md:text-sm text-gray-400 leading-4'>
            {product.seller 
              ? <><TbUserSquareRounded className='self-start mt-0.5' /><span>Feito por {product.seller.name} em {product.created_at}</span></>
              : <><TbUser className='self-start mt-0.5' /><span>Feito por você em {product.created_at}</span></>
            }
          </span>
        </div>

        {showButtonsIncDec &&
          <div className='text-sm'>
            <ButtonsIncDec 
              onDec={handleDec} 
              onInc={handleInc} 
              onDelete={handleDelete} showDeleteOnZero quantityMin={0} initialQuantity={product.quantity} 
              product={product}
            />
          </div>
        }
        <div className={`self-start flex-col text-right leading-4 flex text-nowrap text-sm ${isCanceled ? classCanceled : 'text-gray-500'}`}>
          {product.total_discount > 0 && <span className='line-through text-gray-400 text-sm'>{formatCurrency(product.total * product.quantity)}</span>}
          <span>{formatCurrency(product.total_with_discount * product.quantity)}</span>
        </div>
      </div>
  </div>
  )
}

export default CardProductCart;