'use client'

import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { PropsWithChildren, useEffect } from 'react';
import ButtonClose from './ButtonClose';

interface DrawerProps {
    open: boolean;
    closeable?: boolean;
    onClose: CallableFunction;
    title?: string | null;
    subtitle?: any | null;
    fromBottom?: boolean;
    fromLeft?: boolean;
    backdrop?: boolean;
    className?: string | null;
}

const Drawer = ({
    children,
    open = false,
    closeable = true,
    onClose = () => {},
    title = null,
    subtitle = null,
    fromBottom = false,
    fromLeft = false,
    backdrop = true,
    className = ''
}: PropsWithChildren<DrawerProps>) => {

  const close = () => {
    if (closeable) {
      onClose();
    }
  };

  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      event.preventDefault();
      onClose();
    };
    window.addEventListener('popstate', handlePopState);
    window.history.pushState({}, '');
    return () => {
      window.removeEventListener('popstate', handlePopState);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={open} onClose={close} className="relative z-40">

      {backdrop &&
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
        />
      }

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className={`
            pointer-events-none fixed inset-y-0 flex max-w-full
            ${fromLeft ? 'md:pr-20 left-0' : 'md:pl-20 right-0'}
          `}>
            <DialogPanel
              transition
              className={`
                pointer-events-auto w-screen max-w-full md:max-w-md transform transition duration-200 ease-in-out
                ${fromBottom ? 'data-[closed]:translate-y-full' : fromLeft ? 'data-[closed]:-translate-x-full' : 'data-[closed]:translate-x-full'}
                ${className}
              `}
            >

              <div className="flex h-full flex-col overflow-y-auto bg-white shadow-xl">
                {title &&
                  <div className="p-4 ring-1 ring-gray-100">
                    <div className="flex items-center justify-between gap-2 mr-8">

                      <div className='grid gap-1'>
                        <DialogTitle className="text-xl font-extrabold text-custom-primary">{title}</DialogTitle>
                        {subtitle && <DialogTitle className="text-sm text-gray-500">{subtitle}</DialogTitle>}
                      </div>

                      <ButtonClose onClick={close} />

                    </div>
                  </div>
                }
                <div className={`relative flex-1 pt-2 ${className}`}>{children}</div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default Drawer;