'use client'

import { PropsWithChildren } from 'react';
import { useCartContext } from '../Contexts/CartContext';
import Header from '../Components/Layout/Header';
import Footer from '../Components/Layout/Footer';
import { useOrderTypeContext } from '../Contexts/OrderTypeContext';
import HeaderStoreCounter from '../Components/Layout/HeaderStoreCounter';
import FooterStoreCounter from '../Components/Layout/FooterStoreCounter';
import { useStoreContext } from '../Contexts/StoreContext';
import { TbMoodAnnoyed } from 'react-icons/tb';
import { ImSpinner8 } from 'react-icons/im';

interface DefaultLayoutProps {
  categories: any;
}

const DefaultLayout = ({
  children,
  categories,
}: PropsWithChildren<DefaultLayoutProps>) => {

  const { setShowCart, setShowCartConference } = useCartContext();
  const { orderType } = useOrderTypeContext();
  const { currentStore, loadingStore } = useStoreContext();

  return (
    <>

      <div className="flex flex-col min-h-dvh absolute w-full items-center">

      {orderType === 'store_counter'
        ? 
          <HeaderStoreCounter
            categories={categories} 
          />
        :
          <Header
            onShowCart={() => setShowCart(true)} 
            categories={categories} 
          />
      }

        {/* CONTAINER */}
        <section className='flex flex-col flex-grow w-full md:max-w-[1460px]'> 

          {/* CONTENT */}
          <section className='flex flex-grow p-3 md:p-6 lg:p-8 transition-width ease-in-out duration-200'>
            <div className='grid w-full gap-8 md:gap-12'>  {/* self-start */}

              {!currentStore 
                ? 
                  <div className="flex flex-col gap-1 items-center justify-center text-sm text-gray-500 max-h-full min-h-96">
                      {loadingStore
                          ? <div className="flex items-center gap-1"><ImSpinner8 className="animate-spin" /> <span>Carregando loja</span></div>
                          : <><TbMoodAnnoyed size={35} /> <span>Loja não encontrada</span></>
                      }
                  </div>
                : children
              }
            </div>
          </section>

        </section>

        {/* FOOTER */}
        {orderType === 'store_counter'
          ? 
            <FooterStoreCounter 
              onShowCartConference={() => setShowCartConference(true)} 
              onShowCart={() => setShowCart(true)} 
            />
          : <Footer onShowCart={() => setShowCart(true)} />
          }
      </div>
    </>
  )
}

export default DefaultLayout;