import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';

interface LayoutType {
  mainLoading: boolean;
  setMainLoading: CallableFunction;
  mainLoadingIcon: boolean;
  setMainLoadingIcon: CallableFunction;
  mainLoadingIconInfo: boolean;
  setMainLoadingIconInfo: CallableFunction;
  mainLoadingIconSuccess: boolean;
  setMainLoadingIconSuccess: CallableFunction;
  mainLoadingIconError: boolean;
  setMainLoadingIconError: CallableFunction;
  mainLoadingIconSearch: boolean;
  setMainLoadingIconSearch: CallableFunction;
  mainLoadingIconNoSearchMap: boolean;
  setMainLoadingIconNoSearchMap: CallableFunction;
  mainLoadingIconMapCheck: boolean;
  setMainLoadingIconMapCheck: CallableFunction;
  mainLoadingTitle: string;
  setMainLoadingTitle: CallableFunction;
  mainLoadingMessage: string;
  setMainLoadingMessage: CallableFunction;
  mainLoadingCloseButton: boolean;
  setMainLoadingCloseButton: CallableFunction;
  mainLoadingIconErrorAddProduct: boolean;
  setMainLoadingIconErrorAddProduct: CallableFunction;
  isMobile: boolean;
  isOnline: boolean;
  setIsOnline: CallableFunction;
  hasError: boolean;
  setHasError: CallableFunction;
  productsToSearch: any;
  setProductsToSearch: CallableFunction;
  viewMode: string;
  setViewMode: (value: string) => void;
  welcomeScreenRead: boolean;
  setWelcomeScreenRead: (value: boolean) => void;
  company: any;
  setCompany: CallableFunction;
}

const LayoutContext = createContext<LayoutType | undefined>(undefined);

export const LayoutProvider = ({ children }: { children: ReactNode }) => {

  const [mainLoading, setMainLoading] = useState(false);
  const [mainLoadingIcon, setMainLoadingIcon] = useState(true);
  const [mainLoadingIconInfo, setMainLoadingIconInfo] = useState(false);
  const [mainLoadingIconSuccess, setMainLoadingIconSuccess] = useState(false);
  const [mainLoadingIconError, setMainLoadingIconError] = useState(false);
  const [mainLoadingIconSearch, setMainLoadingIconSearch] = useState(false);
  const [mainLoadingIconNoSearchMap, setMainLoadingIconNoSearchMap] = useState(false);
  const [mainLoadingIconMapCheck, setMainLoadingIconMapCheck] = useState(false);
  const [mainLoadingTitle, setMainLoadingTitle] = useState('Aguarde...');
  const [mainLoadingMessage, setMainLoadingMessage] = useState('');
  const [mainLoadingCloseButton, setMainLoadingCloseButton] = useState(false);
  const [mainLoadingIconErrorAddProduct, setMainLoadingIconErrorAddProduct] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [viewMode, setViewMode] = useState(() => localStorage.getItem('view_mode') || 'list')
  const [company, setCompany] = useState(null)

  const [welcomeScreenRead, setWelcomeScreenRead] = useState<boolean>(() => {
    const storedValue = localStorage.getItem('welcome_read');
    return storedValue === 'true';
  });

  const [productsToSearch, setProductsToSearch] = useState<any[]>([]);

  useEffect(() => {
    localStorage.setItem('view_mode', viewMode)
  }, [viewMode])

  useEffect(() => {
    localStorage.setItem('welcome_read', welcomeScreenRead.toString());
  }, [welcomeScreenRead]);

  useEffect(() => {
    if (mainLoading === false) {
      setTimeout(() => { //* timeout only for layout
        setMainLoadingIcon(true);
        setMainLoadingIconInfo(false);
        setMainLoadingIconSuccess(false);
        setMainLoadingIconError(false);
        setMainLoadingIconSearch(false);
        setMainLoadingIconNoSearchMap(false);
        setMainLoadingIconMapCheck(false);
        setMainLoadingTitle('');
        setMainLoadingMessage('');
        setMainLoadingCloseButton(false);
        setMainLoadingIconErrorAddProduct(false);
        setHasError(false);
      }, 250);
    }
  }, [mainLoading])

  useEffect(() => {
    window.addEventListener('resize', () => {
        setIsMobile(window.innerWidth < 768)
    })

    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };

  }, [])

  useEffect(() => {
    if (isMobile) {
      setViewMode('swiper');
    } else {
      setViewMode('list');
    }
  }, [isMobile])

  return (
    <LayoutContext.Provider value={{ 
        mainLoading, setMainLoading, 
        mainLoadingIcon, setMainLoadingIcon, 
        mainLoadingIconInfo, setMainLoadingIconInfo,
        mainLoadingIconSuccess, setMainLoadingIconSuccess, 
        mainLoadingIconError, setMainLoadingIconError, 
        mainLoadingIconSearch, setMainLoadingIconSearch, 
        mainLoadingIconNoSearchMap, setMainLoadingIconNoSearchMap, 
        mainLoadingIconMapCheck, setMainLoadingIconMapCheck, 
        mainLoadingTitle, setMainLoadingTitle, 
        mainLoadingMessage, setMainLoadingMessage, 
        mainLoadingCloseButton, setMainLoadingCloseButton, 
        mainLoadingIconErrorAddProduct, setMainLoadingIconErrorAddProduct, 
        isMobile, 
        viewMode, setViewMode, 
        isOnline, setIsOnline,
        hasError, setHasError,
        productsToSearch,
        setProductsToSearch,
        welcomeScreenRead, setWelcomeScreenRead, 
        company, setCompany, 
      }}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = () => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
