import React from 'react';
import { TbLayoutGrid, TbLayoutList } from 'react-icons/tb';
import { useLayoutContext } from '../../Contexts/LayoutContext';

interface ButtonsViewModeProps {
    className?: string;
}

const ButtonsViewMode = ({className}: ButtonsViewModeProps) => {

    const { viewMode, setViewMode} = useLayoutContext();

    return (
        <div className={`${className}`}>
            {viewMode === 'grid' &&
                <button
                    onClick={() => setViewMode('list')}
                    type="button"
                    className="flex items-center rounded-lg bg-white p-3 text-gray-400 ring-1 ring-inset ring-gray-100 hover:bg-gray-50 focus:z-10 transition-all ease-in-out duration-200 zoomInIconNoDelay"
                    >
                    <TbLayoutList size={20} className='text-custom-primary' />
                </button>
            }
            {viewMode === 'list' &&
                <button
                    onClick={() => setViewMode('grid')}
                    type="button"
                    className="flex items-center rounded-lg bg-white p-3 text-gray-400 ring-1 ring-inset ring-gray-100 hover:bg-gray-50 focus:z-10 transition-all ease-in-out duration-200 zoomInIconNoDelay"
                >
                    <TbLayoutGrid size={20} className='text-custom-primary' />
                </button>
            }
        </div>
    );
};

export default ButtonsViewMode;
