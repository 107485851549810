import React from 'react';

interface ProductBadgeProps {
    type?: string;
    className?: string;
    center?: boolean;
    bottom?: boolean;
    percentageDiscount?: number;
}

const ProductBadge = ({
    type='sale',
    className,
    center=false,
    bottom=false,
    percentageDiscount
}: ProductBadgeProps) => {

    return (
        <div className={`
            absolute flex items-center justify-center z-20
            ${center ? 'w-full h-full' : bottom ? 'bottom-1' : 'right-1 top-1'}
            ${bottom && 'bottom-1 border-green-300 w-full'}
            ${className}
        `}>
            <div className="flex items-center justify-center">
                <span className={`inline-flex items-center rounded-full px-1.5 py-0.5 text-xs font-semibold text-white ${type === 'sale' ? 'bg-shopfood-success' : 'bg-shopfood-info' }`}>
                    {type === 'sale' && percentageDiscount ? percentageDiscount + '%' : 'Promoção'}
                    {type === 'new' && 'Novo'}
                </span>
                {/* <TbRosetteFilled size={width} className="absolute z-16 text-shopfood-success" />
                <FaPercentage size={widthIn} className="z-16 absolute text-white" /> */}
            </div>
        </div>
    );
};

export default ProductBadge;
