import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { OrderTypeProvider } from './Contexts/OrderTypeContext';
import { CartProvider } from './Contexts/CartContext';
import { AddProductProvider } from './Contexts/AddProductContext';
import { LoginProvider } from './Contexts/LoginContext';
import { LayoutProvider } from './Contexts/LayoutContext';
import { StoreProvider } from './Contexts/StoreContext';
import { CheckoutProvider } from './Contexts/CheckoutContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <StoreProvider>
      <LayoutProvider>
        <OrderTypeProvider>
          <CartProvider>
            <AddProductProvider>
              <LoginProvider>
                <CheckoutProvider>
                  <App />
                </CheckoutProvider>
              </LoginProvider>
            </AddProductProvider>
          </CartProvider>
        </OrderTypeProvider>
      </LayoutProvider>
    </StoreProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
