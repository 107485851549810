import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { ORDER_TYPE_IDS } from '../Utils/Constants/Order';

interface OrderTypeType {
  orderType: string;
  setOrderType: CallableFunction;
  showOrderTypeChoice: boolean;
  setShowOrderTypeChoice: (value: boolean) => void;
  getOrderTypeIdByName: (value: string) => void;
}

const OrderTypeContext = createContext<OrderTypeType | undefined>(undefined);

export const OrderTypeProvider = ({ children }: { children: ReactNode }) => {
  const [showOrderTypeChoice, setShowOrderTypeChoice] = useState(false);

  const [orderType, setOrderType] = useState(() => localStorage.getItem('order_type') || 'store_counter')

  const getOrderTypeIdByName = (orderType: string): number | null => {
    return ORDER_TYPE_IDS[orderType] ?? null;
  }

  useEffect(() => {
    localStorage.setItem('order_type', orderType)
  }, [orderType])

  return (
    <OrderTypeContext.Provider value={{ orderType, setOrderType, showOrderTypeChoice, setShowOrderTypeChoice, getOrderTypeIdByName }}>
      {children}
    </OrderTypeContext.Provider>
  );
};

export const useOrderTypeContext = () => {
  const context = useContext(OrderTypeContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
