import React from 'react';
import { useLayoutContext } from '../../Contexts/LayoutContext';
import { ImSpinner8 } from 'react-icons/im';
import { TbAlertTriangle, TbCheck, TbInfoSquareRounded, TbMapPinCheck, TbMapPinX, TbSearch, TbShoppingBagExclamation } from 'react-icons/tb';
import Button from '../Form/Button';
import { useNavigate } from 'react-router-dom';
import { useCartContext } from '../../Contexts/CartContext';
import DOMPurify from 'dompurify';
import { useStoreContext } from '../../Contexts/StoreContext';
import { useStoreHook } from '../../Hooks/StoreHook';

const LoadingMain = () => {
    const { mainLoadingIcon, setMainLoading, mainLoadingTitle, mainLoadingMessage, mainLoadingCloseButton, mainLoadingIconSuccess, mainLoadingIconError, hasError, mainLoadingIconInfo, mainLoadingIconSearch, mainLoadingIconNoSearchMap, mainLoadingIconMapCheck, mainLoadingIconErrorAddProduct } = useLayoutContext();
    const { returnToCheckout, setReturnToCheckout } = useCartContext();
    const { loadStoreInfoOnCloseMainLoading, setLoadStoreInfoOnCloseMainLoading } = useStoreContext();
    const { currentStore } = useStoreContext();
    const { loadStoreInfo } = useStoreHook();

    const navigate = useNavigate();

    const handleClose = () => {
        if (returnToCheckout && !hasError) {
            navigate('/checkout');
            setReturnToCheckout(false);
        }
        if (loadStoreInfoOnCloseMainLoading) {
            loadStoreInfo(currentStore.id); //* atualiza informações da loja
            setLoadStoreInfoOnCloseMainLoading(false);
        }
        setMainLoading(false)
    }

    const mainLoadingMessageSanitize = DOMPurify.sanitize(mainLoadingMessage);

    return (
        <div className='p-4 py-6 flex flex-col gap-3 items-center'>
            {mainLoadingIcon && <ImSpinner8 size={35} className="animate-spin" />}
            {mainLoadingIconSuccess && <TbCheck size={50} className="text-shopfood-success zoomInIcon" />}
            {mainLoadingIconError && <TbAlertTriangle size={50} className="text-shopfood-error zoomInIcon" />}
            {mainLoadingIconInfo && <TbInfoSquareRounded size={50} className="text-shopfood-info zoomInIcon" />}
            {mainLoadingIconSearch && <TbSearch size={50} className="text-shopfood-success zoomInIcon" />}
            {mainLoadingIconNoSearchMap && <TbMapPinX size={50} className="text-shopfood-error zoomInIcon" />}
            {mainLoadingIconMapCheck && <TbMapPinCheck size={50} className="text-shopfood-success zoomInIcon" />}
            {mainLoadingIconErrorAddProduct && <TbShoppingBagExclamation size={50} className="text-shopfood-error zoomInIcon" />}

            {(mainLoadingTitle || mainLoadingMessage) ?
                <>
                    <div className='flex flex-col w-full gap-2'>
                        {mainLoadingTitle && <span className="font-bold w-full text-center leading-5">{ mainLoadingTitle }</span>}
                        {mainLoadingMessage && <span className='text-sm w-full text-center leading-4 text-gray-500' dangerouslySetInnerHTML={{ __html: mainLoadingMessageSanitize }} />}
                    </div>
                    {mainLoadingCloseButton && <div className='flex justify-center mt-1 fadeInIcon'><Button onClick={() => handleClose()} className='px-6 text-sm'>Ok, entendi</Button></div>}
                </>
                : null
            }
        </div>
    );
};

export default LoadingMain;
