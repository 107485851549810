import React, { createContext, useState, useContext, ReactNode } from 'react';

interface AddProductType {
  product: any;
  setProduct: (value: any) => void;
  category: any;
  setCategory: (value: any) => void;
  showAddProduct: boolean;
  setShowAddProduct: (value: boolean) => void;
}

const AddProductContext = createContext<AddProductType | undefined>(undefined);

export const AddProductProvider = ({ children }: { children: ReactNode }) => {
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [product, setProduct] = useState(null);
  const [category, setCategory] = useState(null);

  return (
    <AddProductContext.Provider value={{ showAddProduct, setShowAddProduct, product, setProduct, category, setCategory }}>
      {children}
    </AddProductContext.Provider>
  );
};

export const useAddProductContext = () => {
  const context = useContext(AddProductContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
