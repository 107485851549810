import BaseService from './BaseService';

export interface InfoProps {
  store_id: number;
}

export interface ProductsProps {
  store_id: number;
  order_type: number;
}

export interface TablesProps {
  store_id: number;
  tables: boolean;
  table_categories?: any;
}

export interface FeaturedProductsProps {
  store_id: number;
  order_type: number;
}

export interface ListPaymentMethodsProps {
  store_id: number;
}

export interface DistanceProps {
  store_id: number;
  latitude: number;
  longitude: number;
  success?: boolean;
  message?: string;
}

// export interface PIXPROPS {
//   payment_method_id: number;
//   payment_method: number;
//   store_id: number;
//   orderType: number;
//   cashier_id: number;
//   store_cashiers?: any;
// }
export interface CashiersProps {
  store_id: number;
  user: boolean;
  sort: string;
  date_from: string;
  date_to: string;
  store_cashiers?: any;
}

export default class StoreService {
  baseService: BaseService;
  constructor(orderType: string, token?: string) {
    this.baseService = new BaseService(orderType, token);
  }

  async info (params: InfoProps): Promise<InfoProps> {
    try {
      const store_id = params.store_id

      const response = await this.baseService.http.get<InfoProps>(`stores/${store_id}/info`);
      return response.data;
    } catch (error) {
      console.error('Erro info:', error);
      throw error;
    }
  }

  async products (params: ProductsProps): Promise<ProductsProps> {
    try {
      const store_id = params.store_id
      const order_type = params.order_type

      const response = await this.baseService.http.get<ProductsProps>(`stores/products?store_id=${store_id}&order_type=${order_type}`);
      return response.data;
    } catch (error) {
      console.error('Erro products:', error);
      throw error;
    }
  }

  async tables (params: TablesProps): Promise<TablesProps> {
    try {
      const payload = { store_id: params.store_id, tables: params.tables };
      const response = await this.baseService.http.post<TablesProps>('tables/list', payload);
      return response.data.table_categories;
    } catch (error) {
      console.error('Erro products:', error);
      throw error;
    }
  }

  async featuredProducts (params: FeaturedProductsProps): Promise<FeaturedProductsProps> {
    try {
      const response = await this.baseService.http.get<FeaturedProductsProps>(`stores/featureds?store_id=${params.store_id}&order_type=${params.order_type}`);
      return response.data;
    } catch (error) {
      console.error('Erro featured products:', error);
      throw error;
    }
  }

  async listPaymentMethods (params: ListPaymentMethodsProps): Promise<ListPaymentMethodsProps> {
    try {
      const response = await this.baseService.http.get<ListPaymentMethodsProps>(`stores/${params.store_id}/payment/list`);
      return response.data;
    } catch (error) {
      console.error('Erro listPayment:', error);
      throw error;
    }
  }
  
  async cashiers (payload: CashiersProps): Promise<CashiersProps> {
    try {
      const response = await this.baseService.http.post<CashiersProps>('cashiers/list', payload);
      return response.data.store_cashiers;
    } catch (error) {
      console.error('Erro cachiers:', error);
      throw error;
    }
  }
  
  async distance (params: DistanceProps): Promise<DistanceProps> {
    try {
      const response = await this.baseService.http.get<DistanceProps>(`stores/${params.store_id}/distance?lat=${params.latitude}&lng=${params.longitude}`);
      return response.data;
    } catch (error) {
      console.error('Erro distance:', error);
      throw error;
    }
  }
}