import React from 'react';
import Badge from './Badge';
import { getOrderColorByStatusId } from '../../Utils/Constants/Order';
import { TbClock, TbHash } from 'react-icons/tb';
import { formatCartId } from '../../Utils/Formatters';
import { ORDER_STATUS_DONE } from '../../Utils/Constants/Checkout';

interface CardOrderDetailProps {
  order: any;
  onClick: CallableFunction;
}

const CardOrderDetail = ({
  order,
  onClick,

}: CardOrderDetailProps) => {

  return (
    <div className='w-full p-2 py-2.5 transition-all cursor-pointer hover:bg-gray-50 ring-inset active:ring-2 active:rounded-md active:ring-custom-primary'
      onClick={() => onClick(order) }
    >
      <div className='flex items-center justify-between gap-2 divide-x divide-gray-100'>
        <div className='font-bold text-lg'>{ formatCartId(order.cart_id) }</div>
        <div className='flex flex-col flex-grow px-2'>
          <div className='text-xs text-sm text-gray-400 flex flex-col'>
            <span className='flex items-center flex-nowrap font-semibold gap-1'><span>Pedido</span> <span className='flex items-center flex-nowrap'><TbHash />{order.id}</span></span>
            <span className='flex items-center flex-nowrap gap-0.5'><TbClock />{order.created_at}</span>
          </div>
        </div>
        <Badge outline={order.status !== ORDER_STATUS_DONE} type={getOrderColorByStatusId(order.status)}>
            {order.status_name}
        </Badge>
      </div>
    </div>
  )
}

export default CardOrderDetail;