import React, { useEffect, useState } from 'react';
import { TbClock, TbHash, TbListSearch, TbMoodConfuzed } from 'react-icons/tb';
import Drawer from './Drawer';
import CustomerService from '../../Services/CustomerService';
import { useOrderTypeContext } from '../../Contexts/OrderTypeContext';
import { useLoginContext } from '../../Contexts/LoginContext';
import { ImSpinner8 } from 'react-icons/im';
import Badge from './Badge';
import OrderDetails from './Checkout/OrderDetails';
import CardOrderDetail from './CardOrderDetail';
import Scrollbars from 'react-custom-scrollbars-2';
import { formatCartId } from '../../Utils/Formatters';
import { getOrderColorByStatusId } from '../../Utils/Constants/Order';
import { ORDER_STATUS_DONE } from '../../Utils/Constants/Checkout';
import OrderService from '../../Services/OrderService';
import { useLayoutContext } from '../../Contexts/LayoutContext';


interface ButtonCustomerOrdersProps {
    className?: string;
}

const ButtonCustomerOrders = ({className}: ButtonCustomerOrdersProps) => {
    const [showOrders, setShowOrders] = useState(false);
    const [loadingOrders, setLoadingOrders] = useState(false);
    const [showOrderDetail, setShowOrderDetail] = useState(false);
    const [loadingOrderDetail, setLoadingOrderDetail] = useState(false);
    const [currentOrder, setCurrentOrder] = useState<any>(null);
    const [currentCartId, setCurrentCartId] = useState<any>(null);
    const [ordersList, setOrdersList] = useState([]);
    const { orderType } = useOrderTypeContext();
    const { token } = useLoginContext();

    const getOrdersList = async () => {
        setShowOrders(true);
        setLoadingOrders(true);
        try {
            const customerService = new CustomerService(orderType, token);
            const getList = await customerService.getOrders();
            if (getList) {
                setOrdersList(getList);
            }
        } catch (error) {
            console.error('Error getOrdersList:', error);
        } finally {
            setLoadingOrders(false);
        }
    }

    const { setMainLoading, setMainLoadingIcon, setMainLoadingIconError, setMainLoadingTitle, setMainLoadingMessage, setMainLoadingCloseButton } = useLayoutContext();
    const getDetail = async (order_id: number) => {
        const payload = {
            order_id: order_id,
        }
        setShowOrderDetail(true);
        setLoadingOrderDetail(true);
        try {
            const orderService = new OrderService(orderType, token);
            const details = await orderService.detail(payload);

            if (details) {
                setCurrentOrder(details);
            }
        } catch (error) {
            console.error('Error orderDetail:', error);
            setShowOrderDetail(false);
            setLoadingOrderDetail(false);

            setMainLoading(true);
            setMainLoadingIcon(false);
            setMainLoadingIconError(true);
            setMainLoadingTitle('Ops! Algo não deu certo.');
            setMainLoadingMessage('Tente novamente e, caso persista, chame o garçom.');
            setMainLoadingCloseButton(true);
        } finally {
            setLoadingOrderDetail(false);
        }
    }

    const handleGetDetail = (order_id: number, cart_id: number) => {
        setCurrentCartId(cart_id); 
        getDetail(order_id);
    }

    useEffect(() => {
        if (!showOrderDetail) {
            setTimeout(() => {//* setTimeout apenas layout
                setCurrentOrder(null);
            }, 250);
        }
    }, [showOrderDetail])


    const [showShadow, setShowShadow] = useState(false);
    const handleScrollFrame = (values: any) => {
        const { scrollTop, scrollHeight, clientHeight } = values;
        // Verifica se o scroll está no início ou no fim para controlar a sombra
        if (scrollTop >= 0 && scrollTop + clientHeight < scrollHeight) {
            setShowShadow(true);
        } else {
            setShowShadow(false);
        }
    }
    useEffect(() => {
        //* Exibição da sombra dos itens quando houver scroll
        const boxScroll = document.getElementById('boxScroll');
        const scrollContent = document.getElementById('scrollContent');

        if (boxScroll && scrollContent) {
            const boxScrollHeight = boxScroll.getBoundingClientRect().height;
            const scrollContentHeight = scrollContent.getBoundingClientRect().height;
            setShowShadow(scrollContentHeight > boxScrollHeight)
        }
    }, [ordersList])

    return (
        <>
            <button
                onClick={() => getOrdersList()}
                type="button"
                className="flex items-center gap-1 rounded-lg bg-white p-3 text-gray-400 ring-1 ring-inset ring-gray-100 hover:bg-gray-50 focus:z-10 transition-all ease-in-out duration-200 zoomInIconNoDelay"
                >
                <TbListSearch size={20} className='text-custom-primary' />
                <span className='hidden md:block text-sm font-medium'>Minhas comandas</span>
            </button>

            <Drawer open={showOrders}
                title='Minhas comandas'
                subtitle={''}
                fromBottom={false}
                onClose={() => setShowOrders(false)}
                >
                    <section className='p-4 pt-3 flex flex-col h-full gap-4 justify-between'>
                        <div className="h-full">
                            {loadingOrders 
                                ? <div className="flex items-center justify-center gap-1 h-full text-sm text-gray-500"><ImSpinner8 className="animate-spin" /> <span>Carregando comandas</span></div>
                                :
                                    ordersList.length === 0
                                        ?   <div className="flex items-center justify-center gap-1 h-full text-sm text-gray-500"><TbMoodConfuzed /> <span>Nenhuma comanda encontrado</span></div>
                                        :
                                            <div className='relative flex flex-col gap-3 w-full h-full md:overflow-y-auto'>
                                                <div className='relative h-full min-h-24' id="boxScroll">

                                                    <Scrollbars autoHide={true} className='min-h-full' onScrollFrame={handleScrollFrame}>
                                                        <div className='flex flex-col divide-y divide-gray-100' id='scrollContent'>
                                                            {ordersList.map((order: any, index: number) => 
                                                                <CardOrderDetail order={order} onClick={() => handleGetDetail(order.id, order.cart_id)} key={index} />
                                                            )}
                                                        </div>
                                                        <div className='text-xs flex items-center gap-4 my-2'>
                                                            <hr className='w-full border-gray-100 border-dashed' />
                                                            <span className='text-nowrap text-gray-200'>Fim das comandas</span>
                                                            <hr className='w-full border-gray-100 border-dashed' />
                                                        </div>
                                                    </Scrollbars>
                                                    <div className={`absolute hidden md:block bottom-0 w-full h-2 shadow-reverse-md transition ${showShadow ? 'opacity-100' : 'opacity-0'}`}></div>
                                                </div>
                                                <div className={`absolute -bottom-2 md:hidden w-full h-2 shadow-reverse-md transition ${showShadow ? 'opacity-100' : 'opacity-0'}`}></div>
                                            </div>

                            }
                        </div>
                        {/* <div>
                            <Button onClick={() => setShowOrders(false)} className='justify-center w-full text-nowrap text-sm'>Fechar</Button>
                        </div> */}
                    </section>
            </Drawer>


            <Drawer open={showOrderDetail}
                title={`Comanda ${formatCartId(currentCartId)}`}
                subtitle={
                    loadingOrderDetail 
                        ? 
                            <div className="flex gap-1 animate-pulse">
                                <div className="bg-gray-200 h-5 w-20 rounded"></div>
                                <div className="bg-gray-200 h-5 w-28 rounded"></div>
                                <div className="bg-gray-200 h-5 w-20 rounded"></div>
                            </div>
                        : currentOrder &&
                            <div className='flex items-center gap-1'>
                                <Badge outline={currentOrder.status !== ORDER_STATUS_DONE} type={getOrderColorByStatusId(currentOrder.status)} className="text-xs">{ currentOrder.status_name }</Badge>
                                <Badge outline className="text-xs gap-0.5 capitalize text-normal"><TbClock /><span>{currentOrder.created_at_formatted}</span></Badge>
                                <Badge outline className="text-xs capitalize text-normal"><TbHash /><span>{currentOrder.id}</span></Badge>
                            </div>
                }
                fromBottom={false}
                onClose={() => setShowOrderDetail(false)}
                >
                    <OrderDetails order={currentOrder} loading={loadingOrderDetail} onClose={() => setShowOrderDetail(false)} />
            </Drawer>
        </>
    );
};

export default ButtonCustomerOrders;
