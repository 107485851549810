import BaseService from './BaseService';

export interface CreateProps {
  anti_fraud_session_id: string;
  payment_method_id: number;
  payment_method: any;
  store_id: number;
  order_type: number;
  cashier_id: number;
  store_cashiers?: any;
  status?: number;
  order?: any;
}

export interface DetailProps {
  order_id: number;
  cart?: any;
  status?: number;
  order?: any;

}

export default class OrderService {
  baseService: BaseService;
  constructor(orderType: string, token?: string) {
    this.baseService = new BaseService(orderType, token);
  }

  async create (payload: CreateProps): Promise<CreateProps> {
    try {
      const response = await this.baseService.http.post<CreateProps>('orders/create', payload);
      return response.data.order;
    } catch (error) {
      console.error('Erro create:', error);
      throw error;
    }
  }

  async detail(params: DetailProps): Promise<DetailProps> {
    try {
      const response = await this.baseService.http.get<DetailProps>(`orders/${params.order_id}/view`);
      return response.data.order;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  }

}