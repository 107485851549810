import { Button } from '@headlessui/react';
import React, { useState } from 'react';
import { TbCheck, TbLockFilled, TbTicket, TbTicketOff } from 'react-icons/tb';
import Drawer from '../Drawer';
import DOMPurify from 'dompurify';

interface CardCouponProps {
  coupon: any;
  onClick: CallableFunction;
}

const CardCoupon = ({
  coupon,
  onClick,
}: CardCouponProps) => {

  const [showRules, setShowRules] = useState(false);
  const contentRules = DOMPurify.sanitize(coupon.rules);

  return (
    <>
      <Button className={`text-xs text-left ring-1 ring-gray-200 hover:ring-2 rounded-lg cursor-pointer transition ease-in-out duration-200 overflow-hidden ${coupon.enabled ? 'bg-white shadow-lg hover:ring-custom-primary hover:text-custom-primary' : 'bg-gray-150 hover:ring-gray-300'}`}>
        <div className=' flex flex-col justify-between h-full'>
          <div onClick={() => onClick()} className='p-4 flex gap-2'>
            <div className='-mt-0.5'>
              {coupon.enabled ? <TbTicket size={30} className='text-custom-primary' /> : <TbTicketOff size={30} /> }
            </div>
            <div className='flex flex-col gap-2 h-full'>
              <div className='font-bold text-sm leading-4'>{coupon.name}</div>
              <div className='text-gray-500'>{coupon.description}</div>
            </div>
          </div>

          

          <div className=' flex flex-col items-center leading-3 text-gray-500'>

            <div onClick={() => setShowRules(true)} className='flex items-center w-full justify-between p-3 text-gray-500'>
                <div className={`font-bold ${coupon.enabled ? 'text-custom-primary' : 'hover:text-custom-primary'}`}>Ver regras</div>
                <div className='text-[11px]'>Válido até {coupon.expiration_date}</div>
            </div>

            {!coupon.enabled &&
              <div className='flex flex-col items-center gap-2 w-full shadow-inner'>
                <div className="overflow-hidden shadow-inner h-0.5 w-full">
                    <div style={{ width: coupon.percentage_to_enable }} className='h-full bg-custom-primary' />
                </div>
              </div>
            }

            <div 
              onClick={() => onClick()} 
              className={`flex items-center gap-1 w-full p-2 ${coupon.enabled ? 'font-bold bg-custom-primary text-white justify-center' : ' justify-between bg-gray-100 text-gray-400'}`}
              >
              <span className='text-sm'>
                {!coupon.enabled ? <TbLockFilled /> : <TbCheck />}
              </span>
              <div className={`flex items-center min-h-6 ${!coupon.enabled && 'text-[11px] w-full'}`}>
                {!coupon.enabled ? `Falta ${coupon.remaining_value} para o pedido mínimo` : 'Cupom disponível'}
              </div>
            </div>

          </div>
        </div>
      </Button>

      <Drawer 
        open={showRules} 
        title='Regra do cupom' 
        backdrop={true} 
        onClose={() => setShowRules(false)}
        className='md:max-w-sm'
        >
        <div className='p-6 grid gap-2'>
          <h4 className='font-bold'>{coupon.name}</h4>
          <div className='text-sm' dangerouslySetInnerHTML={{ __html: contentRules }} />
      

          <div className='py-2'>
            <Button onClick={() => onClick()} className='text-custom-primary text-sm font-medium'>Aplicar este cupom</Button>
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default CardCoupon;