import React from 'react';
import ButtonPrimary from '../Form/ButtonPrimary';
import FooterLinks from './FooterLinks';
import { useLoginContext } from '../../Contexts/LoginContext';
import ButtonOpenStoreCounter from './ButtonOpenStoreCounter';
import { TbChecklist, TbHash, TbSend } from 'react-icons/tb';
import { useCartContext } from '../../Contexts/CartContext';
import { useStoreContext } from '../../Contexts/StoreContext';
import { formatCartId } from '../../Utils/Formatters';
import Badge from './Badge';

interface FooterStoreCounterProps {
    onShowCartConference: CallableFunction;
    onShowCart: CallableFunction;
}

const FooterStoreCounter = ({
    onShowCartConference = () => {},
    onShowCart = () => {},
}: FooterStoreCounterProps) => {

    const { user } = useLoginContext();
    const { cartConference, cartDisplay, getTotalQuantityProductsInConference } = useCartContext();
    const { currentStore } = useStoreContext();

    return (
        <section className='flex flex-col w-full'>

            {currentStore && <FooterLinks />}
            {currentStore &&
                <>
                    <section className={`${(!user || !cartDisplay) && 'block md:hidden'} fixed z-30 flex gap-3 items-center justify-between w-full h-16 bottom-0 p-3 bg-white text-sm ring-t-1 ring-white/50 rounded-t-xl shadow-reverse-md`}>
                        {(!user || !cartDisplay)
                            ?
                                <div className='w-full'>
                                    <ButtonOpenStoreCounter />
                                </div>
                            :
                                <div className='flex gap-3 w-full md:justify-end'>
                                    <button onClick={() => onShowCart(true)} disabled={!cartDisplay} className={`py-2 px-3 text-sm flex items-center font-semibold ring-1 ring-gray-100 gap-0.5 rounded-full hover:bg-gray-100 focus-visible:outline transition-width ease-in-out duration-200 md:max-w-md ${cartConference.length === 0 && 'w-full'}`}>
                                        <div className='flex gap-4 w-full items-center'>
                                            {/* <div className="text-left text-nowrap"> */}
                                                {/* <div className="text-xs font-normal leading-4 text-gray-400">Comanda</div> */}
                                                {/* <div className="flex items-center text-lg leading-4 font-extrabold leading text-custom-primary"> */}
                                                    <Badge type='custom' className='text-lg rounded-full pr-3'>
                                                        <span><TbHash /></span>
                                                        <span>{ formatCartId(cartDisplay?.id) }</span>
                                                    </Badge>
                                                {/* </div> */}
                                            {/* </div> */}
                                            {cartConference.length === 0 &&
                                                <div className="text-xs w-full font-normal flex flex-col text-right leading-3 pr-1">
                                                    <span className="text-base font-bold leading-5">Visualize sua comanda</span>
                                                    <span className="text-gray-400">Se preferir, feche sua conta</span>
                                                </div>
                                            }
                                        </div>
                                    </button>

                                    {cartConference.length > 0 &&
                                        <ButtonPrimary outline onClick={() => onShowCartConference(true)} disabled={cartConference.length === 0} 
                                            className={`relative py-1 text-sm text-nowrap w-full md:max-w-64 gap-2 items-center ${cartConference.length > 0 ? 'justify-between' : 'justify-center bg-gray-100 ring-gray-100 text-gray-500'}`}>
                                            <span className='flex gap-0.5 items-center'>
                                                {cartConference.length > 0 
                                                    ?   <div className='flex items-center gap-0.5'>
                                                            <TbChecklist size={20} /> Conferir e enviar 
                                                            <div className='absolute -top-0.5 -right-0.5 text-shopfood-notify bg-white rounded-full'>
                                                                <TbSend size={20} className='animate-pulse' />
                                                            </div>
                                                        </div> 
                                                    : 'Adicione produtos'} </span>
                                            {cartConference.length > 0 && <span>{getTotalQuantityProductsInConference() > 1 ? `${getTotalQuantityProductsInConference()} itens` : `${getTotalQuantityProductsInConference()} item`}</span>}
                                        </ButtonPrimary>
                                    }
                                </div>
                        }
                    </section>
                </>
            }

        </section>
    )
}

export default FooterStoreCounter;
