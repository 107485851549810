import BaseService from './BaseService';

export default class CompanyService {
  baseService: BaseService;
  constructor(orderType: string) {
    this.baseService = new BaseService(orderType);
  }

  async info (): Promise<any> {
    try {
      const response = await this.baseService.http.get('company/info');
      return response.data.company;
    } catch (error: any) {
      console.error('Erro CompanyService:', error);
      throw error;
    }
  }
}