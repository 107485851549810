import React from 'react';
import ButtonOpenStoreCounter from '../ButtonOpenStoreCounter';
import { QRCodeCanvas } from 'qrcode.react';
import { METHOD_PAYMENT_TYPE_CARD_ONLINE, METHOD_PAYMENT_TYPE_PIX } from '../../../Utils/Constants/Checkout';
import { formatCartId, formatCurrency } from '../../../Utils/Formatters';
import { useStoreContext } from '../../../Contexts/StoreContext';
import { useLoginContext } from '../../../Contexts/LoginContext';
import { TbCheck } from 'react-icons/tb';
import { useCartContext } from '../../../Contexts/CartContext';

interface ProofPaymentProps {
  cart_id: any;
  payment_method: any;
  payment_methods: any;
  order_id: number;
  showButtonOpenNewCart: boolean;
  cashier_id?: number;
}

const ProofPayment = ({
  cart_id,
  payment_method,
  payment_methods,
  order_id,
  showButtonOpenNewCart = true,
  cashier_id
}: ProofPaymentProps) => {

  const { cashier } = useStoreContext();
  const { user } = useLoginContext();
  const { cartFinished } = useCartContext();
  const currentProof = cartFinished && cartFinished.order.cashier_id === cashier.id && cartFinished.order.id === order_id;

  return (
    <section className={`${!currentProof && 'grayscale'} flex flex-col w-full gap-3 p-3 h-full items-center justify-center text-center`}>
      <div className='flex flex-col gap-4 w-full h-full items-center justify-center text-center px-2 -mt-4'>

        <div className='flex gap-2 flex-col w-full items-center justify-center text-center'>
          <h3 className='text-2xl font-extrabold text-gray-800 px-2'>
            Olá, {user.first_name}!<br />
            Sua comanda {formatCartId(cart_id)} foi finalizada com sucesso
            {order_id && (payment_method?.type === METHOD_PAYMENT_TYPE_CARD_ONLINE || payment_method?.type === METHOD_PAYMENT_TYPE_PIX)
              ? ' por você'
              : ' pelo garçom'
            }
          !</h3>

          <div className='-rotate-6'>
            <div className="text-[2rem] border-4 border-dashed my-2 border-shopfood-success text-shopfood-success uppercase px-4 font-extrabold zoomInIcon">
              {currentProof ? 'Pago' : 'Verificado'}
            </div>
          </div>

          <div className='flex flex-col leading-5'>
            <span className='text-sm text-gray-400'>Forma de pagamento</span>
            {payment_methods?.map((method: any, index: number) =>
              <div key={index} className='flex items-center gap-1 justify-center'>
                <span className='font-bold text-gray-800'> { formatCurrency(method.pivot?.amount)}</span>
                ({method?.name})
              </div>
            )}
          </div>
        </div>

        <div className='p-3 my-4 ring-8 ring-shopfood-success rounded shadow-lg shadow-black fadeInIcon'>
          <div className='flex justify-center items-center relative'>
            <QRCodeCanvas size={160} value={'order_id'} />
            {!currentProof && <div className='absolute rounded-full bg-white p-4 bg-opacity-95'><TbCheck size={100} className='text-black' /></div>}
          </div>
          {currentProof && <div className='text-xl font-extrabold mt-2.5 text-custom-primary pl-[.2em] tracking-[.25em]'>{order_id}</div>}
        </div>

        {currentProof && <p className='font-medium text-xl leading-6 px-2 zoomInIconDelay'>Apresente este QR Code<br />como comprovante ;)</p>}

      </div>

      {showButtonOpenNewCart &&
        <div className='text-sm w-full'>
          <ButtonOpenStoreCounter forceLabel proofPayment={true} label='Abra uma nova comanda' />
        </div>
      }
    </section>
  )
}

export default ProofPayment;