import React, { useEffect, useRef, useState } from 'react';

import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { debounce } from 'lodash';
import { useLayoutContext } from '../../Contexts/LayoutContext';

interface MenuCategoriesFloatProps {
    categories: any;
}

const MenuCategoriesFloat = ({
    categories
}: MenuCategoriesFloatProps) => {

    const swiperRef = useRef<SwiperRef>(null);
    const [currentSwiperSlide, setCurrentSwiperSlide] = useState(0);
    const [showMenu, setShowMenu] = useState(false);
    const [isVisibleMenu, setIsVisibleMenu] = useState(false);
    const { viewMode } = useLayoutContext();

    const handleCategory = (category: any) => {
        const goToCategory = document.getElementById(`category_${category.id}`)
        if (goToCategory) {

            goToCategory.scrollIntoView({ behavior: 'smooth', block: 'start' });

            const elementRect = goToCategory.getBoundingClientRect();
            const scrollTop = document.documentElement.scrollTop;

            window.scrollTo({
              top: scrollTop + elementRect.top - 110,
              behavior: 'smooth'
            });
        }
    }

    const toggleVisibilityMenu = () => {
        setShowMenu(window.innerWidth <= 768)
        const boxMenuCategories = document.getElementById('boxMenuCategories');
        const boxProducts = document.getElementById('boxProducts');
        const rect = boxProducts?.getBoundingClientRect();
        const boxProductsPosition = rect ? rect.top + window.scrollY - 110 : 0;

        if (boxMenuCategories && boxProducts && window.scrollY > 110 && window.scrollY > boxProductsPosition) {
            setIsVisibleMenu(true);
        } else {
            setIsVisibleMenu(false);
        }
    };

    const changeMenuByScroll = () => {
        const categoryListItems = document.querySelectorAll('.categoryListScroll');
        categoryListItems.forEach((item) => {
            const rect = item.getBoundingClientRect();
            // Verifica se a categoria está a aproximadamente 145px do topo da viewport e seleciona automaticamente
            if (rect.top <= 135) {
                let changeSwip = item.id.replace('_', '');
                setCurrentSwiperSlide(parseInt(changeSwip));
                if (swiperRef.current && swiperRef.current.swiper) {
                    swiperRef.current.swiper.slideTo(parseInt(changeSwip));
                }
            }
        });
    }

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibilityMenu);
        window.addEventListener('resize', toggleVisibilityMenu);

        const debouncedChangeMenuByScroll = debounce(changeMenuByScroll, 200);
        window.addEventListener('scroll', debouncedChangeMenuByScroll);
        // window.addEventListener('scroll', changeMenuByScroll);

        return () => {
            window.removeEventListener('scroll', debouncedChangeMenuByScroll);
            // window.removeEventListener('scroll', toggleVisibilityMenu);

            window.removeEventListener('resize', toggleVisibilityMenu);
        };
    }, [categories])

    return (
        <>
            {(showMenu && viewMode !== 'swiper') &&
                <div className='flex justify-center fixed w-full text-sm bg-white rounded-t-xl bottom-0 pb-14'>
                    <div className="flex w-full md:max-w-[1460px]">
                        <div id="boxMenuCategories" className={`
                            flex gap-4 w-full swiperMenuCategories transition 
                            shadow-md shadow-black/5
                            rounded-t-xl shadow-reverse-md
                            ${isVisibleMenu ? 'opacity-100 h-full p-2 px-4' : 'opacity-0 h-0'}
                            `}>
                            <div className='flex items-center bg-white text-xs border-b-4 border-transparent text-gray-300'>Categorias</div>
                            <Swiper
                                ref={swiperRef}
                                spaceBetween={0}
                                slidesPerView={'auto'}
                                // centeredSlides={true}
                                slideToClickedSlide={true}
                                >
                                {
                                    categories.map((category: any, index: number) => (
                                        <SwiperSlide
                                            key={category.id} data-hash={`slide${category.id}`}
                                            // onClick={() => handleCategory(category)}
                                            data-active={parseInt(category.id) === currentSwiperSlide}
                                            onClick={() => handleCategory(category)} 
                                            className={`
                                                w-auto
                                                cursor-pointer p-2 px-4 border-b-4 
                                                transition-border ease-in-out duration-200
                                                text-sm leading-4
                                                flex items-center h-full justify-center text-nowrap
                                                max-w-fit
                                                min-w-content
                                                ${currentSwiperSlide === index ? 'border-custom-primary text-custom-primary font-bold' : 'border-transparent font-medium'}`}
                                            >
                                            {category.name}
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default MenuCategoriesFloat;
