import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { TbMessage2Question } from 'react-icons/tb';
import ButtonPrimary from '../../Form/ButtonPrimary';
import Button from '../../Form/Button';

interface EditObservationConferenceProps {
    onConfirm: (value: string) => void;
    onCancel: CallableFunction;
    product: any;
  }

  const EditObservationConference = ({
    onConfirm = () => {},
    onCancel = () => {},
    product
  }: EditObservationConferenceProps) => {

    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [observationUpdated, setObservationUpdated] = useState<string>(product.observation);
    const handleObservation = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const editedObservation = event.target.value.trim();
      setObservationUpdated(editedObservation)
    }

    useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    }, []);

    return (
      <section className='flex flex-col items-center w-full p-6 gap-6'>
          <h4 className='flex flex-col items-center'>
              <span className='mb-2'><TbMessage2Question size={45} /></span>
              <span className='font-bold text-xl text-custom-primary'>Alguma observação?</span>
              <span className='leading-5 text-lg'>{ product.name }</span>
          </h4>

          <textarea
              id="observation"
              name="observation"
              ref={textareaRef}
              className={`
                  block w-full rounded-md text-sm border-0 p-2.5 shadow ring-1 ring-gray-300 placeholder:text-gray-300 
                  focus:ring-2 focus:ring-custom-primary
                  transition-all ease-in-out duration-500
                  ${product.observation ? 'h-24' : 'h-16'}
                  focus:h-24
              `}
              placeholder='Ex: Tirar cebola, maionese à parte, ponto da carne etc'
              defaultValue={product.observation}
              onChange={handleObservation}
          />

          <div className='flex justify-center gap-2'>
              <ButtonPrimary disabled={product.observation === observationUpdated} onClick={() => onConfirm(observationUpdated)} className='w-full justify-center items-center text-sm'>Atualizar observação</ButtonPrimary>
              <Button onClick={() => onCancel()} className='justify-center items-center text-sm'>Cancelar</Button>
          </div>
      </section>
    );
};

export default EditObservationConference;
