import BaseService from './BaseService';

export interface CreateProps {
  store_id: number;
  order_type: number;
  store_table_id: number;
}

export interface ShowProps {
  cart_id: number;
  store_id: number;
  order_type: number;
}

export interface StepsAndComplementsProps {
  store_id: number;
  product_id: number;
  order_type: number;
}

export interface AddProductProps {}

export default class CartService {
  baseService: BaseService;

  constructor(orderType: string, token?: string) {
    this.baseService = new BaseService(orderType, token);
  }

  async create(payload: CreateProps): Promise<any> {
    try {
      const response = await this.baseService.http.post<{ payload: CreateProps }>('cart/create', payload);
      return response.data;
    } catch (error) {
      console.error('Erro create:', error);
      throw error;
    }
  }

  async addProduct(cartId: number, payload: AddProductProps): Promise<any> {
    try {
      const response = await this.baseService.http.post<{ payload: AddProductProps }>(`cart/${cartId}/add`, payload);
      return response.data;
    } catch (error) {
      console.error('Erro addProduct:', error);
      throw error;
    }
  }

  async show(params: ShowProps): Promise<any> {
    try {
      const response = await this.baseService.http.get<{ params: ShowProps }>(`cart/${params.cart_id}?store_id=${params.store_id}&order_type=${params.order_type}`);
      return response.data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  }
  
  async stepsAndComplements(params: StepsAndComplementsProps): Promise<any> {
    try {
      const response = await this.baseService.http.get<{ params: StepsAndComplementsProps }>(`products/composite/steps?store_id=${params.store_id}&product_id=${params.product_id}&order_type=${params.order_type}`);
      return response.data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  }

}
