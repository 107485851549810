import React from 'react';
import SelectedProduct from './SelectedProduct'
// import ProductBadge from './ProductBadge';
import { useAddProductContext } from '../../../Contexts/AddProductContext';
import DOMPurify from 'dompurify';
import Img from '../Img';
import { useQuantityProductInCartConference } from '../../../Utils/Helpers';
import ProductPrice from './ProductPrice';
import ProductBadge from './ProductBadge';


interface CardProductProps {
    product: any;
    category: any;
    loading?: boolean;
}

const CardProduct = ({
    product,
    category,
    loading,
}: CardProductProps) => {

    const { setShowAddProduct, setProduct, setCategory } = useAddProductContext();
    const handleProduct = () => {
        setShowAddProduct(true)
        setProduct(product)
        setCategory(category)
    }

    const description = DOMPurify.sanitize(product.description);
    const quantitySelected = useQuantityProductInCartConference(product.id);

    return (
        <>
            {!loading
                ?
                <div onClick={handleProduct} className="relative p-3 md:p-4 shadow rounded-lg overflow-hidden cursor-pointer transition ease-in-out duration-200 ring-1 ring-gray-100 active:shadow-lg md:hover:shadow-lg active:ring-2 active:ring-custom-primary md:hover:ring-2 md:hover:ring-custom-primary hover:text-custom-primary">

                    {/* BADGE  */}
                    {/* {product.new && !product.sale && <ProductBadge type='new' />} */}
                    {product.from_price && <ProductBadge type='sale' />}

                    <div className="relative flex gap-3 w-full h-full">
                        <div className="flex flex-grow flex-col justify-between gap-1">
                            <h3 className="md:text-lg font-bold">{product.name}</h3>
                            <p className='h-full text-sm text-gray-500' dangerouslySetInnerHTML={{ __html: description.length > 110 ? description.slice(0, 110) + '...' : description }} />
                            <h4 className="text-sm font-medium mt-1">
                                <ProductPrice product={product} />
                            </h4>
                        </div>
                        {product.photos.thumbnail ? 
                            <div className="flex items-center justify-end">
                                <div className='relative self-start'>
                                    {/* PRODUCT IN CART */}
                                    {quantitySelected > 0 && <SelectedProduct quantity={quantitySelected} className="rounded-lg" />}
                                    <Img src={product.photos.thumbnail} alt={product.name} className="max-w-20 md:max-w-24 lg:max-w-28 rounded transition-width ease-in-out duration-200" />
                                </div>
                            </div>
                        :
                            quantitySelected > 0 && <SelectedProduct quantity={quantitySelected} className="rounded-lg" />
                        }
                    </div>
                </div>
                :
                <div className="flex gap-4 w-full p-4 rounded-lg ring-1 ring-gray-100 animate-pulse">
                    <div className="flex flex-col gap-4 justify-between flex-grow">
                        <div className="bg-gray-200 h-10 rounded-lg"></div>
                        <div className="flex flex-col gap-1.5 w-full h-full">
                        <div className="bg-gray-200 w-full h-2 rounded"></div>
                        <div className="bg-gray-200 w-full h-2 rounded"></div>
                        <div className="bg-gray-200 w-[85%] h-2 rounded"></div>
                        <div className="bg-gray-200 w-[70%] h-2 rounded"></div>
                    </div>
                        <div className="flex gap-2 mt-2 items-center">
                            <div className="bg-gray-200 h-4 rounded-lg flex-grow"></div>
                            <div className="bg-gray-200 h-4 w-16 rounded-lg"></div>
                        </div>
                    </div>
                    <div className="flex items-center justify-end">
                        <div className="self-start flex bg-gray-200 min-w-20 min-h-20 md:min-w-24 md:min-h-24 lg:min-w-28 lg:min-h-28 rounded"></div>
                    </div>
                </div>
            }
        </>
    );
};

export default CardProduct;
