import React, { useState } from 'react';
import MenuCategoriesFloat from './MenuCategoriesFloat';
import { Link, useLocation } from 'react-router-dom';
import Search from './Search';
import ButtonsViewMode from './ButtonsViewMode';
import ButtonOpenStoreCounter from './ButtonOpenStoreCounter';
import { useCartContext } from '../../Contexts/CartContext';
import { formatCartId } from '../../Utils/Formatters';
import { useStoreContext } from '../../Contexts/StoreContext';
import { useLayoutContext } from '../../Contexts/LayoutContext';
import ButtonCustomerOrders from './ButtonCustomerOrders';
import { useLoginContext } from '../../Contexts/LoginContext';

interface HeaderStoreCounterProps {
    categories: any;
}

const HeaderStoreCounter = ({
    categories,
}: HeaderStoreCounterProps) => {

    const [showSearch, setShowSearch] = useState(false)
    const location = useLocation();
    const { cartDisplay, cartFinished } = useCartContext();
    const { currentStore } = useStoreContext();
    const { company, isMobile, viewMode } = useLayoutContext();
    const { token } = useLoginContext();

    const [searching, setSearching] = useState(false);
    const handleHeaderLayout = (searching: boolean) => {
        setSearching(searching);
    }

    return (
        <>
            <header id="boxHeaderStoreCounter" className="flex flex-col w-full justify-between sticky bg-custom-header top-0 z-30 text-custom-header-text">
                <section className='flex p-4 justify-center shadow-sm shadow-black/5'>
                    <div className={`flex items-center justify-between w-full md:max-w-[1460px] gap-4 md:gap-6`}>

                        {/* LOGO */}
                        <Link to={'/'} className={`${location.pathname === '/' && 'pointer-events-none'}`}>
                            {company && <img src={company.vector_image ?? company.image} alt="" className={`transition-width ease-in-out duration-200 ${searching && isMobile ? 'w-full min-w-6 max-h-6' : 'w-full min-w-8 max-h-8'}`} />}
                        </Link>

                        {/* SEARCH */}
                        <div className="flex flex-grow h-full">
                            <div className="relative flex items-center w-full">
                                <Search open={showSearch} onClose={() => setShowSearch(false)} onSearching={handleHeaderLayout} />
                            </div>
                        </div>

                        {((!searching && token) || !isMobile) &&
                            <div className="flex items-center gap-2">
                                {/* VIEW MODE */}
                                {(currentStore && viewMode !== 'swiper') && <ButtonsViewMode />}

                                {/* CUSTOMER ORDERS */}
                                {token && <ButtonCustomerOrders />}
                            </div>
                        }

                        {cartDisplay 
                            ? 
                                <div className='hidden md:flex flex-col text-right text-lg leading-4'>
                                    <span className='text-[11px] md:text-xs text-gray-400'>Comanda</span>
                                    <span className='font-bold text-nowrap'>{ formatCartId(cartDisplay.id) }</span>
                                </div>
                            :
                                <div className='w-auto hidden md:flex text-sm'>
                                    <ButtonOpenStoreCounter forceLabel label={cartFinished ? '' : 'Abra sua comanda'} />
                                </div>
                        }
                    </div>
                </section>

                <MenuCategoriesFloat categories={categories} />

            </header>
        </>
    )
}

export default HeaderStoreCounter;
