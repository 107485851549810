import React from 'react';
import Badge from '../Badge';
import { TbCircleCheckFilled } from 'react-icons/tb';
import SlotStepProduct from './SlotStepProduct';
import { useCartContext } from '../../../Contexts/CartContext';

interface SlotStepProps {
  product: any;
  step: any;
  className?: string;
}

const SlotStep = ({
  product,
  step,
  className

}: SlotStepProps) => {

  const { getTotalSelectedComplements, cartDisplay } = useCartContext();

  return (
    <div className='flex flex-col'>
      <div className='sticky top-16 z-20 md:-top-1 rounded-t bg-gray-50 p-3 w-full flex gap-2 items-center justify-between w-full'>

          <div className='text-left text-md'>
            <h4 className='grid'>
              <span className='text-lg md:text-xl text-custom-primary font-bold leading-6'>{step.name}</span>
              {cartDisplay && 
                <span className='font-medium text-gray-400 flex gap-1 leading-5'>
                  <span>
                      {step.description 
                        ? step.description
                        : step.minimum_quantity
                            ? `Escolha pelo menos ${step.minimum_quantity} ${step.minimum_quantity > 1 ? 'itens' : 'item'}`
                            : `Escolha  ${step.required && step.minimum_quantity && (step.minimum_quantity !== step.quantity)
                                          ? `de ${step.minimum_quantity} até ${step.quantity}` 
                                          :  `${step.quantity} ${step.quantity > 1 ? 'itens' : 'item'}`
                                        }`
                      }
                  </span>
                </span>
              }
            </h4>
          </div>

          <div className='flex items-center gap-0.5 self-start'>
            {cartDisplay &&
              <>
                {(step.required && getTotalSelectedComplements(step.id) < step.minimum_quantity) && <Badge type='required' className='text-xs'>Obrigatório</Badge>}
                {(step.required && getTotalSelectedComplements(step.id) >= step.minimum_quantity) && <TbCircleCheckFilled size={22} className={'text-shopfood-success zoomInIcon'} />}
                <Badge outline={getTotalSelectedComplements(step.id) >= step.minimum_quantity} type={getTotalSelectedComplements(step.id) >= step.minimum_quantity ? 'success' : ''} className='flex justify-center text-nowrap text-sm'>{getTotalSelectedComplements(step.id)} / { step.quantity }</Badge>
              </>
            }
          </div>

      </div>

      <div className={`text-left grid gap-4 ${className}`}>
        <section className='grid divide-y divide-gray-50 md:ring-1 ring-inset ring-gray-50 rounded-b-lg'>
          {step.complements.map((complement: any, index: number) => 
            <SlotStepProduct product={product} complement={complement} step={step} key={index} />
          )}
        </section>
      </div>
    </div>

  )
}

export default SlotStep;