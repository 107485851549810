import React, { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import Button from '../../Form/Button';
import ButtonPrimary from '../../Form/ButtonPrimary';
import { useCartContext } from '../../../Contexts/CartContext';
import CardProductCartConference from './CardProductCartConference';
import { TbShoppingBagPlus } from 'react-icons/tb';
import { useProductsHook } from '../../../Hooks/ProductsHook';
import Modal from '../Modal';
import TableChoice from '../Product/TableChoice';
import EditObservationConference from '../Product/EditObservationConference';
import { useStoreHook } from '../../../Hooks/StoreHook';
import { useStoreContext } from '../../../Contexts/StoreContext';
import { useOrderTypeContext } from '../../../Contexts/OrderTypeContext';
import CartService from '../../../Services/CartService';
import { useLoginContext } from '../../../Contexts/LoginContext';
import { CART_STATUS_FINISHED, ERROR_CART_NOT_FOUND } from '../../../Utils/Constants/Cart';
import { useAddProductContext } from '../../../Contexts/AddProductContext';
import { useCartHook } from '../../../Hooks/CartHook';
import { useLayoutContext } from '../../../Contexts/LayoutContext';

interface CartConferenceProps {
    cart: any;
    onCloseCart: CallableFunction;
}

const CartConference = ({cart, onCloseCart}: CartConferenceProps) => {

    const { cartConference, deleteProductFromConference, updateProductInConference, cartDisplay, updateObservationProductInConference } = useCartContext();
    const [showConfirmTable, setShowConfirmTable] = useState(false);
    const [showEditProduct, setShowEditProduct] = useState(false);
    const [currentProductToEdit, setCurrentProductToEdit] = useState<any>(null);
    const { storeIsOpen } = useStoreHook();
    const { sendProducts } = useProductsHook();
    const { currentStore } = useStoreContext();
    const { token } = useLoginContext();
    const { setMainLoading, setMainLoadingIcon, setMainLoadingTitle, setMainLoadingMessage  } = useLayoutContext();
    const { orderType, getOrderTypeIdByName } = useOrderTypeContext();
    const { category } = useAddProductContext();
    const { cartFinished, cartNotFound } = useCartHook();

    const handleChoiceTable = () => {
        //* Verificando horários de funcionamento
        if (storeIsOpen()) {
            setShowConfirmTable(true);
        } 
    }

    const handleSend = () => {

        setShowConfirmTable(false);

        const showCart = async () => {
            const payload = {
                cart_id: cartDisplay.id,
                store_id: currentStore.id,
                order_type: Number(getOrderTypeIdByName(orderType)),
            }
            try {
                setMainLoading(true);
                setMainLoadingIcon(true);
                setMainLoadingTitle('Verificando sua comanda');
                setMainLoadingMessage('Aguarde...');
                const cartService = new CartService(orderType, token);
                const getCart = await cartService.show(payload);

                if (getCart) {
                    if (getCart.status === CART_STATUS_FINISHED) {
                        cartFinished({id: cartDisplay.id, order: getCart.order});
                        setMainLoading(false);
                        onCloseCart();
                    }
                    else {
                        const formattedItems = cartConference.map((item: any) => ({
                            id: item.complementable_id ?? item.id,
                            step_complement_id: item.complementable_id ? item.id : null,
                            cart_id: cartDisplay.id,
                            discount: null,
                            name: item.name,
                            observation: item.observation ?? '',
                            price: item.price,
                            product_category_id: category?.id,
                            total_with_discount: item.price,
                            total_calculated: item.price,
                            total: item.price,
                            description: item.description,
                            product_id: item.complementable_id ?? item.id,
                            quantity: item.quantity,
                            ...(item.steps && item.steps.length > 0 && { steps: item.steps })  //* Adiciona steps apenas se houver (composto)
                        }));

                        const payload = {
                            apply_tax: true,
                            table_id: cartDisplay.table.id,
                            store_id: currentStore.id,
                            order_type: Number(getOrderTypeIdByName(orderType)),
                            items: formattedItems
                        }

                        sendProducts(cartDisplay.id, payload).then((success: any) => {
                            if (success) {
                                onCloseCart(false);
                            }
                        });
                    }
                } else {
                    setMainLoading(false);
                }
            } catch (error: any) {
                if (error.response?.data?.error === ERROR_CART_NOT_FOUND) {
                    cartNotFound();
                } else {
                    setMainLoading(false);
                }
            }
        }
        showCart();
    }

    const handleUpdate = (itemConferenceId: number, quantity: number) => {
        updateProductInConference(itemConferenceId, quantity);
    }

    const handleDelete = (itemConferenceId: number) => {
        deleteProductFromConference(itemConferenceId);
    }

    const handleCardClick = (product: any) => {
        setShowEditProduct(true);
        setCurrentProductToEdit(product);
    }

    const handleCancelEditProduct = () => {
        setShowEditProduct(false);
        setCurrentProductToEdit(null);
    }

    const handleConfirChangeEdit = (observation: string) => {
        updateObservationProductInConference(currentProductToEdit.itemConferenceId, observation);
        handleCancelEditProduct();
    }

    return (
        <section className='p-4 pt-0 h-full'>

            <section className="flex flex-col justify-between h-full gap-4">
                <div className='flex flex-col gap-3 w-full h-full md:overflow-y-auto'>

                    <div className='h-full min-h-32 gap-10'>
                        <Scrollbars autoHide={true}>
                            <div className='flex flex-col divide-y divide-gray-100'>
                                {cart.map((product: any, index: number) => 
                                    <CardProductCartConference
                                        product={product} 
                                        onDec={(quantity: number) => handleUpdate(product.itemConferenceId, quantity)} 
                                        onInc={(quantity: number) => handleUpdate(product.itemConferenceId, quantity)} 
                                        onDelete={() => handleDelete(product.itemConferenceId)} 
                                        key={product.itemConferenceId}
                                        onCardClick={() => handleCardClick(product)}
                                    />
                                )}
                            </div>
                        </Scrollbars>
                    </div>
                </div>

                <div className="flex gap-2 w-full">
                    <Button onClick={() => onCloseCart(false)} className='justify-center items-center text-nowrap w-full text-sm gap-0.5'>
                        <TbShoppingBagPlus size={20} className='-mt-0.5' />
                        <span>Produtos</span>
                    </Button>
                    <ButtonPrimary onClick={() => handleChoiceTable()} className='justify-center items-center w-full text-sm gap-0.5'>
                        Enviar pedido
                    </ButtonPrimary>
                </div>
            </section>

            {/* MODAL CONFIRM TABLE */}
            <Modal open={showConfirmTable} className="max-w-md m-4" onClose={() => setShowConfirmTable(false)}>
                <TableChoice onHandleSend={handleSend} onShowConfirmTable={(e: boolean) => setShowConfirmTable(e)} />
            </Modal>

            {/* MODAL OBSERVACAO DO PRODUTO */}
            {currentProductToEdit &&
                <Modal open={showEditProduct} className="max-w-sm m-4" onClose={() => handleCancelEditProduct()}>
                    <EditObservationConference 
                        product={currentProductToEdit} 
                        onConfirm={(observation: string) => handleConfirChangeEdit(observation)}
                        onCancel={() => handleCancelEditProduct()} 
                    />
                </Modal>
            }

        </section>
    );
};

export default CartConference;
