import React from 'react';
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { HiBars3, HiMiniUserGroup } from "react-icons/hi2";
import { TbBuildingSkyscraper, TbChecklist, TbHome, TbHomeFilled, TbUserCircle } from "react-icons/tb";
import { useLoginContext } from '../../Contexts/LoginContext';
import { Link, useLocation } from 'react-router-dom';

interface MenuMobileProps {
  onHandleClick: CallableFunction;
}

const MenuMobile = ({
  onHandleClick = () => {},
}: MenuMobileProps) => {

  const { setShowLogin } = useLoginContext();
  
  const location = useLocation();

  const headerMenu = [
    { name: 'Home', href: '/', icon: <TbHome /> },
    { name: 'Lojas', href: '/lojas', icon: <TbBuildingSkyscraper /> },
    { name: 'Seja um Franqueado', href: '/', icon: <HiMiniUserGroup /> },
    { name: 'Pagamento', href: '/checkout', icon: <HiMiniUserGroup /> },
  ]

  const defaultClassName = `
      flex flex-col gap-0.5 items-center justify-center 
      w-full h-full 
      hover:bg-gray-50
      transition ease-in-out duration-400
      disabled:pointer-events-none
      disabled:text-gray-300
      `;
  const linkActived = 'bg-gray-50/50 pointer-events-none'
  const linkDisabled = 'pointer-events-none opacity-50'

  return (
    <div className='flex justify-between w-full h-full text-xs font-semibold bg-white shadow-reverse-sm'>

      {/* MENU POPOVER MOBILE */}
      <Popover className="relative flex w-full justify-center bg-gray-50 ring-1 ring-gray-100">
          <PopoverButton className="inline-flex items-center justify-center w-full focus:outline-none focus:ring-0">
              <HiBars3 size={30} />
          </PopoverButton>

          <PopoverPanel
              transition
              className="absolute bottom-14 z-10 flex w-auto left-0 -translate-x-0 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
          >
              <div className="max-w-auto flex-auto divide-red-500 overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-gray-900/5">
                  <div className="overflow-hidden divide-y divide-gray-100">
                      {headerMenu.map((item) => (
                          <div key={item.name} className="group relative flex hover:bg-gray-50">
                              <Link to={item.href} className="flex items-center gap-1 w-full text-nowrap font-semibold text-sm p-3 leading-5">
                                {item.icon}
                                  <span>{item.name}</span>
                              </Link>
                          </div>
                      ))}
                  </div>
              </div>
          </PopoverPanel>
      </Popover>

      <Link to={'/'} className={`${defaultClassName} ${location.pathname === '/' && linkActived}`}>
        {location.pathname === '/' ? <TbHomeFilled size={20} /> : <TbHome size={20} /> }
        Home
      </Link>

      {/* <button onClick={() => onHandleClick({url: '/'})} className={defaultClassName}>
        <TbZoom size={20} />Busca
      </button> */}

      <Link to={'/'} className={`
        ${defaultClassName} 
        ${linkDisabled}
      `}>
        {location.pathname === '/' ? <TbChecklist size={20} /> : <TbChecklist size={20} /> }
        Pedidos
      </Link>
      
      <button onClick={() => setShowLogin(true)}  className={defaultClassName}>
        <TbUserCircle size={20} />Entrar
      {/* <HiMiniUserCircle size={20} /> logado */}
      </button>
    </div>
  )
}

export default MenuMobile;