import React from 'react';
// import { useLoginContext } from '../../Contexts/LoginContext';
import ButtonPrimary from '../Form/ButtonPrimary';
import { useLayoutContext } from '../../Contexts/LayoutContext';
import ButtonOpenStoreCounter from './ButtonOpenStoreCounter';
import StepsStoreCounter from './StepsStoreCounter';


const WelcomeStoreCounter = () => {

    // const { setShowLoginSuccessful } = useLoginContext();
    const { company, setWelcomeScreenRead } = useLayoutContext();

    return (
        <div className="flex flex-col items-center gap-6 w-full h-full p-8 px-6 md:w-8/12">
            <img src={company.square_image} alt="" className="w-36 sm:w-40 md:44 transition-width ease-in-out duration-200" />
            <div className="grid gap-2">
                <h2 className="font-extrabold text-xl text-custom-primary">Olá! Seja muito bem-vindo a {company.name}</h2>
                <div className="text-sm text-gray-500">Estamos prontos para tornar sua experiência especial. Aproveite nosso menu e sinta-se à vontade para abrir a sua própria comanda, seguindo os passos abaixo. É muuito fácil! Qualquer dúvida, é só chamar o garçom!</div>
            </div>
            <div>
                <StepsStoreCounter />
            </div>
            <div className="flex flex-col sm:flex-row items-center justify-center gap-3 w-full">
                <ButtonOpenStoreCounter forceLabel label={'Abra sua comanda'} />
                {/* <ButtonPrimary outline onClick={() => setShowLoginSuccessful(true)} className='items-center justify-center w-full text-no-wrap sm:max-w-64 text-nowrap'>Como funciona?</ButtonPrimary> */}
                <ButtonPrimary outline onClick={() => setWelcomeScreenRead(true)} className='items-center justify-center w-full text-nowrap sm:max-w-64'>Ver cardápio</ButtonPrimary>
            </div>
        </div>
    );
};

export default WelcomeStoreCounter;
