import React from 'react';
import { TbShoppingBagExclamation } from 'react-icons/tb';
import ButtonPrimary from '../../Form/ButtonPrimary';
import { useNavigate, useLocation } from 'react-router-dom';

interface CardEmptyProps {
  onCloseCart?: CallableFunction;
}

const CardEmpty = ({
  onCloseCart,
}: CardEmptyProps) => {

  const navigate = useNavigate()
  const location = useLocation()
  
  const handleClick = () => {
    if (location.pathname === '/checkout') {
      navigate('/')
    } else {
      onCloseCart!(false)
    }
  }

  return (
    <section className='flex flex-col w-full px-2 h-full items-center justify-center text-center'>
        <TbShoppingBagExclamation size={50} className="mb-3 fadeInIcon" />
        <h3 className='text-lg font-bold'>Seu carrinho está vazio</h3>
        <p className='text-sm'>Descubra nosso menu e saboreie sua seleção favorita!</p>

        <ButtonPrimary
            onClick={() => handleClick()}
            className="mt-5 text-sm px-4">
            Clique aqui para começar
        </ButtonPrimary>
    </section>
  )
}

export default CardEmpty;