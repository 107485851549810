import React, { useState } from 'react';
import { useOrderTypeContext } from '../../Contexts/OrderTypeContext';
import CustomerService from '../../Services/CustomerService';
import { useLoginContext } from '../../Contexts/LoginContext';
import { useStoreContext } from '../../Contexts/StoreContext';
import ButtonPrimary from './ButtonPrimary';
import Button from './Button';
import { HiXMark } from 'react-icons/hi2';
import { IMaskInput } from 'react-imask';
import { TbCircleFilled, TbQuestionMark, TbShoppingBagPlus, TbX } from 'react-icons/tb';
import { useLayoutContext } from '../../Contexts/LayoutContext';

import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useCartContext } from '../../Contexts/CartContext';
import { hideKeyboard } from '../../Utils/Helpers';
import { ImSpinner8 } from 'react-icons/im';
import { formatZipcode } from '../../Utils/Formatters';
// import Img from '../Layout/Img';


const currentYear = new Date().getFullYear();
const lastTwoDigitsOfCurrentYear = String(currentYear).slice(-2);
const currentMonth = String(new Date().getMonth() + 1).padStart(2, '0');

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Pelo menos 2 caracteres')
        .required('Obrigatório'),
    number: Yup.string()
        .min(16, 'Pelo menos 16 caracteres')
        .required('Obrigatório'),
    expirationFull: Yup.string()
        .test('len', 'Inválido', value => {
            if (!value || value.length !== 4) {
                return false;
            }
            const month = value.substring(0, 2);
            const year = value.substring(2, 4);
            const isValidMonth = month >= '01' && month <= '12';
            const isValidYear = year >= lastTwoDigitsOfCurrentYear;
            const isValidCurrentMonth = 
                (year === lastTwoDigitsOfCurrentYear && month >= currentMonth) ||
                (year > lastTwoDigitsOfCurrentYear);
            return isValidMonth && isValidYear && isValidCurrentMonth;
        })
        .required('Obrigatório'),
    cvc: Yup.string()
        .min(3, 'Pelo menos 3 caracteres')
        .required('Obrigatório'),
    holder_document: Yup.string()
        .test(
            'len',
            function (value) {
                if (value && value.length < 11) {
                    return this.createError({ message: 'CPF incompleto' });
                } else if (value && value.length > 11 && value.length < 14) {
                    return this.createError({ message: 'CNPJ incompleto' });
                } else if (value && value.length > 14) {
                    return this.createError({ message: 'Documento inválido' });
                }
                return true;
            }
        )
        .required('Obrigatório'),
    billing_zip_code: Yup.string()
        .min(8, 'Pelo menos 8 caracteres')
        .required('Obrigatório'),
    billing_state: Yup.string()
        .min(2, 'Pelo menos 2 caracteres')
        .required('Obrigatório'),
    billing_city: Yup.string()
        .min(2, 'Pelo menos 2 caracteres')
        .required('Obrigatório'),
    billing_street: Yup.string().required('Obrigatório'),
    billing_neighborhood: Yup.string().required('Obrigatório'),
  });

interface FormRegisterCustomerCardProps {
    onCancel: CallableFunction;
    onSetCard: CallableFunction;
    onEditMethod: CallableFunction;
}

const FormRegisterCustomerCard = ({onCancel, onEditMethod = () => {}, onSetCard = () => {}}: FormRegisterCustomerCardProps) => {

    const { orderType } = useOrderTypeContext();
    const { token } = useLoginContext();
    const { currentStore } = useStoreContext();
    const { setMainLoading, setMainLoadingIcon, setMainLoadingIconInfo, setMainLoadingIconError, setMainLoadingIconNoSearchMap, setMainLoadingTitle, setMainLoadingMessage, setMainLoadingCloseButton } = useLayoutContext();
    const navigate = useNavigate();
    const { setReturnToCheckout } = useCartContext();

    const initialCardData = {
        name: '',
        number: '',
        expiration_month: '',
        expiration_year: '',
        cvc: '',
        holder_document: '',
        billing_country: 'br',
        billing_zip_code: '',
        billing_state: '',
        billing_city: '',
        billing_neighborhood: '',
        billing_street: '',
        billing_street_number: '',
        store_id: currentStore.id,
        main: true,
        expirationFull: '',
        save_card: true
    }

    const handleInfoCvc = () => {
        setMainLoadingIcon(false);
        setMainLoadingIconInfo(true);
        setMainLoading(true);
        setMainLoadingTitle('Código de segurança.');
        setMainLoadingMessage('Digite o código de segurança (CVC) de 3 ou 4 dígitos localizado no verso do seu cartão.');
        setMainLoadingCloseButton(true);
    }

    const handleRegisterCard = async (cardData: any) => {
        const payload = {
            name: cardData.name.toUpperCase(),
            number: cardData.number,
            expiration_month: cardData.expiration_month,
            expiration_year: cardData.expiration_year,
            cvc: cardData.cvc,
            holder_document: cardData.holder_document,
            billing_country: cardData.billing_country,
            billing_zip_code: cardData.billing_zip_code,
            billing_state: cardData.billing_state,
            billing_city: cardData.billing_city,
            billing_neighborhood: cardData.billing_neighborhood,
            billing_street: cardData.billing_street,
            billing_street_number: cardData.billing_street_number,
            store_id: currentStore.id,
            main: true
        }

        //* PAGAMENTO SEM REGISTRO DO CARTÃO
        if (!cardData.save_card) {
            onSetCard(payload);
        } else {
            //* PAGAMENTO COM REGISTRO DO CARTÃO
            setMainLoading(true)
            setMainLoadingTitle('Salvando cartão')
            setMainLoadingMessage('Aguarde...')
            try {
                const customerService = new CustomerService(orderType, token);
                const registeredCard = await customerService.registerCard(payload);

                if (registeredCard) {
                    const payload = {
                        customer_card_id: registeredCard.id,
                        customer_card_cvv: cardData.cvc,
                    }
                    onSetCard(payload);
                    // setMainLoading(false) //* O fechamento do modal será no feito no createOrder no checkout.
                }
                } catch (error) {
                    console.error('Error registerCard:', error);
                    setMainLoadingIcon(false);
                    setMainLoadingIconError(true);
                    setMainLoadingTitle('Ops! Algo não deu certo.');
                    setMainLoadingMessage('Verifique os dados informados, tente salvar novamente e, caso o erro persista, chame o garçom.');
                    setMainLoadingCloseButton(true)
                }
        }
    }

    const isFieldRequired = (schema: any, fieldName: string) => {
        const field = schema.describe().fields[fieldName];
        return field.tests.some((test: any) => test.name === 'required');
    };

    const handleAddProduct = () => {
        navigate('/')
        setReturnToCheckout(true)
    }

    // const handleEditMethod = () => {
    //     onEditMethod();
    // }

    // const handleCancel = () => {
    //     navigate('/')
    //     setReturnToCheckout(false)
    // }

    const [loadingZipcode, setLoadingZipcode] = useState(false)
    const [showFieldsAddress, setShowFieldsAddress] = useState(false)

    // const getBrandCard = (numero: string): string => {
    //     const brands: { [key: string]: RegExp } = {
    //         'https://shopfood-storage-homologation.s3.amazonaws.com/public/images/payment_methods/JrgCNsiZ5rg2f4wfh8IMWXbvk16QamnJS2eAho49.png': /^4[0-9]{12}(?:[0-9]{3})$/,//Visa
    //         'https://shopfood-storage-homologation.s3.amazonaws.com/public/images/payment_methods/Z6IEHlXAgSJxmfM6GRwWd8Lly3un3qKWZPXJ2Zpt.png': /^5[1-5][0-9]{14}$/,
    //         'American Express': /^3[47][0-9]{13}$/,
    //         'https://shopfood-storage-homologation.s3.amazonaws.com/public/images/payment_methods/qePSApRZHlwWxmj00wdhcZJDxOEofLJiENEjkppo.png': /^(636368|438935|504175|451416|5067[0-9]{10,11})$/,//Elo
    //         'Hipercard': /^(606282|384100|637099)$/,
    //         'Discover': /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    //         'Diners Club': /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    //         'JCB': /^(?:2131|1800|35\d{3})\d{11}$/,
    //         'VISA Electron': /^(4026|417500|4508|4844|4913|4917)[0-9]{12}$/
    //     };
    //     for (const brand in brands) {
    //         if (brands[brand].test(numero)) {
    //             return brand;
    //         }
    //     }
    //     return '';
    // }

  return (
    <>
        <Formik
            initialValues={initialCardData}
            validationSchema={validationSchema}
            onSubmit={(cardData) => {
                handleRegisterCard(cardData);
            }}
            >
            {({ setFieldValue, values }) => (
                <Form className="flex flex-col justify-between h-fitgap-4 mb-2 bg-gray-50 rounded-lg shadow-sm">
                    <div className='h-full grid gap-3 p-3'>
                        <div className='grid'>
                            <span className='text-lg leading-5 font-extrabold'>Informe os dados cartão</span>
                            <span className='text-base leading-5 text-gray-500'>É prático e totalmente seguro.</span>
                        </div>

                        <div className='grid gap-2'>
                            <div className='flex gap-2'>
                                <div className='w-full'>
                                    <label htmlFor="name" className='text-xs text-gray-500'>
                                        Nome do titular
                                        {isFieldRequired(validationSchema, 'name') && (<sup className="text-shopfood-error inline-flex ml-0.5"> <TbCircleFilled size={7} /></sup>)}
                                    </label>
                                    <IMaskInput
                                        id="name"
                                        name="name"
                                        mask={/^[a-zA-ZÀ-ÿ\s]*$/}
                                        value={values.name}
                                        onAccept={(value) => setFieldValue('name', value)} // Atualiza o valor no Formik
                                        unmask={true} // true|false|'typed'
                                        placeholder=""
                                        className="relative uppercase block w-full rounded border-0 p-2 ring-1 ring-gray-300 placeholder:text-gray-300 font-bold focus:ring-2 focus:ring-gray-500 transition-all ease-in-out duration-200"
                                    />
                                    <ErrorMessage name="name" component="div" className='text-xs text-shopfood-error pt-1' />
                                </div>
                                <div className='w-32'>
                                    <label htmlFor="expirationFull" className='text-xs text-gray-500'>
                                        Validade
                                        {isFieldRequired(validationSchema, 'expirationFull') && (<sup className="text-shopfood-error inline-flex ml-0.5"> <TbCircleFilled size={7} /></sup>)}
                                    </label>
                                    <IMaskInput
                                        id="expirationFull"
                                        name="expirationFull"
                                        inputMode="numeric"
                                        mask={"00 / 00"}
                                        radix=""
                                        value={values.expirationFull}
                                        onAccept={(value) => {
                                            if (value.length === 4) {
                                                setFieldValue('expiration_month', value.slice(0, 2))
                                                setFieldValue('expiration_year', value.slice(2))
                                            } else {
                                                setFieldValue('expiration_month', '')
                                                setFieldValue('expiration_year', '')
                                            }
                                            setFieldValue('expirationFull', value);
                                        }}
                                        unmask={true} // true|false|'typed'
                                        placeholder='-- / --'
                                        className='relative block w-full autofill:bg-yellow-600 rounded border-0 p-2 ring-1 ring-gray-300 placeholder:text-gray-300 placeholder:font-normal font-bold focus:ring-2 focus:ring-gray-500 transition-all ease-in-out duration-200 disabled:bg-gray-200'
                                    />
                                    <ErrorMessage name="expirationFull" component="div" className='text-xs text-shopfood-error pt-1' />
                                </div>
                            </div>

                            <div className='flex gap-2'>
                                <div className='w-full'>
                                    <label htmlFor="number" className='text-xs text-gray-500'>
                                        Número do cartão
                                        {isFieldRequired(validationSchema, 'number') && (<sup className="text-shopfood-error inline-flex ml-0.5"> <TbCircleFilled size={7} /></sup>)}
                                    </label>
                                    <div className='relative flex items-center justify-between'>
                                        <IMaskInput
                                            id="number"
                                            name="number"
                                            inputMode="numeric"
                                            mask={"0000 0000 0000 0000"}
                                            value={values.number}
                                            onAccept={(value) => setFieldValue('number', value)}
                                            unmask={true}
                                            placeholder='---- ---- ---- ----'
                                            className="relative block w-full rounded border-0 p-2 ring-1 ring-gray-300 placeholder:text-gray-300 font-bold focus:ring-2 focus:ring-gray-500 transition-all ease-in-out duration-200"
                                        />
                                        {/* { getBrandCard(values.number) && <Img src={getBrandCard(values.number)} alt='' className='w-6 absolute right-3' />} */}
                                    </div>
                                    <ErrorMessage name="number" component="div" className='text-xs text-shopfood-error pt-1' />
                                </div>
                                <div className='w-32'>
                                    <label htmlFor="cvc" className='text-xs text-gray-500'>
                                        CVC
                                        {isFieldRequired(validationSchema, 'cvc') && (<sup className="text-shopfood-error inline-flex ml-0.5"> <TbCircleFilled size={7} /></sup>)}
                                    </label>
                                    <div className='relative flex items-center justify-between'>
                                        <IMaskInput
                                            id="cvc"
                                            name="cvc"
                                            inputMode="numeric"
                                            mask={"0000"}
                                            value={values.cvc}
                                            onAccept={(value) => setFieldValue('cvc', value)}
                                            unmask={true}
                                            placeholder='---'
                                            className="relative block w-full rounded border-0 p-2 ring-1 ring-gray-300 placeholder:text-gray-300 font-bold focus:ring-2 focus:ring-gray-500 transition-all ease-in-out duration-200"
                                        />
                                        <TbQuestionMark size={22} onClick={handleInfoCvc} className='absolute cursor-pointer p-1 right-2 bg-gray-100 hover:bg-gray-200 rounded-full' />
                                    </div>
                                    <ErrorMessage name="cvc" component="div" className='text-xs text-shopfood-error pt-1' />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="holder_document" className='text-xs text-gray-500'>
                                    CPF do titular
                                    {isFieldRequired(validationSchema, 'holder_document') && (<sup className="text-shopfood-error inline-flex ml-0.5"> <TbCircleFilled size={7} /></sup>)}
                                </label>
                                <IMaskInput
                                    id="holder_document"
                                    name="holder_document"
                                    inputMode="numeric"
                                    mask={[
                                        { mask: '000.000.000-00', maxLength: 11 },  // CPF
                                        // { mask: '00.000.000/0000-00' }  // CNPJ
                                    ]}
                                    value={values.holder_document}
                                    onAccept={(value) => setFieldValue('holder_document', value)}
                                    unmask={true}
                                    placeholder='---.---.--- --'
                                    className="relative block w-full rounded border-0 p-2 ring-1 ring-gray-300 placeholder:text-gray-300 font-bold focus:ring-2 focus:ring-gray-500 transition-all ease-in-out duration-200"
                                />
                                <ErrorMessage name="holder_document" component="div" className='text-xs text-shopfood-error pt-1' />
                            </div>





                            <div className='w-48'>
                                <label htmlFor="billing_zip_code" className='text-xs text-gray-500'>
                                    CEP
                                    {isFieldRequired(validationSchema, 'billing_zip_code') && (<sup className="text-shopfood-error inline-flex ml-0.5"> <TbCircleFilled size={7} /></sup>)}
                                </label>
                                <div className='relative flex items-center justify-between'>
                                    <IMaskInput
                                        id="billing_zip_code"
                                        name="billing_zip_code"
                                        inputMode="numeric"
                                        mask={'00000-000'}
                                        value={values.billing_zip_code}
                                        onAccept={(value) => {
                                            if (value.length === 8) {
                                                hideKeyboard();
                                                setLoadingZipcode(true);
                                                fetch(`https://ceps.shopfood.io/addresses/search?q=${value}`)
                                                    .then(response => {
                                                        if (!response.ok) {
                                                            throw new Error('Network response was not ok');
                                                        }
                                                        return response.json();
                                                    })
                                                    .then(data => {
                                                        if (Array.isArray(data) && data.length > 0) {
                                                            const dataResp = data[0];
                                                            setFieldValue('billing_state', dataResp.state);
                                                            setFieldValue('billing_city', dataResp.city);
                                                            setFieldValue('billing_neighborhood', dataResp.neighborhood);
                                                            setFieldValue('billing_street', dataResp.street);
                                                        }
                                                        else {
                                                            setMainLoadingIcon(false);
                                                            setMainLoadingIconNoSearchMap(true);
                                                            setMainLoading(true);
                                                            setMainLoadingTitle(`Infelizmente, não localizamos as informações do CEP ${formatZipcode(value)}`);
                                                            setMainLoadingMessage('Verifique se está correto e, caso esteja, preencha as informações do endereço manualmente.');
                                                            setMainLoadingCloseButton(true);
                                                        }
                                                        setShowFieldsAddress(true);
                                                    })
                                                    .catch(error => {
                                                        console.error('Houve um problema com a consulta:', error);
                                                    })
                                                    .finally(() => {
                                                        setLoadingZipcode(false);
                                                    });
                                            } else {
                                                setFieldValue('billing_state', '');
                                                setFieldValue('billing_city', '');
                                                setFieldValue('billing_neighborhood', '');
                                                setFieldValue('billing_street', '');
                                                setShowFieldsAddress(false);
                                            }
                                            setFieldValue('billing_zip_code', value)
                                        }}
                                        unmask={true} // true|false|'typed'
                                        placeholder="----- ---"
                                        className="relative uppercase block w-full rounded border-0 p-2 ring-1 ring-gray-300 placeholder:text-gray-300 font-bold focus:ring-2 focus:ring-gray-500 transition-all ease-in-out duration-200"
                                    />
                                    {loadingZipcode 
                                            ? <ImSpinner8 size={22} className="animate-spin absolute p-1 right-2 rounded-full transition-all ease-in-out duration-200" />
                                            : values.billing_zip_code && <TbX size={22} onClick={() => setFieldValue('billing_zip_code', '')} className='absolute cursor-pointer p-1 right-2 hover:bg-gray-200 rounded-full  transition-all ease-in-out duration-200' />
                                    }
                                </div>
                                <ErrorMessage name="billing_zip_code" component="div" className='text-xs text-shopfood-error pt-1' />
                            </div>



                            {showFieldsAddress &&
                                <>

                                    <div className='flex gap-2'>
                                        <div className='w-full'>
                                            <label htmlFor="billing_street" className='text-xs text-gray-500'>
                                                Rua
                                                {isFieldRequired(validationSchema, 'billing_street') && (<sup className="text-shopfood-error inline-flex ml-0.5"> <TbCircleFilled size={7} /></sup>)}
                                            </label>
                                            <IMaskInput
                                                id="billing_street"
                                                name="billing_street"
                                                mask={''}
                                                value={values.billing_street}
                                                onAccept={(value) => setFieldValue('billing_street', value)}
                                                unmask={true} // true|false|'typed'
                                                placeholder=""
                                                disabled={loadingZipcode}
                                                className="relative block w-full rounded border-0 p-2 ring-1 ring-gray-300 disabled:text-gray-400 placeholder:text-gray-300 font-bold focus:ring-2 focus:ring-gray-500 transition-all ease-in-out duration-200"
                                            />
                                            <ErrorMessage name="billing_street" component="div" className='text-xs text-shopfood-error pt-1' />
                                        </div>

                                        <div className='w-32'>
                                            <label htmlFor="billing_street_number" className='text-xs text-gray-500'>Número</label>
                                            <IMaskInput
                                                id="billing_street_number"
                                                name="billing_street_number"
                                                mask={''}
                                                value={values.billing_street_number}
                                                onAccept={(value) => setFieldValue('billing_street_number', value)}
                                                unmask={true} // true|false|'typed'
                                                placeholder=""
                                                disabled={loadingZipcode}
                                                className="relative block w-full rounded border-0 p-2 ring-1 ring-gray-300 disabled:text-gray-400 placeholder:text-gray-300 font-bold focus:ring-2 focus:ring-gray-500 transition-all ease-in-out duration-200"
                                            />
                                            <ErrorMessage name="billing_street_number" component="div" className='text-xs text-shopfood-error pt-1' />
                                        </div>

                                    </div>


                                    <div className='w-full'>
                                        <label htmlFor="billing_neighborhood" className='text-xs text-gray-500'>
                                            Bairro
                                            {isFieldRequired(validationSchema, 'billing_neighborhood') && (<sup className="text-shopfood-error inline-flex ml-0.5"> <TbCircleFilled size={7} /></sup>)}
                                        </label>
                                        <IMaskInput
                                            id="billing_neighborhood"
                                            name="billing_neighborhood"
                                            mask={''}
                                            value={values.billing_neighborhood}
                                            onAccept={(value) => setFieldValue('billing_neighborhood', value)}
                                            unmask={true} // true|false|'typed'
                                            placeholder=""
                                            disabled={loadingZipcode}
                                            className="relative block w-full rounded border-0 p-2 ring-1 ring-gray-300 disabled:text-gray-400 placeholder:text-gray-300 font-bold focus:ring-2 focus:ring-gray-500 transition-all ease-in-out duration-200"
                                        />
                                        <ErrorMessage name="billing_neighborhood" component="div" className='text-xs text-shopfood-error pt-1' />
                                    </div>


                                    <div className='flex gap-2'>
                                        <div className='w-full'>
                                            <label htmlFor="billing_city" className='text-xs text-gray-500'>
                                                Cidade
                                                {isFieldRequired(validationSchema, 'billing_city') && (<sup className="text-shopfood-error inline-flex ml-0.5"> <TbCircleFilled size={7} /></sup>)}
                                            </label>
                                            <IMaskInput
                                                id="billing_city"
                                                name="billing_city"
                                                mask={/^[a-zA-ZÀ-ÿ\s]*$/}
                                                value={values.billing_city}
                                                onAccept={(value) => setFieldValue('billing_city', value)}
                                                unmask={true} // true|false|'typed'
                                                placeholder=""
                                                disabled={loadingZipcode}
                                                className="relative block w-full rounded border-0 p-2 ring-1 ring-gray-300 disabled:text-gray-400 placeholder:text-gray-300 font-bold focus:ring-2 focus:ring-gray-500 transition-all ease-in-out duration-200"
                                            />
                                            <ErrorMessage name="billing_city" component="div" className='text-xs text-shopfood-error pt-1' />
                                        </div>

                                        <div className='w-32'>
                                            <label htmlFor="billing_state" className='text-xs text-gray-500'>
                                                Estado
                                                {isFieldRequired(validationSchema, 'billing_state') && (<sup className="text-shopfood-error inline-flex ml-0.5"> <TbCircleFilled size={7} /></sup>)}
                                            </label>
                                            <IMaskInput
                                                id="billing_state"
                                                name="billing_state"
                                                mask={/^[a-zA-ZÀ-ÿ\s]*$/}
                                                value={values.billing_state}
                                                onAccept={(value) => setFieldValue('billing_state', value)}
                                                unmask={true} // true|false|'typed'
                                                placeholder=""
                                                disabled={loadingZipcode}
                                                className="relative block w-full rounded border-0 p-2 ring-1 ring-gray-300 disabled:text-gray-400 placeholder:text-gray-300 font-bold focus:ring-2 focus:ring-gray-500 transition-all ease-in-out duration-200"
                                            />
                                            <ErrorMessage name="billing_state" component="div" className='text-xs text-shopfood-error pt-1' />
                                        </div>

                                    </div>

                                </>
                            }

                            <div className='flex items-center gap-1 pt-2'>
                                <input
                                    type="checkbox"
                                    id="save_card"
                                    name="save_card"
                                    checked={values.save_card ? true : false}
                                    onChange={(event) => setFieldValue('save_card', event.target.checked)}
                                    className="w-6 h-6 text-blue-600 bg-white border-2 border-gray-300 rounded focus:ring-custom-primary hover:border-custom-primary-hover checked:bg-custom-primary checked:border-custom-primary transition duration-200 ease-in-out"
                                />
                                <label htmlFor='save_card' className={`cursor-pointer text-xs transition ${values.save_card ? 'font-medium text-custom-primary' : 'text-gray-400'}`}>Salvar cartão para compras futuras</label>
                            </div>

                        </div>
                    </div>

                    {/*  md:right-[28rem] equivalente ao tamanho da conferência no md (max-w-md) */}
                    <div className='flex gap-2 fixed z-50 left-0 right-0 md:right-[28rem] bottom-0 bg-white p-4 md:justify-end'>

                        <Button onClick={handleAddProduct} className='justify-center items-center text-sm text-nowrap gap-0.5'>
                            <TbShoppingBagPlus size={20} className='-mt-0.5' />
                            <span className='hidden lg:inline-block'>Adicionar produtos</span>
                        </Button>

                        <ButtonPrimary type={'submit'} className='w-full items-center justify-center text-sm xs:max-w-sm md:max-w-xs' onClick={() => {}}>Efetuar pagamento</ButtonPrimary>

                        {/* <Button onClick={handleEditMethod} className='justify-center items-center text-sm'>
                            <TbPencil size={20} />
                        </Button> */}

                        <Button onClick={() => onCancel()} className='justify-center items-center text-nowrap text-sm'>
                            <HiXMark size={20} />
                        </Button>
                    </div>

                </Form>

            )}
        </Formik>
    </>
  )
}

export default FormRegisterCustomerCard;
