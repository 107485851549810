'use client'

import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'
import { PropsWithChildren, useEffect, useState } from 'react';

interface ModalProps {
    open: boolean;
    closeable?: boolean;
    onClose: CallableFunction;
    style?: any
    className?: string
}

const Modal = ({
    children,
    open = false,
    closeable = true,
    onClose = () => {},
    style = '',
    className = ''
}: PropsWithChildren<ModalProps>) => {

  const [scaleNotCloseable, setScaleNotCloseable] = useState(false)
  const [scale, setScale] = useState('')
  const close = () => {
    if (closeable) {
      onClose();
    } else {
      setScaleNotCloseable(true)
    }
  };

  useEffect(() => {
    setScale('scale-110');
    setTimeout(() => {
      setScale('scale-100');
      setScaleNotCloseable(false)
    }, 50);
  }, [scaleNotCloseable])

  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      event.preventDefault();
      onClose();
    };

    window.addEventListener('popstate', handlePopState);

    if (!window.history.state) {
      window.history.pushState({ page: 'modal' }, ''); 
    }

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [onClose]);


  return (
    <Dialog open={open} onClose={close} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 bottom-0 z-10 w-screen overflow-y-auto h-dvh min-h-dvh">
        <div className="flex min-h-full items-end justify-center text-center items-center">
          <DialogPanel
            transition
            className={`
              ${scale}
              ${className}
              relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95
              w-full
            `}
          >
            {children}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}

export default Modal;