import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/react'
import { useEffect, useState } from 'react'
import { TbPhotoX, TbX, TbZoom } from 'react-icons/tb'
import { useStoreContext } from '../../Contexts/StoreContext';
import { useAddProductContext } from '../../Contexts/AddProductContext';
import Img from './Img';
import { useLayoutContext } from '../../Contexts/LayoutContext';
import { ImSpinner8 } from 'react-icons/im';
import { normalizeStringToSearch } from '../../Utils/Helpers';

interface SearchProps {
    open: boolean;
    onClose: CallableFunction;
    onSearching?: CallableFunction;
}

const Search = ({open = false, onClose, onSearching}: SearchProps) => {

  const { productsToSearch } = useLayoutContext();
  const [ showList, setShowList ] = useState<boolean>(false);

  const [query, setQuery] = useState('');

  const { setShowAddProduct, setProduct } = useAddProductContext();
  const { currentStore } = useStoreContext();

  const handleChangeOption = (e: any) => {
    onClose();
    setQuery('');
    setShowList(false);
    setShowAddProduct(true)
    setProduct(e)
  }

  useEffect(() => {
    onSearching!(query.length > 0)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const filteredProducts =
    query === ''
      ? []
      : productsToSearch.filter((product: any) => {
          return normalizeStringToSearch(product.name).includes(normalizeStringToSearch(query));
        })

  return (
      <div className={`overflow-y-auto z-10 ring-1 ring-gray-100 w-full shadow-sm ${!currentStore && 'hidden'} ${query !== '' ? 'rounded-t-lg' : 'rounded-lg'}`}>
          <Combobox
            onChange={(person) => handleChangeOption(person)}
          >
            <div className="relative">
              {currentStore && productsToSearch.length === 0 
                ? <ImSpinner8 className="animate-spin pointer-events-none absolute left-3 top-3.5 h-5 w-5 text-gray-400" />
                : <TbZoom className="pointer-events-none absolute left-3 top-3.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
              }

              <div className='relative flex items-center justify-between'>
                <ComboboxInput
                  className={`
                      h-12 w-full border-0 bg-transparent pl-10 font-bold placeholder:font-normal placeholder:text-gray-400 placeholder:text-sm focus:ring-0 disabled:bg-gray-100
                      ${query.length > 0 ? 'pr-8' : 'pr-4'}
                    `}
                  placeholder={`${productsToSearch.length === 0 ? 'Aguarde...' : 'Encontre seu produto'}`}
                  onChange={(event) => {setQuery(event.target.value); setShowList(true)}}
                  onBlur={() => {setShowList(false); onSearching!(false) }}
                  onFocus={() => onSearching!(true)}
                  value={query}
                  disabled={productsToSearch.length === 0}
                />
                {query.length > 0 && <TbX size={22} onClick={() => setQuery('')} className='absolute cursor-pointer p-1 right-2 hover:bg-gray-200 rounded-full' />}
              </div>
            </div>

            {(query && showList) ? (
              <ComboboxOptions static className="absolute shadow-xl w-full rounded-b-lg max-h-[70vh] scroll-py-2 overflow-y-auto text-sm text-gray-800 ring-1 ring-gray-700 ring-opacity-5 divide-y divide-gray-100">
                {productsToSearch.filter((product: any) => {
                  return normalizeStringToSearch(product.name).includes(normalizeStringToSearch(query));
                }).map((product: any) => (
                  <ComboboxOption
                    key={product.id}
                    value={product}
                    className="flex items-center gap-1.5 cursor-default font-medium select-none px-1.5 py-3 data-[focus]:bg-custom-primary data-[focus]:text-white bg-white"
                  >
                    <div className='w-7 overflow-hidden'><Img src={product.photos?.thumbnail} fallback={<div className='text-gray-500 flex items-center justify-center bg-gray-100 w-6 h-6 rounded-full'><TbPhotoX /></div>} alt={product.name} className="w-full rounded-full" /></div>
                    <span className='w-full leading-4'>{product.name}</span>
                  </ComboboxOption>
                ))}
              </ComboboxOptions>
            ) : null}

            {query !== '' && filteredProducts.length === 0 && (
              <p className="absolute left-0 w-full bg-white shadow rounded-b-lg p-4 text-sm text-gray-500">Nenhum produto encontrado</p>
            )}
          </Combobox>
      </div>
  )
}

export default Search;