import React, { useEffect, useRef, useState } from 'react';
import ButtonPrimary from '../Form/ButtonPrimary';
import ButtonClose from './ButtonClose';
import { TbMessage2Question, TbMoodCheck, TbMoodHeart, TbMoodSearch, TbPhone } from 'react-icons/tb';
import { IMask, IMaskInput } from 'react-imask';
import { HiXMark } from 'react-icons/hi2';
import { useLoginContext } from '../../Contexts/LoginContext';
import { ImSpinner8 } from 'react-icons/im';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { useOrderTypeContext } from '../../Contexts/OrderTypeContext';
import CustomerService from '../../Services/CustomerService';
import Button from '../Form/Button';
import CountdownTimer from './CountdownTimer';
import { useCartContext } from '../../Contexts/CartContext';
import { useStoreContext } from '../../Contexts/StoreContext';
import { isPhoneValid } from '../../Utils/Helpers'
import { useLayoutContext } from '../../Contexts/LayoutContext';
import { useCartHook } from '../../Hooks/CartHook';

interface LoginBasicProps {
  closeable?: boolean;
  onClose: CallableFunction;
}

const LoginBasic = ({closeable, onClose}: LoginBasicProps) => {

  const [phone, setPhone] = useState('')
  const [userRegistered, setUserRegistered] = useState(false)
  const [userToken, setUserToken] = useState('')
  const [invalidToken, setInvalidToken] = useState(false)
  const [userHash, setUserHash] = useState('')
  const [errorPhone, setErrorPhone] = useState('')
  const [firstName, setFirstName] = useState('')
  const [errorFirstName, setErrorFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [errorLastName, setErrorLastName] = useState('')

  const { user, setUser, setToken, setShowBasicLogin, generateToken, setGenerateToken, setExpiratedCode, setShowLoginSuccessful } = useLoginContext();
  const { setMainLoading, setMainLoadingIcon, setMainLoadingIconError, setMainLoadingTitle, setMainLoadingMessage, setMainLoadingCloseButton  } = useLayoutContext();

  const [ loadingButton, setLoadingButton ] = useState(false)
  const [ loadingResendCode, setLoadingResendCode ] = useState(false)
  const [ codeSent, setCodeSent ] = useState(false)
  const [ enableResendCode, setEnableResendCode ] = useState(false)
  const [ timeResendCode, setTimeResendCode ] = useState('')

  const { orderType, getOrderTypeIdByName } = useOrderTypeContext();
  const { table, setTable, currentStore, setShowTableChoice } = useStoreContext();
  const { setCartDisplay, setCartFinished } = useCartContext();
  const { createCart } = useCartHook();

  const swiperRef = useRef<SwiperRef>(null);
  const [ step, setStep ] = useState(1);
  const [ icon, setIcon ] = useState<any>();
  const [ title, setTitle ] = useState('')
  const [ subtitle, setSubtitle ] = useState('')
  const [ message, setMessage ] = useState('')

  const formatPhoneNumber = (phoneNumber: string): string => {
    const phoneMask = IMask.createMask({
      mask: '(00) 00000-0000',
    });
    phoneMask.resolve(phoneNumber);
    return phoneMask.value;
  };

  const handleComplete = () => {
    setEnableResendCode(true)
  };

  useEffect(() => {
    //* Montando configurações para pular etapas
    //* quando o cliente clicar no "Prefiro continuar depois"
    if (user) {
      setPhone(user.mobile_phone);
      setUserRegistered(true);
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setStep(3);

      setCodeSent(Boolean(generateToken))
      if (generateToken) {
        setTimeResendCode(generateToken.genereted)
        setUserHash(generateToken.hash)
      }
    }
  }, [user, generateToken])

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(step - 1);
    }
    switch (step) {
      case 2:
        setTitle(userRegistered ? 'Encontramos você!' : 'Que legal! Novo por aqui...')
        setSubtitle(userRegistered ? 'Confirme seu nome e sobrenome' : 'Nos diga seu nome e sobrenome')
        setMessage('')
        setIcon(userRegistered ? <TbMoodCheck size={50} className='zoomInIconNoDelay' /> : <TbMoodHeart size={50} className='zoomInIconNoDelay' />)
        break;
      case 3:
        setTitle(`Falta pouco, ${firstName}!`)
        setSubtitle(`Enviamos um código para ${formatPhoneNumber(phone)}`)
        setMessage('Digite abaixo e abra sua comanda')
        setIcon(<TbMessage2Question size={50} className='zoomInIconNoDelay' />)
        break;
      default:
        setTitle('Vamos abrir sua comanda')
        setSubtitle('Precisamos identificar você')
        setMessage('Digite seu telefone abaixo')
        setIcon(<TbMoodSearch size={50} className='zoomInIcon' />)
        break;
    }
  }, [ phone, step, firstName, userRegistered ])

  useEffect(() => {
    if (phone && phone.length && phone.length < 11) {
      setErrorPhone('Telefone inválido');
    } else {
      setErrorPhone('')
    }
    if (firstName && firstName.length && firstName.length < 2) {
      setErrorFirstName('Seu nome deve ter pelo menos 2 caracteres');
    } else {
      setErrorFirstName('')
    }
    if (lastName && lastName.length && lastName.length < 2) {
      setErrorLastName('Seu sobrenome deve ter pelo menos 2 caracteres');
    } else {
      setErrorLastName('')
    }
  }, [phone, firstName, lastName])

  const isCodeValid = () => {
    return userToken.length === 6
  }

  const isContinueValid = () => {
    return Boolean(firstName && firstName.length && firstName.length >= 2 && lastName && lastName.length && lastName.length >= 2)
  }

  const handleResendCode = () => {
    setUserToken('')
    setLoadingResendCode(true)
    handleSendCode(phone);
  }

  const handleSearchPhone = async () => {
    try {
      setLoadingButton(true);
      const customerService = new CustomerService(orderType);
      const customer = await customerService.searchByPhone(phone);

      if (customer) {
        setUserRegistered(true);
        setFirstName(customer.first_name);
        setLastName(customer.last_name);
        setUser(customer)
        setStep(3);

        setLoadingButton(true);
        handleSendCode(customer.mobile_phone);
      }

    } catch (error) {
        setStep(2);
        console.error('Error searchByPhone:', error);
    } finally {
        setLoadingButton(false);
    }
  }

  const handleValidateToken = async () => {
    const payload = {
      user_token: userToken,
      user_hash: userHash,
      phone_number: phone
    }
    try {
      setLoadingButton(true);
      const customerService = new CustomerService(orderType);
      const validToken = await customerService.validateToken(payload);

      if (validToken.validated) {
        setToken(validToken.token);
        setGenerateToken(null);
        getCustomerCart(validToken.token);
        onClose();
      } else {
        setInvalidToken(true)
      }

    } catch (error) {
        console.error('Error validateToken:', error);
        setInvalidToken(true)
    } finally {
        setLoadingButton(false);
    }
  }

  const getCustomerCart = async (token: string) => {
    try {
        const customerService = new CustomerService(orderType, token);
        const payload = {
          store_id: currentStore.id,
          order_type: Number(getOrderTypeIdByName(orderType)),
        }
        const getCart = await customerService.getCartOpen(payload);

        if (getCart && Object.keys(getCart).length > 0) {
          setCartDisplay({
            id: getCart.id, 
            table: {
              id: getCart.table.id,
              name: getCart.table.name,
            }
          })
          setTable('');
          setCartFinished(null)
          setShowLoginSuccessful(true);
        } else {
          if (!table) {
            setShowTableChoice(true);
          } else {
            createCart(token);
          }
        }
      } catch (error) {
          setMainLoading(true);
          setMainLoadingIcon(false);
          setMainLoadingIconError(true);
          setMainLoadingCloseButton(true);
          setMainLoadingTitle('Ops! Algo não deu certo.');
          setMainLoadingMessage('Tente abrir a comanda novamente e, caso persista, chame o garçom.');
          console.error('Error getCartOpen:', error);
      }
  }

  const handleRegiterCustomer = async () => {
    const payload = {
      first_name: firstName,
      last_name: lastName,
      mobile_phone: phone
    }

    try {
      setLoadingButton(true);
      const customerService = new CustomerService(orderType);
      const register = await customerService.register(payload);

      if (register) {
        setUserRegistered(true);
        setFirstName(register.customer.first_name);
        setLastName(register.customer.last_name);
        setUser(register.customer)
        setStep(3);

        setLoadingButton(true);
        handleSendCode(register.customer.mobile_phone);
      }

    } catch (error) {
      console.error('Error register:', error);
    } finally {
      setLoadingButton(false);
    }
  }

  const handleUpdateCustomer = async () => {
    // console.log('updateCustomer')
  }

  const handleSendCode = async (phone: string) => {
    const payload = {number: phone}

    try {
      const customerService = new CustomerService(orderType);
      const token = await customerService.generateToken(payload);

      if (token) {
        setCodeSent(!token.error)
        setUserHash(token.hash)
        setGenerateToken(token)
        setEnableResendCode(false)
        setTimeResendCode(token.genereted)
        setExpiratedCode(false)
      } else {
        setInvalidToken(true)
      }

    } catch (error) {
      console.error('Error generateToken:', error);
    } finally {
      setLoadingResendCode(false);
      setLoadingButton(false);
    }
  }

  const handleLater = () => {
    setShowBasicLogin(false)
    onClose()
  }

  const handleChangeNumber = () => {
    setUser(null);
    setGenerateToken(null);
    setUserToken('');
    setPhone('');
    setStep(1);
  }

  useEffect(() => {
    if (userToken.length === 6) {
      handleValidateToken();
      setInvalidToken(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken])

  return (
    <div className="pt-4 flex gap-3 flex-col">

        {closeable && <ButtonClose onClick={onClose} />}

        <div className='py-1 grid gap-2'>
          <h2 className='flex flex-col gap-3 items-center text-gray-500 px-4'>
            { icon }
            <span className='text-xl font-extrabold text-custom-primary leading-5'>{ title }</span>
          </h2>
          <div className='flex gap-0.5 px-2 flex-col items-center justify-center leading-5'>
            <span className='font-medium'>{subtitle}</span>
            <span className='hidden text-gray-500 text-sm'>{message}</span>
          </div>
        </div>

        <div className='flex flex-col p-2 md:p-4 bg-gray-50'>

          <Swiper
            ref={swiperRef}
            spaceBetween={0}
            slidesPerView={1}
            centeredSlides={true}
            keyboard={{enabled: false}}
            className='grid w-full'
            pagination={false}
            navigation={false}
            simulateTouch={false}
            >

            <SwiperSlide className='p-2 h-full'>
              {/* PHONE */}
              <div className="grid gap-2">
                <label htmlFor="phone" className="block text-xs text-left text-gray-500 self-start">
                  Seu telefone
                </label>
                <div className="relative flex items-center rounded-md shadow-sm">
                  <TbPhone size={20} className='absolute z-10 left-2' />
                  <IMaskInput
                    mask="(00) 00000-0000"
                    inputMode="numeric"
                    id="phone"
                    radix=""
                    value={phone}
                    onAccept={(value) => setPhone(value)}
                    disabled={loadingButton}
                    unmask={true} // true|false|'typed'
                    placeholder='(__) _____.____'
                    className='relative block w-full autofill:bg-yellow-600 rounded border-0 py-2 pl-8 pr-10 ring-1 ring-gray-400 placeholder:text-gray-300 placeholder:font-normal font-bold text-xl focus:ring-2 focus:ring-gray-500 transition-all ease-in-out duration-200 disabled:bg-gray-200'
                  />
                    {(isPhoneValid(phone) && !loadingButton) && 
                      <button onClick={() => setPhone('')} className='absolute flex items-center justify-center right-2 text-gray-400 rounded-full p-1 hover:bg-gray-100 focus-visible:outline transition-width ease-in-out duration-200'>
                        <HiXMark aria-hidden="true" size={20} />
                      </button>
                    }
                   {errorPhone && <div className='absolute right-2 text-xs text-left text-shopfood-error'>{errorPhone}</div>}
                </div>
                {step === 1 &&
                  <ButtonPrimary disabled={!isPhoneValid(phone) || loadingButton} onClick={handleSearchPhone} className='w-full h-10 my-1 justify-center items-center'>
                    {!loadingButton ? 'Verificar número' : <span className='flex items-center justify-center'><ImSpinner8 className="animate-spin" /></span> }
                  </ButtonPrimary>
                }
              </div>
            </SwiperSlide>

            <SwiperSlide className='p-2 h-full'>
              {/* NAME */}
              {(step === 2) && 
                <div className='grid gap-1'>
                  <div className='flex w-full gap-2'>
                    <div className="flex flex-col gap-1">
                      <label htmlFor="first_name" className="block text-xs text-left text-gray-500 self-start">
                        Nome
                      </label>
                      <div className="relative flex items-center rounded-md  shadow-sm">
                        <IMaskInput
                          id="first_name"
                          mask={/^[a-zA-ZÀ-ÿ\s]*$/}
                          radix=""
                          value={firstName}
                          onAccept={(value) => setFirstName(value)}
                          unmask={true} // true|false|'typed'
                          placeholder=''
                          className='relative text-sm block w-full rounded py-2 px-3 ring-1 ring-gray-400 placeholder:text-gray-500 placeholder:font-normal placeholder:text-xs font-bold focus:ring-2 focus:ring-gray-500 transition-all ease-in-out duration-200 disabled:bg-gray-200'
                        />
                      </div>
                      {errorFirstName && 
                        <div className='text-xs text-left text-shopfood-error'>{errorFirstName}</div>
                      }
                    </div>

                    <div className="flex flex-col gap-1">
                      <label htmlFor="last_name" className="block text-xs text-left text-gray-500 self-start">
                        Sobrenome
                      </label>
                      <div className="relative flex items-center rounded-md shadow-sm">
                        <IMaskInput
                          id="last_name"
                          mask={/^[a-zA-ZÀ-ÿ\s]*$/}
                          radix=""
                          value={lastName}
                          onAccept={(value) => setLastName(value)}
                          unmask={true} // true|false|'typed'
                          placeholder=''
                          className='relative text-sm block w-full rounded py-2 px-3 ring-1 ring-gray-400 placeholder:text-gray-500 placeholder:font-normal placeholder:text-xs font-bold focus:ring-2 focus:ring-gray-500 transition-all ease-in-out duration-200 disabled:bg-gray-200'
                        />
                      </div>
                      {errorLastName && 
                        <div className='text-xs text-left text-shopfood-error'>{errorLastName}</div>
                      }
                    </div>
                  </div>
                  <div className='flex'>
                    {!userRegistered 
                      ? <ButtonPrimary onClick={handleRegiterCustomer} disabled={!isContinueValid() || loadingButton} className='justify-center items-center w-full mt-2 text-sm h-10'>
                          {!loadingButton ? 'Continuar' : <span className='flex items-center justify-center'><ImSpinner8 className="animate-spin" /></span> }
                        </ButtonPrimary>
                      : <ButtonPrimary onClick={handleUpdateCustomer} disabled={!isContinueValid() || loadingButton} className='justify-center items-center w-full mt-2 text-sm h-10'>
                          {!loadingButton ? 'Atualizar cadastro' : <span className='flex items-center justify-center'><ImSpinner8 className="animate-spin" /></span> }
                        </ButtonPrimary>
                    }
                  </div>
                </div>
              }
            </SwiperSlide>
            

            <SwiperSlide className='p-2 h-full'>
              {/* CODE */}
              {step === 3 &&
                <div className="grid gap-2">
                  <label htmlFor="code" className="block text-xs text-left text-gray-500">
                    Código
                  </label>
                  <div className="relative flex items-center rounded-md shadow-sm">
                    <IMaskInput
                      mask="000000"
                      inputMode="numeric"
                      id="code"
                      radix="."
                      value={userToken}
                      onAccept={(value) => setUserToken(value)}
                      unmask={true} // true|false|'typed'
                      placeholder='------'
                      className='relative tracking-[.25em] text-xl block w-full rounded border-0 py-2 pl-3 pr-10 ring-1 ring-gray-400 placeholder:text-gray-300 placeholder:text-xl placeholder:font-normal font-bold focus:ring-2 focus:ring-gray-500 transition-all ease-in-out duration-200 disabled:bg-gray-200'
                    />
                      <ButtonPrimary disabled={userRegistered && (!isCodeValid() || loadingButton)} onClick={handleValidateToken} className='absolute text-xs right-1 h-8 w-20 justify-center items-center'>
                        {!loadingButton ? 'Validar' : <span className='flex items-center justify-center'><ImSpinner8 className="animate-spin" /></span> }
                      </ButtonPrimary>

                      {codeSent && 
                        <Button onClick={handleResendCode} disabled={!enableResendCode || loadingResendCode} className='absolute items-center justify-center right-20 mr-2 h-8 w-20 text-xs text-nowrap disabled:text-gray-800 text-xs bg-white'>
                          { !enableResendCode
                            ? <CountdownTimer  startDate={timeResendCode} durationInMinutes={2} onComplete={handleComplete} />
                            : enableResendCode && !loadingResendCode ? 'Reenviar' : <span className='flex items-center justify-center'><ImSpinner8 className="animate-spin" /></span>
                          }
                        </Button>
                      }

                  </div>

                  {invalidToken && 
                    <div className='text-xs text-left text-shopfood-error'>Token inválido</div>
                  }

                </div>
              }
            </SwiperSlide>
          </Swiper>

          <div className='m-2 flex gap-2 justify-between text-sm text-gray-500'>
            <button onClick={handleLater} className='w-full p-2 leading-3 hover:text-custom-primary cursor-pointer transition-all ease-in-out duration-200'>
              Continuar depois
            </button>

            {user && 
              <button onClick={handleChangeNumber} className='w-full p-1 leading-3 hover:text-custom-primary cursor-pointer transition-all ease-in-out duration-200'>
                Telefone errado
              </button>
            }
          </div>
          

        </div>
    </div>
  )
}

export default LoginBasic;