import { useLayoutContext } from '../Contexts/LayoutContext';
import { useOrderTypeContext } from '../Contexts/OrderTypeContext';
import { useStoreContext } from '../Contexts/StoreContext';
import DOMPurify from 'dompurify';
import { compareEquals, generateOperatingHoursText } from '../Utils/Helpers';
import StoreService from '../Services/StoreService';
import { format } from 'date-fns';

export const useStoreHook = () => {

  const { setMainLoading, setMainLoadingIcon, setMainLoadingIconError, setMainLoadingTitle, setMainLoadingMessage, setMainLoadingCloseButton,setMainLoadingIconNoSearchMap } = useLayoutContext();
  const { currentStore, checkStoreOpen, setCurrentStore, setLoadingStore, setCashier } = useStoreContext();
  const { orderType, getOrderTypeIdByName } = useOrderTypeContext();

  const loadStoreInfo = async (storeId: number) => {
    setLoadingStore(true);
    try {
        const storeSevice = new StoreService(orderType);
        const payload = {store_id: storeId}
        const getStoreInfo = await storeSevice.info(payload);
        if (!compareEquals(getStoreInfo, currentStore)) {
          setCurrentStore(getStoreInfo);
        }
    } catch (error) {
        console.error('Erro storeInfo:', error);
        setCurrentStore(null);
    } finally {
      setLoadingStore(false);
    }
  }

  const storeIsOpen = () => {
    if (!checkStoreOpen(Number(getOrderTypeIdByName(orderType)))) {
      setMainLoading(true);
      setMainLoadingIcon(false);
      setMainLoadingIconError(true);
      setMainLoadingCloseButton(true);
      setMainLoadingTitle('Infelizmente nossa loja está fechada no momento. Confira abaixo nossos horários de funcionamento.');
      const storeWorkdays = currentStore.store_workdays.filter((cw: any) => cw.service_type === Number(getOrderTypeIdByName(orderType)) && cw.active);
      const operatingHoursMessage = DOMPurify.sanitize(generateOperatingHoursText(storeWorkdays));
      setMainLoadingMessage(<div dangerouslySetInnerHTML={{ __html: operatingHoursMessage }}></div>);

      loadStoreInfo(currentStore.id);
      return false;
    }
    return true;
  }

  const noOpenCashier = () => {
      setMainLoading(true);
      setMainLoadingIcon(false);
      setMainLoadingIconError(true);
      setMainLoadingIconNoSearchMap(false);
      setMainLoadingCloseButton(true);
      setMainLoadingTitle('Opa! Infelizmente, nossa loja está fechada no momento.');
      setMainLoadingMessage('');
  }

  const getCashier = async () => {
      const payload = {
          store_id: currentStore.id,
          user: true,
          sort: 'ASC',
          date_from: format(new Date(), 'yyyy-MM-dd'),
          date_to: format(new Date(), 'yyyy-MM-dd')
      }
      try {
          const storeService = new StoreService(orderType);
          const getCashiers = await storeService.cashiers(payload);
          if (getCashiers && Array.isArray(getCashiers) && getCashiers.length > 0) {
              setMainLoading(false);
              setCashier(getCashiers[0]);
              return getCashiers[0];
          } else {
            setCashier(null);
            return null;
          }
        } catch (error) {
          console.error('Error cashiers:', error);
          return null;
        }
  }


  return { loadStoreInfo, storeIsOpen, getCashier, noOpenCashier };
}


