import React, { PropsWithChildren, useEffect, useState } from 'react';

interface BadgeProps {
    type?: string;
    className?: string;
    outline?: boolean;
}

const Badge = ({
    children, 
    type,
    className,
    outline = false
}:  PropsWithChildren<BadgeProps>) => {

    const [badgeClass, setBadgeClass] = useState('');

    useEffect(() => {
        setBadgeClass(`${outline ? 'ring-1 ring-inset ring-gray-300 text-gray-500' : 'text-gray-500 bg-gray-100' }`);
        switch (type) {
            case 'success':
                setBadgeClass(`${outline ? 'ring-1 ring-inset ring-shopfood-success text-shopfood-success' : 'bg-shopfood-success text-white' }`);
                break;
            case 'error':
                setBadgeClass(`${outline ? 'ring-1 ring-inset ring-shopfood-error text-shopfood-error' : 'bg-shopfood-error text-white' }`);
                break;
            case 'info':
                setBadgeClass(`${outline ? 'ring-1 ring-inset ring-sky-500 text-sky-500' : 'bg-sky-500 text-white' }`);
                break;
            case 'alert':
                setBadgeClass(`${outline ? 'ring-1 ring-inset ring-shopfood-alert text-shopfood-alert' : 'bg-shopfood-alert text-white' }`);
                break;
            case 'custom':
                setBadgeClass(`${outline ? 'ring-1 ring-inset ring-custom-primary text-custom-primary' : 'bg-custom-primary text-white' }`);
                break;
            case 'required':
                setBadgeClass(`${outline ? 'ring-1 ring-inset ring-gray-700 text-gray-700' : 'bg-gray-700 text-white' }`);
                break;
        }
    }, [type, outline])

    return (
        <span className={`inline-flex items-center rounded px-1.5 py-0.5 text-[9px] font-bold uppercase ${badgeClass} ${className}`}>
            {children}
        </span>
    );
};

export default Badge;
