import React, { useEffect, useState } from 'react';
import CartValueDetails from './CartValueDetails';
import CardProductCart from './CardProductCart';
import CartEmpty from './CartEmpty';
import Button from '../../Form/Button';
import ButtonPrimary from '../../Form/ButtonPrimary';
import { useOrderTypeContext } from '../../../Contexts/OrderTypeContext';
import { HiXMark } from 'react-icons/hi2';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStoreContext } from '../../../Contexts/StoreContext';
import CartService from '../../../Services/CartService';
import { useLoginContext } from '../../../Contexts/LoginContext';
import { ImSpinner8 } from 'react-icons/im';
import Scrollbars from 'react-custom-scrollbars-2';
import { useCartContext } from '../../../Contexts/CartContext';
import { TbShoppingBagPlus } from 'react-icons/tb';
import { CART_STATUS_FINISHED, ERROR_CART_NOT_FOUND } from '../../../Utils/Constants/Cart';
import { ORDER_STATUS_DONE } from '../../../Utils/Constants/Checkout';
import { useCartHook } from '../../../Hooks/CartHook';

interface CartProps {
    onCloseCart: CallableFunction;
}

const CartStoreCounter = ({onCloseCart}: CartProps) => {

    const { orderType, getOrderTypeIdByName } = useOrderTypeContext();
    const { currentStore } = useStoreContext();
    const { token } = useLoginContext();
    const { cartDisplay, setCartDisplay, setShowCart, setCartFinished } = useCartContext();
    const { cartFinished, cartNotFound } = useCartHook();

    const [cart, setCart] = useState<any | null>(null)
    const [loading, setLoading] = useState<Boolean>(true)

    const navigate = useNavigate();
    const location = useLocation();

    const [isCheckout] = useState(location.pathname === '/checkout')

    const handleCheckout = () => {
        onCloseCart(false)
        navigate('/checkout')
    }

    useEffect(() => {
        const showCart = async () => {
            const payload = {
                cart_id: cartDisplay.id,
                store_id: currentStore.id,
                order_type: Number(getOrderTypeIdByName(orderType)),
            }
            try {
                const cartService = new CartService(orderType, token);
                const getCart = await cartService.show(payload);

                if (getCart) {
                    if (getCart.status === CART_STATUS_FINISHED) {
                        if (parseInt(getCart.order?.status) === ORDER_STATUS_DONE) {
                            cartFinished({id: cartDisplay.id, order: getCart.order});
                            setShowCart(false);
                        } else {
                            setCart(getCart);
                        }
                    } else {
                        setCartDisplay({...cartDisplay, table: {id: getCart.table.id, name: getCart.table.name}})
                        setCartFinished(null);
                        setCart(getCart);
                    }
                }
            } catch (error: any) {
                if (error.response.data.error === ERROR_CART_NOT_FOUND) {
                    cartNotFound();
                }
                setCart(null);
            } finally {
                setLoading(false)
            }
        }
        showCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const [showShadow, setShowShadow] = useState(false);

    const handleScrollFrame = (values: any) => {
        const { scrollTop, scrollHeight, clientHeight } = values;
        // Verifica se o scroll está no início ou no fim para controlar a sombra
        if (scrollTop >= 0 && scrollTop + clientHeight < scrollHeight) {
            setShowShadow(true);
        } else {
            setShowShadow(false);
        }
    }

    useEffect(() => {
        //* Exibição da sombra dos itens quando houver scroll
        const boxScroll = document.getElementById('boxScroll');
        const scrollContent = document.getElementById('scrollContent');

        if (boxScroll && scrollContent) {
            const boxScrollHeight = boxScroll.getBoundingClientRect().height;
            const scrollContentHeight = scrollContent.getBoundingClientRect().height;
            setShowShadow(scrollContentHeight > boxScrollHeight)
        }
    }, [cart])

    return (
        <section className='px-4 h-full'>
            {loading 
                ?   <div className="flex items-center justify-center gap-1 h-full text-sm text-gray-500"><ImSpinner8 className="animate-spin" /> <span>Carregando comanda</span></div>
                :
                    !cart || cart.items?.length === 0
                        ?
                            <CartEmpty onCloseCart={() => onCloseCart(false)} />
                        :
                            <section className="flex flex-col justify-between h-full gap-4">
                                <div className='relative flex flex-col gap-3 w-full h-full md:overflow-y-auto'>
                                    <div className='relative h-full min-h-24' id="boxScroll">

                                        <Scrollbars autoHide={true} className='min-h-full' onScrollFrame={handleScrollFrame}>
                                            <div className='flex flex-col divide-y divide-gray-100' id='scrollContent'>
                                                {cart.items?.map((product: any, index: number) => 
                                                    <CardProductCart product={product} onDec={() => {}} onInc={() => {}} onDelete={() => {}} key={index} showButtonsIncDec={false} />
                                                )}
                                            </div>
                                            <div className='text-xs flex items-center gap-4 my-2'>
                                                <hr className='w-full border-gray-100 border-dashed' />
                                                <span className='text-nowrap text-gray-200'>Fim dos produtos</span>
                                                <hr className='w-full border-gray-100 border-dashed' />
                                            </div>
                                        </Scrollbars>
                                        <div className={`absolute hidden md:block bottom-0 w-full h-2 shadow-reverse-md transition ${showShadow ? 'opacity-100' : 'opacity-0'}`}></div>
                                    </div>
                                    <div className={`absolute -bottom-2 md:hidden w-full h-2 shadow-reverse-md transition ${showShadow ? 'opacity-100' : 'opacity-0'}`}></div>
                                </div>

                                <div className="grid gap-4 w-full">
                                    <CartValueDetails cart={cart} />
                                    {!isCheckout &&
                                        <div className='flex gap-2 pb-4'>
                                            <Button onClick={() => onCloseCart(false)} className='justify-center items-center text-sm text-nowrap gap-0.5'>
                                                <TbShoppingBagPlus size={20} />
                                            </Button>
                                            <ButtonPrimary onClick={() => handleCheckout()} className='justify-center items-center w-full text-sm leading-4'>
                                                Fechar conta
                                            </ButtonPrimary>
                                            <Button onClick={() => onCloseCart(false)} className='justify-center items-center text-nowrap text-sm'>
                                                <HiXMark size={20} />
                                            </Button>
                                        </div>
                                    }
                                </div>
                            </section>
            }
        </section>
    );
};

export default CartStoreCounter;
