import { useCartContext } from '../Contexts/CartContext';
import { useCheckoutContext } from '../Contexts/CheckoutContext';
import { useLayoutContext } from '../Contexts/LayoutContext';
import { useLoginContext } from '../Contexts/LoginContext';
import { useOrderTypeContext } from '../Contexts/OrderTypeContext';
import { useStoreContext } from '../Contexts/StoreContext';
import CartService from '../Services/CartService';
import CustomerService from '../Services/CustomerService';
import { formatCartId } from '../Utils/Formatters';

export const useCartHook = () => {

  const { setMainLoading, setMainLoadingIcon, setMainLoadingIconSearch, setMainLoadingIconError, setMainLoadingTitle, setMainLoadingMessage, setMainLoadingCloseButton, setMainLoadingIconNoSearchMap } = useLayoutContext();
  const { cartDisplay, setCartDisplay, setShowCartIsFinished, setCartFinished, setCartConference, setShowCart, setShowCartConference } = useCartContext();
  const { currentStore, table, setTable } = useStoreContext();
  const { orderType, getOrderTypeIdByName } = useOrderTypeContext();
  const { setShowLoginSuccessful, setToken, setUser } = useLoginContext();
  const { setShowPixCode, order, setOrder } = useCheckoutContext();

  const createCart = async (token: string) => {
    setMainLoading(true);

    setMainLoadingTitle('Abrindo comanda');
    setMainLoadingMessage('Aguarde...');

    const payload = {
        store_id: currentStore.id,
        order_type: Number(getOrderTypeIdByName(orderType)),
        store_table_id: table
    }
    try {
      const cartService = new CartService(orderType, token);
      const cartCreated = await cartService.create(payload);
      if (cartCreated) {
        setCartDisplay({
          id: cartCreated.cart.id,
          table: {
            id: table,
            name: cartCreated.cart.table.name,
          }
        })
        setTable('');
        setShowLoginSuccessful(true);
        setCartFinished(null);
        setShowCartIsFinished(false);
        setOrder(null);
        setMainLoading(false);
      }
    } catch (error) {
      setMainLoading(true);
      setMainLoadingIcon(false);
      setMainLoadingIconError(true);
      setMainLoadingCloseButton(true);
      setMainLoadingTitle('Ops! Algo não deu certo.');
      setMainLoadingMessage('Tente abrir a comanda novamente e, caso persista, chame o garçom.');
      console.error('Error create cart:', error);
    } 
  }

  interface CartFinishedProps {id: number, order: any}
  const cartFinished = ({id, order}: CartFinishedProps) => {
    // setTable(cartDisplay.table.id);
    setCartFinished({id, order});
    setShowCartIsFinished(true);
    setOrder(null);
    setCartConference([]);
    setCartDisplay(null);
  }

  const getOpenedCustomerCartDiffStored = async (token: string) => {
    try {
      setMainLoading(true);

      setTimeout(() => {
        setMainLoadingTitle('Verificando sua comanda');
        setMainLoadingMessage('Aguarde...');
      }, 250);

      const customerService = new CustomerService(orderType, token);
      const payload = {
        store_id: currentStore.id,
        order_type: Number(getOrderTypeIdByName(orderType)),
      };

      const getCart = await customerService.getCartOpen(payload);

      // Verifica se a comanda aberta é diferente da armazenada
      if (!cartDisplay || cartDisplay.id !== getCart.id) {
        if (getCart && Object.keys(getCart).length > 0) {
          setCartDisplay({
            id: getCart.id,
            table: {
              id: cartDisplay?.table?.id || table,
              name: getCart.table.name,
            },
          });

          setMainLoadingTitle(`Encontramos sua comanda ${formatCartId(getCart.id)}.`);
          setMainLoadingMessage('Verifique se possui produtos adicionados pelo garçom e continue o seu pedido.');
          setMainLoadingIcon(false);
          setMainLoadingIconSearch(true);
          setMainLoadingCloseButton(true);
          setTable('');
          setCartFinished(null);
        } else {
          setCartDisplay(null);
        }
      }

      setMainLoading(false);
    } catch (error) {
      setMainLoading(false);
      removeLogin();
    }
  }

  const cartNotFound = () => {
    setMainLoadingIcon(false);
    setMainLoadingIconError(true);
    setMainLoading(true);
    setMainLoadingTitle(`Sua comanda ${formatCartId(cartDisplay.id)} foi encerrada.`);
    setMainLoadingMessage('Caso queira abrir uma nova comanda, feche esta janela e clique no botão no rodapé ou chame o garçom.');
    setMainLoadingCloseButton(true);
    setTable(cartDisplay.table.id);
    setCartDisplay(null);
    setShowCart(false);
    setShowCartConference(false);
  }

  const geolocationBlocked = () => {
    setMainLoadingIcon(false);
    setMainLoadingIconNoSearchMap(true);
    setMainLoadingTitle('Geolocalização negada');
    setMainLoadingMessage('Para abrir a comanda, ativa a localização no navegador e atualiza a página.');
    setMainLoadingCloseButton(true);
    setMainLoading(true);
  }

  const removeLogin = () => {
    setMainLoadingIcon(false);
    setMainLoadingIconError(true);
    setMainLoading(true);
    setMainLoadingTitle('Ops! Sua sessão expirou.');
    setMainLoadingMessage('Para continuar, clique no botão para abrir uma nova comanda, informe seu telefone e valide o token novamente.');
    setMainLoadingCloseButton(true);
    if (cartDisplay) {
      setTable(cartDisplay.table.id);
    }
    setCartDisplay(null);
    setToken(null);
    setUser(null);
  }

  const cancelPayment = () => {
    setCartDisplay({...cartDisplay, order})
    setShowPixCode(false);
    setOrder(null);
  }

  const removeOldCart = () => {
    setCartFinished(null);
  }

  return { createCart, cartFinished, getOpenedCustomerCartDiffStored, cartNotFound, cancelPayment, removeLogin, geolocationBlocked, removeOldCart };
}
