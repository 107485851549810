import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../Form/Button';
import { useCartContext } from '../../../Contexts/CartContext';
import { HiXMark } from 'react-icons/hi2';
import { TbShoppingBagPlus } from 'react-icons/tb';
import { METHOD_PAYMENT_TYPE_PIX } from '../../../Utils/Constants/Checkout';

interface ButtonsCheckoutProps {
  disabledFinish: boolean;
  onFinishOrder: CallableFunction;
  onEditMethod: CallableFunction;
  onCancel: CallableFunction;
  showButtonEditMethod: boolean;
  label: string;
  method: any;
}

const ButtonsCheckout = ({
  disabledFinish,
  onFinishOrder,
  onEditMethod,
  showButtonEditMethod = false,
  label,
  method,
  onCancel
}: ButtonsCheckoutProps) => {

  const navigate = useNavigate();
  const { setReturnToCheckout } = useCartContext();

  const handleFinishOrder = () => {
    onFinishOrder()
  }
  
  // const handleEditMethod = () => {
  //   onEditMethod()
  // }

  const handleAddProduct = () => {
    navigate('/')
    setReturnToCheckout(true)
  }

  // const handleCancel = () => {
  //   navigate('/')
  //   setReturnToCheckout(false)
  // }

  return (
    <>
      <Button onClick={handleAddProduct} className='justify-center items-center text-sm text-nowrap gap-0.5'>
        <TbShoppingBagPlus size={20} className='-mt-0.5' />
        <span className='hidden lg:inline-block'>Adicionar produtos</span>
      </Button>

      <Button
        disabled={disabledFinish} onClick={() => handleFinishOrder()} 
          className={`
            ${method?.type === METHOD_PAYMENT_TYPE_PIX ? 'ring-teal-400 bg-teal-400 text-white hover:bg-teal-500 hover:ring-teal-500' : 'ring-bg-custom-primary bg-custom-primary hover:bg-custom-primary-hover text-white'}
            justify-center text-sm items-center w-full xs:max-w-sm md:max-w-xs gap-1 text-nowrap`}
          >
          {label}
      </Button>

      {/* {showButtonEditMethod &&
        <Button onClick={handleEditMethod} className='justify-center items-center text-sm'>
            <TbPencil size={20} />
        </Button>
      } */}

      <Button onClick={() => onCancel()} className='justify-center items-center text-sm'>
          <HiXMark size={20} />
      </Button>
    </>
  )
}

export default ButtonsCheckout;