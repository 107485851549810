import React, { useState, useEffect } from 'react';

interface ImgProps {
  src: string;
  alt: string;
  fallback?: any;
  className?: string;
}

const Img: React.FC<ImgProps> = ({ src, alt, fallback, className }) => {
  const [imageExists, setImageExists] = useState<boolean>(true);

  useEffect(() => {
    const img = new Image();
    img.src = src;

    img.onload = () => setImageExists(true);
    img.onerror = () => setImageExists(false);
  }, [src]);

  return (
    <>
      {imageExists ? (
        <img src={src} alt={alt} className={className} loading='lazy' />
      ) : (
        fallback && fallback
      )}
    </>
  );
};

export default Img;
