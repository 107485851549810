import React from 'react';
import SelectedProduct from './SelectedProduct'
import ProductBadge from './ProductBadge';
import { useAddProductContext } from '../../../Contexts/AddProductContext';
import { TbPhotoX } from 'react-icons/tb';
import { useQuantityProductInCartConference } from '../../../Utils/Helpers';
import ProductPrice from './ProductPrice';

interface CardProductGridProps {
    product: any;
    category: any;
    loading?: boolean;
}

const CardProductGrid = ({
    product,
    category,
    loading,
}: CardProductGridProps) => {

    const { setShowAddProduct, setProduct, setCategory } = useAddProductContext();

    const handleProduct = () => {
        setShowAddProduct(true);
        setProduct(product);
        setCategory(category);
    }

    const quantitySelected = useQuantityProductInCartConference(product.id);

    return (
        <>
            {!loading
                ?
                    <div onClick={handleProduct} className="flex flex-col w-full h-full shadow hover:text-custom-primary cursor-pointer transition ease-in-out duration-200 ring-1 ring-gray-100 active:ring-2 active:ring-custom-primary md:hover:ring-2 md:hover:ring-custom-primary rounded-lg"> 
                        <div 
                            className={`relative flex items-center justify-center bg-cover bg-center bg-no-repeat min-h-28 md:min-h-36 ring-b ring-1 ring-opacity-50 ring-gray-200 bg-gray-50 rounded-t-lg m-[1px]`}
                            style={{backgroundImage: `url('${product.photos?.medium}')`}}
                            >
                            {!product.photos?.thumbnail && <TbPhotoX className='text-gray-100 w-1/2 h-1/2' />}

                            {/* PRODUCT IN CART */}
                            {quantitySelected > 0 && <SelectedProduct quantity={quantitySelected} />}

                            {/* BADGE  */}
                            {product.new && !product.sale && <ProductBadge type='new' />}
                            {product.from_price && <ProductBadge type='sale' />}

                        </div>
                        <div className="flex flex-col justify-between gap-1.5 h-full p-2.5 md:p-4">
                            <h3 className=" font-bold text-sm lg:text-md ">{product.name}</h3>
                            {/* <p className="h-full text-xs md:text-sm text-gray-500">{product.description.length > 50 ? product.description.slice(0, 50) + '...' : product.description }</p> */}
                            <h4 className="font-medium text-sm text-gray-400 leading-4">
                                <ProductPrice product={product} />
                            </h4>
                        </div>
                    </div>
                :
                    <div className="flex gap-4 w-full rounded-lg ring-1 ring-gray-100 animate-pulse">
                        <div className="flex gap-4 w-full h-full rounded-lg ring-1 ring-gray-100 animate-pulse">
                            <div className="flex flex-col justify-between flex-grow">
                                <div className="bg-gray-200 min-h-28 md:min-h-36 rounded-t-lg"></div>
                                <div className="flex flex-col gap-1.5 justify-between h-full w-full p-4">
                                    <div className='flex flex-col gap-1.5'>
                                        <div className="bg-gray-200 w-full h-2 rounded"></div>
                                        <div className="bg-gray-200 w-2/3 h-2 rounded"></div>
                                    </div>
                                    <div className="bg-gray-200 h-2 w-1/3 rounded-lg"></div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    );
};

export default CardProductGrid;
