import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';

interface StoreType {
  loadingStore: any;
  setLoadingStore: (value: any) => void;
  currentStore: any;
  setCurrentStore: (value: any) => void;
  tables: any;
  setTables: (value: any) => void;
  table: any;
  setTable: (value: string) => void;
  showTableChoice: boolean;
  setShowTableChoice: (value: boolean) => void;
  checkStoreOpen: CallableFunction;
  cashier: any;
  setCashier: (value: any) => void;
  loadStoreInfoOnCloseMainLoading: boolean;
  setLoadStoreInfoOnCloseMainLoading: (value: boolean) => void;
}

const StoreContext = createContext<StoreType | undefined>(undefined);

export const StoreProvider = ({ children }: { children: ReactNode }) => {

  const [currentStore, setCurrentStore] = useState(() => {
    const storedCurrentStore = localStorage.getItem('current_store')
    return storedCurrentStore ? JSON.parse(storedCurrentStore) : null
  })

  const [tables, setTables] = useState(() => {
    const storedTables = localStorage.getItem('tables')
    return storedTables ? JSON.parse(storedTables) : null
  })
  
  const [showTableChoice, setShowTableChoice] = useState(false)
  const [loadingStore, setLoadingStore] = useState(true)

  const [table, setTable] = useState(() => {
    const storedTable = localStorage.getItem('table')
    return storedTable ? storedTable : null
  });

  const [cashier, setCashier] = useState(null);
  const [loadStoreInfoOnCloseMainLoading, setLoadStoreInfoOnCloseMainLoading] = useState(false);

  useEffect(() => {
    if (table) {
      localStorage.setItem('table', table);
    } else {
      localStorage.removeItem('table');
    }
  }, [table]);

  useEffect(() => {
    if (currentStore) {
      localStorage.setItem('current_store', JSON.stringify(currentStore));
    } else {
      localStorage.removeItem('current_store');
    }
  }, [currentStore]);

  useEffect(() => {
    if (tables) {
      localStorage.setItem('tables', JSON.stringify(tables));
    } else {
      localStorage.removeItem('tables');
    }
  }, [tables]);

  const checkStoreOpen = (order_type: number) => {

    // Obter o dia da semana atual (0 = Domingo, 1 = Segunda, etc.)
    const currentWeekday = new Date().getDay();
    const services = currentStore.store_workdays.filter((cw: any) => cw.service_type === order_type && cw.active && cw.weekday === currentWeekday);
    let storeOpen = null;

    // Função para verificar se o horário atual está dentro do intervalo
    function isCurrentTimeInRange(service: any) {
      const now = new Date();
      const currentTime = now.toTimeString().split(' ')[0]; // Formato HH:MM:SS
      const { operation_from, operation_to } = service;

      return currentTime >= operation_from && currentTime <= operation_to;
    }

    services.forEach((service: any) => {
      storeOpen = isCurrentTimeInRange(service);
    });
    return storeOpen;
  }

  return (
    <StoreContext.Provider value={{ currentStore, setCurrentStore, tables, setTables, cashier, setCashier, loadingStore, setLoadingStore, table, showTableChoice, setShowTableChoice, setTable, checkStoreOpen, loadStoreInfoOnCloseMainLoading, setLoadStoreInfoOnCloseMainLoading }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStoreContext = () => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
