import React, { useEffect, useState } from 'react';
import { useLoginContext } from '../../Contexts/LoginContext';
import ButtonPrimary from '../Form/ButtonPrimary';
import CountdownTimer from './CountdownTimer';
import Badge from './Badge';
import { useCartContext } from '../../Contexts/CartContext';
import Modal from './Modal';
import Confirm from './Confirm';
import { useStoreHook } from '../../Hooks/StoreHook';
import { useCartHook } from '../../Hooks/CartHook';
import { useAddProductContext } from '../../Contexts/AddProductContext';
import { formatCartId } from '../../Utils/Formatters';
import Button from '../Form/Button';
import { useLayoutContext } from '../../Contexts/LayoutContext';
import { TbArrowBigRightFilled } from 'react-icons/tb';
import { useStoreContext } from '../../Contexts/StoreContext';
import TableChoice from './Product/TableChoice';
import StoreService from '../../Services/StoreService';
import { useOrderTypeContext } from '../../Contexts/OrderTypeContext';
import { getWeekdayInPortuguese } from '../../Utils/Helpers';

interface ButtonOpenStoreCounterProps {
  label?: any;
  forceLabel?: boolean;
  proofPayment?: boolean;
}

const ButtonOpenStoreCounter = ({ label='Abra você mesmo a sua comanda', forceLabel=false, proofPayment=false } : ButtonOpenStoreCounterProps) => {

  const { setShowBasicLogin, generateToken, expiratedCode, setExpiratedCode, user, token, setShowLoginSuccessful, geolocation, geolocationIsBlocked, setGeolocationIsBlocked, setGeolocation } = useLoginContext();
  const [ timeResendCode, setTimeResendCode ] = useState('')
  const [ textCountdown, setTextCountdown ] = useState('')
  const [ textCustomer, setTextCustomer ] = useState('Informe o código e abra a sua comanda')
  const [ showConfirmNewCart, setShowConfirmNewCart ] = useState<boolean>(false)
  const { cartDisplay, cartFinished, showCartIsFinished, setShowCartIsFinished, setShowProofPayment } = useCartContext();
  const { setShowAddProduct } = useAddProductContext();
  const { setMainLoading, setMainLoadingIcon, setMainLoadingTitle, setMainLoadingMessage, setWelcomeScreenRead, setMainLoadingCloseButton, setMainLoadingIconNoSearchMap, setMainLoadingIconError } = useLayoutContext();
  const { storeIsOpen, noOpenCashier } = useStoreHook();
  const { createCart, geolocationBlocked } = useCartHook();
  const { table, showTableChoice, setShowTableChoice, currentStore, cashier } = useStoreContext();
  const { orderType, getOrderTypeIdByName } = useOrderTypeContext();
  const [showGeolocationPermission, setShowGeolocationPermission] = useState(false);
  
  const [messageStoreClosed, setMessageStoreClosed] = useState('');

  useEffect(() => {
    if(generateToken) {
      setTimeResendCode(generateToken.genereted)
    }
  }, [generateToken])
  
  useEffect(() => {
    if(!cashier) {

      if (currentStore) {
        interface WorkdayEntry {
          id: number;
          store_id: number;
          service_type: number;
          weekday: number;
          operation_from: string;
          operation_to: string;
          max_orders: number | null;
          extra_tax_value: number | null;
          active: boolean;
        }

        const findNextActiveWorkday = (weekday: number): WorkdayEntry | undefined => {
          const maxRetries = 7;
          let attempts = 0;

          const recursiveSearch = (currentWeekday: number): WorkdayEntry | undefined => {
            if (attempts >= maxRetries) {
              return undefined; // Caso o limite de tentativas seja atingido, retorna undefined
            }

            attempts++;

            const nextDayOpen = Object.values(currentStore.store_workdays as Record<string, WorkdayEntry>).find(
              (entry) =>
                entry.service_type === Number(getOrderTypeIdByName(orderType)) &&
                entry.weekday === currentWeekday &&
                entry.active
            );

            if (nextDayOpen) {
              return nextDayOpen;
            } else {
              const nextWeekday = (currentWeekday + 1) % 7; //* 0 - 6
              return recursiveSearch(nextWeekday);
            }
          }
          return recursiveSearch(weekday);
        }

        const formatTime = (time: string): string => {
          return time.split(':').slice(0, 2).join(':');
        }

        const todayWeekday = new Date().getDay();
        const dayOpen = findNextActiveWorkday(todayWeekday);
        if (dayOpen) {
          const checkToday = todayWeekday === Number(dayOpen.weekday) ? 'hoje' : getWeekdayInPortuguese(Number(dayOpen.weekday));
          setMessageStoreClosed(`Abrirá ${checkToday} às ${formatTime(dayOpen.operation_from)}h`);
        }

      }

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashier, currentStore]);

  const onComplete = () => {
    setExpiratedCode(true);
    setTextCountdown('Expirou');
    setTextCustomer('Seu código expirou, clique e reenvie');
  }

  const handleCheckOpen = () => {
    if (cartFinished && !showCartIsFinished && !proofPayment) { //* Exibindo comprovante de finalização da comanda
      setShowCartIsFinished(true);
      return;
    }

    // if (!geolocation || geolocationIsBlocked) { //* Verificando permissão de geolocalização
    //   if (!geolocation && !geolocationIsBlocked) {
    //     setShowGeolocationPermission(true);
    //   } else if (geolocationIsBlocked) {
    //     geolocationBlocked();
    //   } else {
    //     handleGeolocation();
    //   }
    // } else {
    //   handleGeolocation();
    // }

    if (!geolocation) { //* Verificando permissão de geolocalização
      if (geolocationIsBlocked) {
          geolocationBlocked();
      } else {
          setShowGeolocationPermission(true);
      }
    } else {
        handleGeolocation();
    }

  }

  const handleOpen = () => {
    setShowProofPayment(false);

    if (!cashier) {
      noOpenCashier();
    } else if (storeIsOpen()) {
      if (token && !cartDisplay) {
        if (cartFinished) {
          handleCreate();
        } else {
          setShowConfirmNewCart(true);
        }
      } else if (!token) {
        setMainLoading(false);
        setShowBasicLogin(true);
        setWelcomeScreenRead(true);
      } else {
        // setShowAddProduct(false);
        setShowConfirmNewCart(false);
        createCart(token);
      }
    }
  }

  //* Verificando proximidade da loja para abertura da comanda
  const checkDistanceToOpen = async (latitude: number, longitude: number) => {
    setShowConfirmNewCart(false);
    try {
        const storeSevice = new StoreService(orderType);
        const payload = {
            store_id: currentStore.id,
            latitude: latitude,
            longitude: longitude,
        }
        const data = await storeSevice.distance(payload);
        if (data.success) {
          handleOpen();
        } else {
          setTimeout(() => {
            setMainLoadingIcon(false);
            setMainLoadingIconNoSearchMap(true);
            setMainLoadingIconError(false);
            setMainLoadingTitle(data.message);
            setMainLoadingMessage('');
            setMainLoadingCloseButton(true);
          }, 1000);
        }
    } catch (error) {
        console.error('Erro loadDistance:', error);
    }
  }

  const handleCreate = () => {
    setShowProofPayment(false);
    setMainLoading(true);
    setMainLoadingTitle('Abrindo sua comanda');
    setMainLoadingMessage('Aguarde...');
    setMainLoadingCloseButton(true);

    setShowAddProduct(false);
    setShowConfirmNewCart(false);
    setShowLoginSuccessful(false);
    if (!table) {
      setShowTableChoice(true);
      setMainLoading(false);
    } else {
      setShowTableChoice(false);
      setShowConfirmNewCart(false);
      setShowLoginSuccessful(false);
      setShowAddProduct(false);
      createCart(token);
    }
  }

  const handleGeolocation = async () => {

    setMainLoading(true);
    setMainLoadingTitle('Verificando sua localização');
    setMainLoadingMessage('');
    setMainLoadingCloseButton(true);

    try {
        await new Promise<GeolocationPosition>((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
            setMainLoadingTitle('Verificando sua permissão');
            startCheckLocation();
          });
    } catch (error: any) {
      if (error.code === 1) { //* PERMISSION_DENIED
          setGeolocationIsBlocked(true);
          geolocationBlocked();
      } else {
        setMainLoadingIcon(false);
        setMainLoadingIconError(true);
        setMainLoadingTitle('Não conseguimos verificar sua permissão. Tente novamente, por gentileza.');
      }
    }
  }

  // // const startCheckLocation = () => {

  // //   if (!navigator.geolocation) {
  // //     setGeolocationIsBlocked(true);
  // //     geolocationBlocked();
  // //     return;
  // //   }

  // //   navigator.geolocation.getCurrentPosition(
  // //     (position) => {
  // //       const { latitude, longitude } = position.coords;
  // //       setGeolocation({ latitude, longitude });
  // //       setMainLoadingTitle('Verificando sua distância para a loja');
  // //       checkDistanceToOpen(latitude, longitude);
  // //       setGeolocationIsBlocked(false);
  // //     },
  // //     (error) => {
  // //       console.error('Erro ao obter a localização:', error);
  // //     },
  // //     { enableHighAccuracy: true, maximumAge: 1000, timeout: 5000 }
  // //   )
  // // }


  const startCheckLocation = () => {
    if (!navigator.geolocation) {
      setGeolocationIsBlocked(true);
      geolocationBlocked();
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setGeolocation({ latitude, longitude });
        setMainLoadingTitle('Verificando sua distância para a loja');
        checkDistanceToOpen(latitude, longitude);
        setGeolocationIsBlocked(false);
      },
      (error) => {
        if (error.code === error.TIMEOUT) {
          console.warn('');
          setMainLoadingIcon(false);
          setMainLoadingIconNoSearchMap(true);
          setMainLoadingIconError(false);
          setMainLoadingTitle('Ops! Não conseguimos te localizar a tempo. Será que o GPS está ligado?');
          setMainLoadingMessage('Não conseguimos obter sua localização. Verifique se o GPS do dispositivo está ativado.');
          setMainLoadingCloseButton(true);
        } else {
          console.error('Erro ao obter a localização:', error);
        }
      },
      { enableHighAccuracy: true, maximumAge: 1000, timeout: 10000 } // Aumente o timeout
    );
}

  return (
    <>

      {!cashier &&
        <ButtonPrimary onClick={() => {}} className='w-full flex-col justify-center items-center pointer-events-none max-h-10 md:max-h-auto sm:max-w-64'>
            <span className='leading-4 text-sm'>Loja fechada no momento</span>
            <span className="leading-4 text-xs font-normal">{ messageStoreClosed }</span>
        </ButtonPrimary>
      }

      {cashier &&
        <>
          {(cartFinished && !showCartIsFinished && !proofPayment)
            ?
            <Button onClick={handleCheckOpen} className={`py-1 w-full justify-center leading-5 bg-shopfood-success hover:bg-shopfood-success hover:ring-2 hover:ring-gray-800 text-gray-800 ${!showCartIsFinished && 'md:max-w-80'}`}>
                <div className='flex items-center gap-1.5 w-full justify-between'>
                  <div className='flex items-center gap-1.5'>
                    <span className='text-lg font-black'>{formatCartId(cartFinished?.id)}</span> 
                    <Badge type='required' className='ring-2 ring-gray-800 bg-gray-800 rounded-full font-extrabold text-sm'>Pago</Badge> 
                  </div>
                  {label && label !== ''
                      ?
                        <div className='leading-4'>
                              <div className='flex flex-col gap-0.5 text-right pr-1'><span className='leading-3'>Veja seu comprovante</span><span className='font-normal text-xs leading-3'>ou abra uma nova comanda</span></div>
                        </div>
                      :
                        label
                  }
                </div>
              </Button>
            :
              <ButtonPrimary onClick={handleCheckOpen} className={`py-1 w-full justify-center leading-5 ${!showCartIsFinished && 'md:max-w-80'}`}>
                {!forceLabel && generateToken && timeResendCode 
                      ?
                        <div className='flex items-center justify-between w-full text-left gap-1'>
                          <div className='grid'>
                            <span className='text-sm font-extrabold leading-4'>Olá, { user.first_name }!</span>
                            <span className='text-xs font-normal opacity-75 leading-3'>{ textCustomer }</span>
                          </div>
                          <div className='flex items-center'>
                            <Badge type="custom" className='bg-black bg-opacity-20 rounded-full text-nowrap px-1 text-sm'>
                              {expiratedCode 
                                ? textCountdown 
                                : <CountdownTimer startDate={timeResendCode} durationInMinutes={2} onComplete={() => onComplete()} />}
                            </Badge>
                          </div>
                        </div>
                      : <div className='flex gap-1.5 items-center'>
                          <div><TbArrowBigRightFilled size={18} className='animate-side' /></div>
                          <span>{label}</span>
                          <div className='rotate-180'><TbArrowBigRightFilled size={18} className='animate-side' /></div>
                        </div>
                  }
              </ButtonPrimary>
          }

          <Modal open={showConfirmNewCart} className="max-w-sm m-4" onClose={() => setShowConfirmNewCart(false)}>
            <Confirm
              title='Deseja abrir uma nova comanda?'
              labelConfirm='Sim, quero abrir'
              onConfirm={() => handleCreate() }
              onCancel={() => {
                setShowConfirmNewCart(false); setMainLoading(false);
              }}
            />
          </Modal>

          <Modal open={showTableChoice} className="max-w-md m-4" onClose={() => setShowTableChoice(false)}>
              <TableChoice onHandleSend={() => handleCreate()} onShowConfirmTable={(e: boolean) => {} } />
          </Modal>

          <Modal open={showGeolocationPermission} className="max-w-md m-4" onClose={() => setShowGeolocationPermission(false)}>
          <Confirm
            onConfirm={() => {
              setShowGeolocationPermission(false);
              handleGeolocation();
            }} 
            onCancel={() => setShowGeolocationPermission(false)} 
            title={`${geolocationIsBlocked ? 'Sua permissão foi negada' : 'Permissão de localização'}`}
            message={`${geolocationIsBlocked 
                        ? 'Para abrir a comanda, ativa a localização no navegador e atualiza a página.' 
                        : 'Para abrir uma comanda é necessário permitir acesso a geolocalização. '}`
                      }
            labelConfirm='Ok, vou aceitar'
            labelCancel='Agora não'
          />
          </Modal>
        </>
        }
    </>
  )
}

export default ButtonOpenStoreCounter;