import React, { useEffect, useState } from 'react';
import { TbClock } from 'react-icons/tb';

interface CountdownTimerProps {
  startDate: string;
  durationInMinutes: number;
  onComplete: () => void;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ startDate, durationInMinutes, onComplete }) => {
  const calculateTimeLeft = () => {
    const startTime = new Date(startDate).getTime();
    const expirationTime = startTime + durationInMinutes * 60 * 1000;
    const currentTime = new Date().getTime();
    const difference = expirationTime - currentTime;

    const totalDuration = durationInMinutes * 60 * 1000;
    const percentageLeft = Math.floor((difference / totalDuration) * 100); // Arredonda para baixo

    let timeLeft = {
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
      percentage: difference > 0 ? percentageLeft : 0, // retorna o percentual restante como inteiro
    };

    return difference > 0 ? timeLeft : { minutes: 0, seconds: 0, percentage: 0 };
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);

      if (newTimeLeft.minutes === 0 && newTimeLeft.seconds === 0) {
        clearInterval(timer);
        onComplete();
      }
    }, 1000);

    return () => clearInterval(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, durationInMinutes, onComplete]);

  const formatTime = (time: number) => {
    return time.toString().padStart(2, '0');
  };

  return (
    <span className='flex items-center gap-0.5'>
      <TbClock />
      {formatTime(timeLeft.minutes)}:{formatTime(timeLeft.seconds)}
    </span>
  );
}

export default CountdownTimer;
