import { ORDER_STATUS_AWAITING_PAYMENT, ORDER_STATUS_CANCELED, ORDER_STATUS_DONE, ORDER_STATUS_EXPEDITED, ORDER_STATUS_EXPIRED, ORDER_STATUS_MOUNTING, ORDER_STATUS_OPEN, ORDER_STATUS_PENDING, ORDER_STATUS_RELEASED_SHIPMENT, ORDER_STATUS_RELEASED_TAKEAWAY, ORDER_STATUS_RETURNED, ORDER_STATUS_SCHEDULED } from "./Checkout";

export const ORDER_TYPE_IDS: { [key: string]: number | null } = {
    'store_counter': 5,
    'default': null,
}

export const getOrderColorByStatusId = (orderStatus: number): string => {
    let typeBadge = '';
    switch (orderStatus) {
        case ORDER_STATUS_CANCELED: typeBadge = 'error'; break;
        case ORDER_STATUS_PENDING: typeBadge = 'default'; break;
        case ORDER_STATUS_OPEN: typeBadge = 'info'; break;
        case ORDER_STATUS_MOUNTING: typeBadge = 'alert'; break;
        case ORDER_STATUS_RELEASED_SHIPMENT: typeBadge = 'alert'; break;
        case ORDER_STATUS_EXPEDITED: typeBadge = 'alert'; break;
        case ORDER_STATUS_RETURNED: typeBadge = 'error'; break;
        case ORDER_STATUS_DONE: typeBadge = 'success'; break;
        case ORDER_STATUS_RELEASED_TAKEAWAY: typeBadge = 'info'; break;
        case ORDER_STATUS_SCHEDULED: typeBadge = 'required'; break;
        case ORDER_STATUS_AWAITING_PAYMENT: typeBadge = 'alert'; break;
        case ORDER_STATUS_EXPIRED: typeBadge = 'required'; break;
        default: break;
    }
    return typeBadge;
}
