import React from 'react';
import { HiXMark } from 'react-icons/hi2';

interface ButtonCloseProps {
  onClick: CallableFunction;
  className?: string;
}

const ButtonClose = ({
  onClick = () => {},
  className

}: ButtonCloseProps) => {

  const handleClick = () => {
    onClick()
  }
  
  return (
    <button
      type="button"
      onClick={handleClick}
      className={`
      ${className}
        absolute right-3 top-3 text-gray-400 rounded-full p-1 bg-white hover:bg-gray-100 focus-visible:outline transition-width ease-in-out duration-200
      `}
    >
      <HiXMark aria-hidden="true" size={20} />
    </button>
  )
}

export default ButtonClose;