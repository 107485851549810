import React, { useState } from 'react';

import Scrollbars from 'react-custom-scrollbars-2';
import { ImSpinner8 } from 'react-icons/im';
import CardProductCart from '../Cart/CardProductCart';
import CartValueDetails from '../Cart/CartValueDetails';
import { formatCurrency } from '../../../Utils/Formatters';
import ButtonPrimary from '../../Form/ButtonPrimary';
import ProofPayment from '../Cart/ProofPayment';
import Drawer from '../Drawer';
import { useLayoutContext } from '../../../Contexts/LayoutContext';
import { ORDER_STATUS_DONE } from '../../../Utils/Constants/Checkout';
import { useCartContext } from '../../../Contexts/CartContext';

interface OrderDetailsProps {
    order: any,
    loading: boolean;
    onClose: CallableFunction;
}
const OrderDetails = ({ order, loading, onClose }: OrderDetailsProps) => {

    const [showShadow, setShowShadow] = useState(false);

    const handleScrollFrame = (values: any) => {
        const { scrollTop, scrollHeight, clientHeight } = values;
        // Verifica se o scroll está no início ou no fim para controlar a sombra
        if (scrollTop >= 0 && scrollTop + clientHeight < scrollHeight) {
            setShowShadow(true);
        } else {
            setShowShadow(false);
        }
    }

    const {showProofPayment, setShowProofPayment} = useCartContext();
    const {isMobile} = useLayoutContext();
    const handleProofPayment = () => {
        setShowProofPayment(true);
    }

    return (
        <>
            <section className='flex flex-col p-4 h-full gap-4 justify-between'>
                <div className='h-full'>
                    {loading
                        ? <div className="flex items-center justify-center gap-1 h-full text-sm text-gray-500"><ImSpinner8 className="animate-spin" /> <span>Carregando comanda</span></div>
                        :
                            order &&
                                <section className="flex flex-col justify-between h-full gap-3">
                                    <div className='relative flex flex-col gap-3 w-full h-full md:overflow-y-auto'>

                                        <div className='relative h-full min-h-24' id="boxScroll">
                                            <Scrollbars autoHide={true} className='min-h-full' onScrollFrame={handleScrollFrame}>
                                                <div className='flex flex-col divide-y divide-gray-100' id='scrollContent'>
                                                    {order.cart.items?.map((product: any, index: number) => 
                                                        <CardProductCart product={product} onDec={() => {}} onInc={() => {}} onDelete={() => {}} key={index} showButtonsIncDec={false} />
                                                    )}
                                                </div>
                                                <div className='text-xs flex items-center gap-4 my-2'>
                                                    <hr className='w-full border-gray-100 border-dashed' />
                                                    <span className='text-nowrap text-gray-200'>Fim dos produtos</span>
                                                    <hr className='w-full border-gray-100 border-dashed' />
                                                </div>
                                            </Scrollbars>
                                            <div className={`absolute hidden md:block bottom-0 w-full h-2 shadow-reverse-md transition ${showShadow ? 'opacity-100' : 'opacity-0'}`}></div>
                                        </div>
                                        <div className={`absolute -bottom-2 md:hidden w-full h-2 shadow-reverse-md transition ${showShadow ? 'opacity-100' : 'opacity-0'}`}></div>
                                    </div>

                                    <div className="grid w-full text-sm">
                                        <span>Forma de pagamento</span>
                                        {order?.payment_methods?.map((method: any, index: number) =>
                                            <div className='flex justify-between text-gray-600' key={index}>
                                                <span className='font-semibold'>{method?.name}</span>
                                                {order?.payment_methods.length > 1 && <span>{ formatCurrency(method.pivot?.amount) }</span>}
                                            </div>
                                        )}
                                    </div>

                                    <div className="grid gap-4 w-full">
                                        <CartValueDetails cart={order.cart} />
                                    </div>

                                    {order.status === ORDER_STATUS_DONE && <ButtonPrimary outline onClick={() => handleProofPayment()} className='text-sm justify-center'>Gerar comprovante</ButtonPrimary>}

                                </section>
                    }
                </div>
                {/* <div>
                    <Button onClick={onClose} className='justify-center w-full text-nowrap text-sm'>Fechar detalhes</Button>
                </div> */}
            </section>

            {order &&
                <Drawer open={showProofPayment}
                    title='Seu comprovante'
                    fromBottom={isMobile} onClose={() => setShowProofPayment(false)}
                    >
                    <ProofPayment 
                        cart_id={order.cart.id}
                        payment_method={order.payment_method}
                        payment_methods={order.payment_methods}
                        order_id={order.id}
                        showButtonOpenNewCart={false}
                        cashier_id={order.cashier_id}
                    />
                </Drawer>
            }
        </>

    )
};

export default OrderDetails;
