import React, { useEffect, useRef, useState } from 'react';
import { TbMinus, TbPlus, TbTrash } from 'react-icons/tb';
import Modal from './Modal';
import DOMPurify from 'dompurify';
import { vibrate } from '../../Utils/Helpers';
import Confirm from './Confirm';

interface ButtonsIncDecProps {
  onInc: CallableFunction;
  onDec: CallableFunction;
  onDelete?: CallableFunction;
  disabled?: boolean;
  initialQuantity?: number;
  quantityMin?: number;
  quantityMax?: number;
  showTrash?: boolean;
  showDeleteOnZero?: boolean;
  showConfirmDelete?: boolean;
  className?: string;
  onClickIncOutside?: CallableFunction;
  product?: any
  disabledInc?: boolean;
  disabledDec?: boolean;
  forcedQuantity?: number | null; //* Utilizado para refletir o resultado em dois componentes
}

const ButtonsIncDec = ({
  onInc = () => {},
  onDec = () => {},
  onDelete = () => {},
  disabled = false,
  initialQuantity,
  quantityMin = 1,
  quantityMax,
  showTrash = false,
  showDeleteOnZero = false,
  showConfirmDelete = true,
  className,
  onClickIncOutside,
  product,
  disabledInc = false,
  disabledDec = false,
  forcedQuantity

}: ButtonsIncDecProps) => {

  const [quantity, setQuantity] = useState(forcedQuantity || initialQuantity || quantityMin)
  const prevQuantityRef = useRef<number | null>(null);
  const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false)
  const confirmMessage = DOMPurify.sanitize(product ? `Deseja remover <strong>${product.name}</strong> do seu pedido?` : 'Deseja remover este item?');

  const prevForcedQuantityRef = useRef<number>();
  
  const handleDec = () => {
    setQuantity(prevQuantity => {
      const newQuantity = Math.max(prevQuantity - 1, quantityMin);
      return newQuantity
    });
    vibrate();
  };

  const handleInc = () => {
    setQuantity(prevQuantity => {
      const newQuantity = quantityMax ? Math.min(prevQuantity + 1, quantityMax) : prevQuantity + 1;
      return newQuantity
    });
    vibrate();
  };

  const handleDelete = () => {
    onDelete();
  }

  const handleConfirmDelete = () => {
    vibrate();
    showConfirmDelete ? setShowModalConfirmDelete(true) : handleDelete()
  }

  useEffect(() => {
    const prevQuantity = prevQuantityRef.current ?? 0;
    prevQuantity < quantity ? onInc(quantity) : onDec(quantity);
    prevQuantityRef.current = quantity;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  useEffect(() => {
    if (prevForcedQuantityRef.current === 1 && !forcedQuantity) {
      setQuantity(0);
    } else if (forcedQuantity) {
      prevForcedQuantityRef.current = forcedQuantity;
      setQuantity(forcedQuantity);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forcedQuantity]);

  // Método chamado pelo slot do produto
  const onIncOutside = () => {
    handleInc();
  };

  // Se `onClickIncOutside` for fornecido, chame-o com `onIncOutside`
  React.useEffect(() => {
    if (onClickIncOutside) {
      onClickIncOutside(onIncOutside);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClickIncOutside]);

  return (

    <>
      <Modal open={showModalConfirmDelete} className="max-w-sm m-4" onClose={() => setShowModalConfirmDelete(false)} closeable={true}>
        <Confirm 
          title="Tem certeza?" 
          message={<div dangerouslySetInnerHTML={{ __html: confirmMessage }} />} 
          labelConfirm="Sim, quero remover"
          onConfirm={() => handleDelete()} 
          onCancel={() => setShowModalConfirmDelete(false)} 
        />
      </Modal>

      <section className={`flex bg-white rounded-full overflow-hidden ring-1 ring-gray-100 ${className}`}>
        {(showDeleteOnZero && quantity === 1) 
          ?
            <button
              type="button"
              onClick={handleConfirmDelete}
              className="
                flex items-center justify-center
                h-full text-shopfood-danger
                transition-color ease-in-out duration-200
                hover:bg-gray-100
                p-2.5
              ">
              <TbTrash size={16} />
            </button>
        :
          (!showTrash || (showTrash && quantity > quantityMin)) && 
            <button
              type="button"
              onClick={handleDec}
              disabled={disabled || quantity === quantityMin || disabledDec}
              className="
                flex items-center justify-center
                h-full font-bold
                text-custom-primary
                transition-color ease-in-out duration-200
                active:bg-gray-100
                active:shadow-inner
                disabled:text-gray-400
                disabled:pointer-events-none
                p-2.5
              ">
              <TbMinus size={16} />
            </button>
        }

        {(!showTrash || (showTrash && quantity > quantityMin)) && 
          <div className='flex flex-grow min-w-7 px-1 justify-center items-center font-bold cursor-default'>
            {quantity}
          </div>
        }

        <button
          type="button"
          onClick={handleInc}
          disabled={disabled || quantity === quantityMax || disabledInc}
          className="
            flex items-center justify-center
            h-full font-bold
            text-custom-primary
            transition-color ease-in-out duration-200
            active:bg-gray-100
            active:shadow-inner
            disabled:text-gray-400
            disabled:pointer-events-none
            p-2.5
          ">
          <TbPlus size={16} />
        </button>
      </section>
    </>
  )
}

export default ButtonsIncDec;