import React from 'react';
import SelectedProduct from './Product/SelectedProduct'
import ProductBadge from './Product/ProductBadge';
import { useAddProductContext } from '../../Contexts/AddProductContext';
import { useQuantityProductInCartConference } from '../../Utils/Helpers';
import ProductPrice from './Product/ProductPrice';
import DOMPurify from 'dompurify';
import { TbPhotoX } from 'react-icons/tb';

interface CardProductFeaturedProps {
    product: any;
    loading?: boolean;
}

  const CardProductFeatured = ({
    product,
    loading,
}: CardProductFeaturedProps) => {

    const { setShowAddProduct, setProduct, setCategory } = useAddProductContext();
    const description = DOMPurify.sanitize(product.description);
    const quantitySelected = useQuantityProductInCartConference(product.id);

    const handleProduct = () => {
        setShowAddProduct(true)
        setProduct(product)
        setCategory(product.categories[0]);
    }

    return (
        <>
            {!loading
                ?
                <div onClick={handleProduct} className="relative flex flex-col w-full h-full rounded-lg hover:text-custom-primary cursor-pointer transition ease-in-out duration-200 ring-1 ring-gray-100 active:ring-4 active:ring-custom-primary md:hover:ring-4 md:hover:ring-custom-primary">

                    {/* PRODUCT IN CART */}
                    {quantitySelected > 0 && <SelectedProduct quantity={quantitySelected} />}

                    <div className="relative bg-cover bg-center bg-no-repeat min-h-48 ring-b ring-1 ring-opacity-50 ring-gray-200 bg-gray-50 rounded-t-lg m-[1px]" style={{backgroundImage: `url(${product.photos?.medium})`}}>
                        {!product.photos?.medium &&
                            <div className='flex items-center justify-center w-full h-full bg-gray-50 rounded-t-lg'>
                                <TbPhotoX size={50} className='text-gray-200' />
                            </div>
                        }

                        {/* BADGE  */}
                        {/* {product.new && !product.sale && <ProductBadge type='new' />} */}
                        {product.from_price && <ProductBadge type='sale' />}

                    </div>
                    <div className="flex flex-col justify-between gap-1 h-full p-4">
                        <h3 className="text-lg font-extrabold">{product.name}</h3>
                        <p className='h-full text-gray-500 leading-5 md:text-sm' dangerouslySetInnerHTML={{ __html: description.length > 110 ? description.slice(0, 110) + '...' : description }} />
                        <h4 className="font-semibold mt-1 justify-right text-gray-500 leading-5">
                            <ProductPrice product={product} />
                        </h4>
                    </div>
                </div>
                :
                <div className="flex gap-4 w-full h-full rounded-lg ring-1 ring-gray-100 animate-pulse">
                    <div className="flex flex-col gap-4 justify-between flex-grow">
                        <div className="bg-gray-200 h-44 rounded-t-lg"></div>
                        <div className="flex flex-col gap-1.5 w-full p-4 pt-2">
                            <div className="bg-gray-200 w-4/5 h-5 mb-1 rounded"></div>
                            <div className="bg-gray-200 w-full h-2 rounded"></div>
                            <div className="bg-gray-200 w-2/3 h-2 rounded"></div>
                            <div className="bg-gray-200 w-1/3 h-2 rounded"></div>
                        </div>
                        <div className="flex gap-2 items-center p-4 pt-0">
                            <div className="bg-gray-200 h-6 w-2/3 rounded-lg"></div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default CardProductFeatured;
