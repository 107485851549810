import React from 'react';
import { useLayoutContext } from '../../Contexts/LayoutContext';
import { TbWifiOff } from 'react-icons/tb';

const Offline = () => {
    const { isOnline } = useLayoutContext();

    return (
        <>
            {!isOnline && <div className="fixed w-screen h-screen bg-white bg-opacity-90 flex flex-col z-100 justify-center items-center leading-5">
                <TbWifiOff size={50} className=" mb-1" />
                <span className="font-bold">Ops! Sem conexão no momento.</span>
                <span className='text-sm'>Assim que sua conexão voltar, continuaremos.</span>
            </div>
            }
        </>
    );
};

export default Offline;
