import React from 'react';
import SelectedProduct from '../Product/SelectedProduct'
import ProductBadge from '../Product/ProductBadge';
import { useAddProductContext } from '../../../Contexts/AddProductContext';

interface CardProductSuggestionProps {
    product: any;
    loading?: boolean;
}

const CardProductSuggestion = ({
    product,
    loading,
}: CardProductSuggestionProps) => {

    const { setShowAddProduct, setProduct } = useAddProductContext();
    const handleProduct = () => {
        setShowAddProduct(true)
        setProduct(product)
    }

    return (
        <>
            {!loading
                ?
                <div onClick={handleProduct} className="relative p-2 h-full rounded-lg overflow-hidden cursor-pointer transition ease-in-out duration-200 ring-1 ring-gray-100 active:ring-2 active:ring-custom-primary md:hover:ring-2 md:hover:ring-custom-primary hover:text-custom-primary bg-white">

                    {/* BADGE  */}
                    {product.new && !product.sale && <ProductBadge type='new' />}
                    {product.sale && <ProductBadge type='sale' />}

                    <div className="flex flex-col gap-2 w-full h-full">
                        {product.img && 
                            <div className="flex items-center justify-center">
                                <div className='relative'>
                                    {/* PRODUCT IN CART */}
                                    {product.selected && <SelectedProduct quantity={product.quantity} className="rounded-lg" />}
                                    <img src={product.img} alt={product.name} className="max-w-10 md:max-w-12 rounded transition-width ease-in-out duration-200" />
                                </div>
                            </div>
                        }
                        <div className="flex flex-grow flex-col justify-between text-center">
                            <h3 className="text-xs font-bold">{product.name}</h3>
                            {/* <p className="h-full text-xs text-gray-500">{product.description.length > 40 ? product.description.slice(0, 40) + '...' : product.description }</p> */}
                            <h4 className="text-xs font-medium mt-1 text-gray-400">{product.amount}</h4>
                        </div>
                    </div>
                </div>
                :
                <div className="flex gap-4 w-full h-28 p-4 rounded-lg animate-pulse bg-gray-200"></div>
            }
        </>
    );
};

export default CardProductSuggestion;
