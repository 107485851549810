import React, { useState } from 'react';
import { HiMiniUserCircle, HiMiniUserGroup } from 'react-icons/hi2';
import { MdOutlineDeliveryDining } from 'react-icons/md';
import { TbBuildingSkyscraper, TbClock, TbHome, TbMapPin } from 'react-icons/tb';
import MenuCategoriesFloat from './MenuCategoriesFloat';
import { useOrderTypeContext } from '../../Contexts/OrderTypeContext';
import { useLoginContext } from '../../Contexts/LoginContext';
import { Link, useLocation } from 'react-router-dom';
import Search from './Search';
import ButtonsViewMode from './ButtonsViewMode';
import { useLayoutContext } from '../../Contexts/LayoutContext';

interface HeaderProps {
    onShowCart: CallableFunction;
    categories: any;
}

const Header = ({
    onShowCart = () => {},
    categories,
}: HeaderProps) => {

    const { setShowLogin } = useLoginContext();
    const { setShowOrderTypeChoice } = useOrderTypeContext();
    const { company } = useLayoutContext();
    const [showSearch, setShowSearch] = useState(false)

    const headerMenu = [
        { name: 'Home', href: '/', icon: <TbHome /> },
        { name: 'Lojas', href: '/lojas', icon: <TbBuildingSkyscraper /> },
        { name: 'Seja um Franqueado', href: '#', icon: <HiMiniUserGroup /> },
        { name: 'Pagamento', href: '/checkout', icon: <HiMiniUserGroup /> },
    ]

    const location = useLocation();
    const linkActived = 'text-custom-primary pointer-events-none'

    return (
        <>
            {/* TOP HEADER */}
            <header className='flex px-2 md:px-4 py-2 justify-center w-full bg-custom-topbar text-custom-topbar-text shadow-inner font-medium'>
                <div className="w-full flex text-[11px] justify-between flex-col md:flex-row">{/*  md:max-w-[1460px] */}
                    <div className="flex gap-2 md:flex-row-reverse">
                        <div className="flex gap-0.5 w-full md:w-auto items-center font-bold">
                            <TbMapPin />
                            <span>Praia de Botafogo, 300 <span className="hidden md:inline">- 22250040</span></span>
                            {/* Clique aqui e informe seu endereço */}
                        </div>
                        <div className="flex gap-2">
                            <button
                                type="button"
                                className="flex gap-0.5 items-center text-custom-primary md:rounded-full md:px-2 md:py-0.5 font-extrabold md:ring-1 hover:ring-2 md:ring-gray-200 hover:ring-custom-primary transition-color ease-in-out duration-200"
                                onClick={() => setShowOrderTypeChoice(true)}
                            >
                                <MdOutlineDeliveryDining size={15} />
                                Delivery
                            </button>
                        </div>
                    </div>

                    <div className="flex divide-x divide-x-0 md:divide-x">
                        <div className="pr-2 flex gap-0.5 w-full md:w-auto items-center">
                            <TbClock />Estimativa de entrega: 
                            <span className="font-bold">50 Min</span>
                        </div>
                        <div className="pl-2 flex items-center gap-0.5 text-nowrap items-center font-bold">
                            <TbBuildingSkyscraper />
                            <span>Bob's Praia de Botafogo</span>
                        </div>
                    </div>

                </div>
            </header>


            {/* HEADER */}
            <header className="flex flex-col w-full justify-between sticky bg-custom-header top-[-1px] z-30 text-custom-header-text">
                <section className='flex p-4 justify-center shadow-sm shadow-black/5'>
                    <div className="flex items-center justify-between w-full md:max-w-[1460px] gap-2 md:gap-6">

                        {/* LOGO */}
                        <Link to={'/'} className={`${location.pathname === '/' && 'pointer-events-none'}`}>
                            <img src={company.vector_image ?? company.image} alt="" className="min-w-20 w-20 sm:w-24 md:28 transition-width ease-in-out duration-200" />
                        </Link>

                        {/* MENU INLINE DESKTOP */}
                        <div className="flex items-center gap-3 text-xs hidden md:flex font-bold leading-4 text-nowrap">
                            {headerMenu.filter(item => item.href !== '/').map((item) => (
                                <div key={item.name}>
                                    <Link to={item.href} 
                                        className={`hover:text-custom-primary ${location.pathname === item.href && linkActived}`}
                                        >
                                        {item.name}
                                    </Link>
                                </div>
                            ))}
                        </div>

                        {/* SEARCH */}
                        <div className="flex flex-grow h-full">
                            <div className="relative flex items-center w-full">
                                <Search open={showSearch} onClose={() => setShowSearch(false)} />
                            </div>
                        </div>

                        <ButtonsViewMode />

                        <div className="hidden md:flex items-center gap-2 md:gap-4 h-full">

                            <div
                                onClick={() => setShowLogin(true) } 
                                className='flex gap-0.5 items-center text-custom-primary hover:text-custom-primary-hover font-bold text-sm cursor-pointer'>
                                <HiMiniUserCircle size={20} />
                                <span>Entrar {/* Logado exibir 'Perfil' */}</span>
                            </div>

                            {/* <button
                                type="button"
                                className={`
                                    flex items-center p-1 md:p-2 h-full gap-1 rounded-lg 
                                    transition-color ease-in-out duration-200
                                    hover:bg-custom-search
                                    ${!cart.empty && 'ring-2 ring-custom-primary'}
                                    `}
                                onClick={() => onShowCart(true)}
                            >
                                { !cart.empty
                                    ? <TbShoppingBagCheck size={28} className="text-custom-primary" />
                                    : <TbShoppingBag size={28} className="text-custom-primary" />
                                }
                                <div className="text-left text-nowrap">
                                    <div className="text-sm font-bold leading-4">R$ 0,00</div>
                                    <div className="text-xs leading-4 text-gray-400">0 itens</div>
                                </div>
                            </button> */}
                        </div>
                    </div>
                </section>

                {/* Float menu category */}
                {categories &&
                    <div className='flex justify-center w-full text-sm bg-custom-header shadow-md shadow-black/5'>
                        <div className="w-full md:max-w-[1460px]">
                            <MenuCategoriesFloat categories={categories} />
                        </div>
                    </div>
                }

            </header>
        </>
    );
};

export default Header;
