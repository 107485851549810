import { useLayoutContext } from '../Contexts/LayoutContext';
import CartService from '../Services/CartService';
import { useOrderTypeContext } from '../Contexts/OrderTypeContext';
import { useLoginContext } from '../Contexts/LoginContext';
import { useCartContext } from '../Contexts/CartContext';
import { useStoreContext } from '../Contexts/StoreContext';

export const useProductsHook = () => {

  const { setCartTemp, cartConference, setCartConference, returnToCheckout } = useCartContext();
  const { setMainLoading, setMainLoadingIcon, setMainLoadingIconSuccess, setMainLoadingIconError, setMainLoadingTitle, setMainLoadingMessage, setMainLoadingCloseButton, setMainLoadingIconErrorAddProduct } = useLayoutContext();
  const { orderType } = useOrderTypeContext();
  const { token } = useLoginContext();
  
  const { setLoadStoreInfoOnCloseMainLoading } = useStoreContext();
  
  const sendProducts = async (cart_id: number, payload: any) => {

    setMainLoading(true);
    setMainLoadingIcon(true);
    setMainLoadingCloseButton(false);
    setMainLoadingTitle('Enviando seu pedido');
    setMainLoadingMessage('Aguarde...');

    //* Removendo produtos com estoque zerado da lista de conferência
    const reemoveItems = (products: any) => {
      products.unavailable_items.notallowed
        .filter((item: any) => item.product?.stock && item.product.stock[0]?.quantity <= 0 && !item.product.stock[0]?.allow_negative_stock)
        .map((item: any) => {
          return setCartConference(cartConference.filter((product: any) => {
            const idToCompare = product.complementable_id ?? product.id;
            return idToCompare !== item.product_id;
          }));
        });
    }

    //* Gerando mensagem de disponibilidade do produto
    const messageUnavailableReemovibleItems = (products: any): string => {
      const { notallowed } = products.unavailable_items;
      const { unavailableItems, removibleItems } = notallowed.reduce(
        (acc: { unavailableItems: string[]; removibleItems: string[] }, item: any) => {
          const { product } = item;
          const quantity = product?.stock?.[0]?.quantity ?? 0;
          const allowNegative = product?.stock?.[0]?.allow_negative_stock ?? true;

          if (quantity > 0) {
            acc.unavailableItems.push(`- ${product.name} (${quantity} ${quantity > 1 ? 'disponíveis' : 'disponível'})`);
          } else if (quantity <= 0 && !allowNegative) {
            acc.removibleItems.push(`- ${product.name}`);
          }
          return acc;
        },
        { unavailableItems: [], removibleItems: [] }
      );

      const unavailableItemsMessage = unavailableItems.length
        ? unavailableItems.join('<br />')
        : '';

      const removibleItemsMessage = removibleItems.length
        ? `
          <div class='font-semibold text-gray-500 mb-2'>
            ${removibleItems.length > 1
              ? 'Os produtos abaixo estão indisponíveis no momento, então removemos do seu pedido.'
              : 'O produto abaixo está indisponível no momento, então removemos do seu pedido.'}
          </div>
          <span>${removibleItems.join('<br />')}</span>`
        : '';

      return [unavailableItemsMessage, removibleItemsMessage].filter(Boolean).join('<br /><br />');
    }

    try {
      const cartService = new CartService(orderType, token);
      const addedProduct = await cartService.addProduct(cart_id, payload);

      if (addedProduct.success) {
          setCartConference([]);
          setCartTemp([]);
          setMainLoadingIcon(false);
          setMainLoadingIconSuccess(true);
          setMainLoadingCloseButton(true);
          setMainLoadingTitle(returnToCheckout 
            ? 'Boooa! Seu pedido foi enviado. Levaremos você de volta para a tela de pagamento.' 
            : 'Boooa! Seu pedido foi enviado. Aguarde alguns instantes, em breve entregaremos na sua mesa.'
          );
          setMainLoadingMessage('Qualquer dúvida é só chamar o garçom.');
          return true;
      } else {
          setMainLoadingIcon(false);
          setMainLoadingIconErrorAddProduct(true);
          setMainLoadingCloseButton(true);
          setMainLoadingTitle(`${addedProduct.unavailable_items?.notallowed.length > 1 ? 'Infelizmente, não foi possível enviar os produtos abaixo:' : 'Infelizmente, não foi possível enviar o produto abaixo:'}`);
          setMainLoadingMessage(messageUnavailableReemovibleItems(addedProduct));
          reemoveItems(addedProduct);
          setLoadStoreInfoOnCloseMainLoading(true);
          return false;
      }

    } catch (error) {
      console.error('Error addProduct:', error);
      setMainLoadingIcon(false);
      setMainLoadingIconError(true);
      setMainLoadingCloseButton(true);
      setMainLoadingIconErrorAddProduct(false);
      setMainLoadingTitle('Ops! Algo não deu certo.');
      setMainLoadingMessage('Tente enviar seu pedido novamente e, caso persista, chame o garçom.');
    }

  };

  return { sendProducts };
};
