import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { ORDER_STATUS_NAME } from '../Utils/Constants/Checkout';

interface CheckoutType {
  order: any;
  setOrder: CallableFunction;
  showPixCode: boolean;
  setShowPixCode: CallableFunction;
  getStatusNameById: (value: number) => void;
}

const CheckoutContext = createContext<CheckoutType | undefined>(undefined);

export const CheckoutProvider = ({ children }: { children: ReactNode }) => {

  const [order, setOrder] = useState(() => {
    const storedOrder = localStorage.getItem('order')
    return storedOrder ? JSON.parse(storedOrder) : null
  })

  const [showPixCode, setShowPixCode] = useState<boolean>(false)

  const getStatusNameById = (orderStatusId: number): string | null => {
    return ORDER_STATUS_NAME[orderStatusId] ?? null;
  }

  useEffect(() => {
    if (order) {
      localStorage.setItem('order', JSON.stringify(order));
    } else {
      localStorage.removeItem('order');
    }
  }, [order]);

  return (
    <CheckoutContext.Provider value={{ order, setOrder, showPixCode, setShowPixCode, getStatusNameById }}>
      {children}
    </CheckoutContext.Provider>
  );
};

export const useCheckoutContext = () => {
  const context = useContext(CheckoutContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
