import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { PRODUCT_TYPE_COMPOSITE, PRODUCT_TYPE_SIMPLE } from '../Utils/Constants/Product';
import { useStoreContext } from './StoreContext';

interface CartType {
  showCart: boolean;
  setShowCart: (value: boolean) => void;
  showCartConference: boolean;
  setShowCartConference: (value: boolean) => void;
  addProductCartTemp: any;
  setAddProductCartTemp: (value: any) => void;
  currentSteps: any;
  setCurrentSteps: (value: []) => void;

  disabledButtonAddProduct: boolean;
  setDisabledButtonAddProduct: (value: boolean) => void;
  
  showProofPayment: boolean;
  setShowProofPayment: (value: boolean) => void;

  cartTemp: any;
  setCartTemp: (value: []) => void;
  cartConference: any;
  setCartConference: (value: []) => void;

  cartDisplay: any;
  setCartDisplay: (value: any) => void;

  addCartTempInConference: CallableFunction;

  updateCurrentSteps: (stepId: number, complementId: number, newQuantity: number, type: string) => void;
  getTotalSelectedComplements: (stepId: number) => void;
  getPriceTotalSelectedComplements: CallableFunction;
  getTotalQuantityProductsInConference: CallableFunction;
  getCalculateTotalPriceFromComplements: CallableFunction;
  requiredStepsValid: CallableFunction;
  sanitizeCurrentSteps: CallableFunction;
  updateProductInConference: (itemConferenceId: number, quantity: number) => void;
  updateObservationProductInConference: (itemConferenceId: number, observation: string) => void;
  deleteProductFromConference: (itemConferenceId: number) => void;

  returnToCheckout: boolean;
  setReturnToCheckout: (value: boolean) => void;
  
  showCartIsFinished: boolean;
  setShowCartIsFinished: (value: boolean) => void;
  cartFinished: any;
  setCartFinished: (value: any) => void;
  showTablesToChoice: boolean;
  setShowTablesToChoice: (value: boolean) => void;

  deleteDataCartToOpenNew: CallableFunction;
}


interface Product {
  id: number;
  itemConferenceId: number;
  quantity: number;
  type: number;
  steps: any;
}

interface CartDisplay {
  id: number;
  table: string;
}

interface CartFinished {
  id: number;
  order?: any;
}

interface Step {
  id: number;
  product: any;
  complements: any;
  ignore_parent_on_cart: boolean;
}

const CartContext = createContext<CartType | undefined>(undefined);

export const CartProvider = ({ children }: { children: ReactNode }) => {
  const [showCart, setShowCart] = useState(false);
  const [showCartConference, setShowCartConference] = useState(false);
  const [returnToCheckout, setReturnToCheckout] = useState(false)
  const [showCartIsFinished, setShowCartIsFinished] = useState(false)
  const [showTablesToChoice, setShowTablesToChoice] = useState(false)

  const [cartDisplay, setCartDisplay] = useState<CartDisplay | null>(() => {
    const storedCartDisplay = localStorage.getItem('cart_display')
    return storedCartDisplay ? JSON.parse(storedCartDisplay) : null
  })

  const [cartFinished, setCartFinished] = useState<CartFinished | null>(() => {
    const storedCartFinished = localStorage.getItem('cart_finished')
    return storedCartFinished ? JSON.parse(storedCartFinished) : null
  })

  const [cartConference, setCartConference] = useState<Product[]>([]);
  const [cartTemp, setCartTemp] = useState<Product[]>([]);
  const [addProductCartTemp, setAddProductCartTemp] = useState<Product | null>(null);
  const [currentSteps, setCurrentSteps] = useState<Step[]>([]);
  const [disabledButtonAddProduct, setDisabledButtonAddProduct] = useState(true);
  const [showProofPayment, setShowProofPayment] = useState(false);
  const {setTable} = useStoreContext();

  useEffect(() => {
    if (addProductCartTemp) {

      setCartTemp(prevProducts => {
        if (!Array.isArray(prevProducts)) {
          return [addProductCartTemp];
        }
        return [...prevProducts, addProductCartTemp];
      });

    }
  }, [addProductCartTemp]);

  useEffect(() => {
    if (showCartIsFinished) {
      if (cartDisplay) {
        let cd: any;
        if (typeof cartDisplay === 'string') {
          cd = JSON.parse(cartDisplay);
        } else {
          cd = cartDisplay;
        }
        setTable(cd.table.id);
        setCartDisplay(null);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCartIsFinished]);

  const addCartTempInConference = () => {
    //* Adiciona todos os produtos de cartTemp ao cartConference
    //* adiciona 'itemConferenceId' no produto adicionado para identificação atualização e remoção. Podem ter dois produtos com o mesmo id na conferência.

    const productToAdd: any[] = [];
    const stepIgnoreParent: any[] = [];
    cartTemp.map(item => {
        if (item.type === PRODUCT_TYPE_SIMPLE) {

          productToAdd.push(item);

        } else if (item.type === PRODUCT_TYPE_COMPOSITE) {

          const stepsWithoutIgnoreParent = sanitizeCurrentSteps();

          stepsWithoutIgnoreParent.map((step: any) => {

            //* Separando os itens no envio: ignore_parent_on_cart === true
            if(step.ignore_parent_on_cart) {
              step.complements.forEach((complement: any) => {
                const complementsToAdd = Array.from({ length: complement.quantity * item.quantity }, () => ({
                  ...complement,
                  quantity: 1,
                  idParentProduct: item.id //* Incluso para fazer parte do cálculo de produtos adicionados
                }));
                productToAdd.push(...complementsToAdd);
              });

            } else {
              stepIgnoreParent.push(step)
            }
            return true;
          })

          //* Adicionando produtos compostos
          if (sanitizeCurrentSteps(false).length > 0) {
            productToAdd.push({...item, steps: sanitizeCurrentSteps(false) });
          }

        return true;

      }

      return true;
    });

    setCartConference(prevCartConference => [
      ...prevCartConference,
      ...productToAdd.map((item, index) => ({
        ...item,
        itemConferenceId: index + Date.now(),
      }))
    ]);

    setCartTemp([]);
    setCurrentSteps([]);
  };


  const sanitizeCurrentSteps = (ignoreParent?: boolean) => {
    return currentSteps
      .map(step => {
        //* Filtra os complements que têm quantity > 0
        const validComplements = step.complements.filter((complement: any) => complement.quantity > 0);

        // Retorna o step com os complements filtrados
        return {
          ...step,
          complements: validComplements
        };
      })
      //* Filtra os steps que possuem complements com quantity > 0 e, se ignoreParent for fornecido, filtra por ignore_parent_on_cart
      .filter(step => 
        step.complements.length > 0 && 
        (ignoreParent === undefined || step.ignore_parent_on_cart === ignoreParent)
      );
  }


  const deleteProductFromConference = (itemConferenceId: number) => {
    setCartConference((prevCartConference) => {
      return prevCartConference.filter((product) => product.itemConferenceId !== itemConferenceId)
    });
  };

  const updateProductInConference = (itemConferenceId: number, quantity: number) => {
    setCartConference((prevCartConference) =>
      prevCartConference.map((product) =>
        product.itemConferenceId === itemConferenceId ? { ...product, quantity } : product
      )
    );
  };

  const updateObservationProductInConference = (itemConferenceId: number, observation: string) => {
    setCartConference((prevCartConference) =>
      prevCartConference.map((product) =>
        product.itemConferenceId === itemConferenceId ? { ...product, observation } : product
      )
    );
  };

  //* quando encerrar uma comanda fará com que um botão de abertura da comanda seja exibido novamente
  const deleteDataCartToOpenNew = () => {
    setCartDisplay(null)
  }

  const updateCurrentSteps = (stepId: number, complementId: number, newQuantity: number, type: string) => {
    setCurrentSteps(prevSteps => {
      return prevSteps.map(step => {
        if (step.id === stepId) {
          let updatedComplements;

          if (type === 'unica') {
            // Se type === 'unica', define todos os complements como 0, exceto o selecionado
            updatedComplements = step.complements.map((complement: any) => {
              if (complement.id === complementId) {
                // Se já estiver selecionado, desmarcar
                return complement.quantity > 0 
                  ? { ...complement, quantity: 0 } // Desmarca se já estiver selecionado
                  : { ...complement, quantity: 1 }; // Marca como 1 se não estiver
              }
              return { ...complement, quantity: 0 }; // Outros complementos são desmarcados
            });
          } else {
            // Caso contrário, atualiza apenas o complemento específico
            updatedComplements = step.complements.map((complement: any) => {
              if (complement.id === complementId) {
                return { ...complement, quantity: newQuantity };
              }
              return complement;
            });
          }

          return { ...step, complements: updatedComplements };
        }
        return step;
      });
    });
  };

  const getPriceTotalSelectedComplements = () => {
    return currentSteps.reduce((totalStepsQuantity, step: any) => {
      // Para cada step, somamos os complements com quantity > 0
      const totalComplementsQuantity = step.complements.reduce((total: number, complement: any) => {
        return complement.quantity > 0 ? total + (complement.price * complement.quantity) : total;
      }, 0);

      return totalStepsQuantity + totalComplementsQuantity;
    }, 0);
  };

  const getTotalSelectedComplements = (stepId: number) => {
    const step = currentSteps.find(step => step.id === stepId);
    if (!step) {
      return 0;
    }
    const totalSelected = step.complements.reduce((total: number, complement: any) => {
      return total + complement.quantity;
    }, 0);
    return totalSelected;
  };

  const getTotalQuantityProductsInConference = () => {
    return cartConference
      .reduce((total: number, product: any) => {
        //* 0 para produto composto para contabilizar somente os complementos.
        let productTotal = product.type === PRODUCT_TYPE_COMPOSITE ? 0 : product.quantity;

        //* Se o produto tiver steps, somar a quantity de todos os complements
        if (product.steps) {
          const stepQuantity = product.steps.reduce((stepTotal: number, step: any) => {
            return stepTotal + (step.complements?.reduce((complementTotal: number, complement: any) => {
              return (complementTotal + complement.quantity) * product.quantity;
            }, 0) || 0);
          }, 0);

          productTotal += stepQuantity;
        }

        return total + productTotal;
      }, 0);
  }


  const getCalculateTotalPriceFromComplements = (complements: any) => {
    return complements.reduce((total: number, complement: any) => {
      const price = parseFloat(complement.price);
      const quantity = complement.quantity || 1;
      return total + (price * quantity);
    }, 0);
  };


  const requiredStepsValid = (): boolean => {
    return currentSteps.every((step: any) => {
      if (step.required) {
        // Soma o quantity de todos os complements
        const totalQuantity = step.complements.reduce((total: number, complement: any) => {
          return total + complement.quantity;
        }, 0);

        return totalQuantity >= step.minimum_quantity;
      }
      // Se o step não for required
      return true;
    });
  };

  useEffect(() => {
    if (cartConference.length === 0) {
      setShowCartConference(false);
    }
  }, [cartConference])

  useEffect(() => {
    if (cartDisplay) {
      localStorage.setItem('cart_display', JSON.stringify(cartDisplay));
    } else {
      localStorage.removeItem('cart_display');
    }
  }, [cartDisplay])

  useEffect(() => {
    if (cartFinished) {
      localStorage.setItem('cart_finished', JSON.stringify(cartFinished));
    } else {
      localStorage.removeItem('cart_finished');
    }
  }, [cartFinished])

  return (
    <CartContext.Provider value={{
        showCart, setShowCart,
        showCartConference, setShowCartConference,
        addProductCartTemp, setAddProductCartTemp,
        currentSteps, setCurrentSteps,
        disabledButtonAddProduct, setDisabledButtonAddProduct,
        showProofPayment, setShowProofPayment,
        cartTemp, setCartTemp,
        cartConference, setCartConference,
        addCartTempInConference, deleteProductFromConference, updateProductInConference, updateObservationProductInConference,
        updateCurrentSteps, requiredStepsValid, sanitizeCurrentSteps,
        getTotalSelectedComplements, getPriceTotalSelectedComplements, getTotalQuantityProductsInConference,
        getCalculateTotalPriceFromComplements,
        cartDisplay, setCartDisplay,
        returnToCheckout, setReturnToCheckout,
        showCartIsFinished, setShowCartIsFinished,
        showTablesToChoice, setShowTablesToChoice,
        deleteDataCartToOpenNew,

        cartFinished,
        setCartFinished,
        }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCartContext = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
