import React, { useEffect, useRef, useState } from 'react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from 'swiper/modules';
import CardProductFeatured from './CardProductFeatured';
import { useLayoutContext } from '../../Contexts/LayoutContext';

interface FeaturedProductsProps {
    list: any;
    loading?: boolean;
}

const FeaturedProducts = ({
    list,
    loading,
}: FeaturedProductsProps) => {

    const swiperRef = useRef<SwiperRef>(null);
    const swiperRefPrincipal = useRef<SwiperRef>(null);
    const { isMobile } = useLayoutContext();
    const [slidesPerView, setSlidesPerView] = useState(isMobile ? 1 : 3);

    const handleChangeSlide = (index: number) => {
        if (swiperRef.current && swiperRef.current.swiper && swiperRefPrincipal.current) {
            swiperRef.current.swiper.slideTo(index);
            swiperRefPrincipal.current.swiper.slideTo(index);
        }
    }

    useEffect(() => {
        const checkSlidesPerView = () => {
            if (window.innerWidth < 640) {
                setSlidesPerView(1)
            } else if (window.innerWidth < 768) {
                setSlidesPerView(2)
            } else if (window.innerWidth < 1024) {
                setSlidesPerView(3)
            } else if (window.innerWidth < 1280) {
                setSlidesPerView(4)
            } else {
                setSlidesPerView(5)
            }
        }

        checkSlidesPerView()
        window.addEventListener('resize', () => {
            checkSlidesPerView()
        });

    }, []);

    return (
        <div id="boxFeaturedProducts" className='grid self-start gap-4'>
            <h2 className={`text-xl font-extrabold text-custom-primary mt-3 ml-2`}>Destaques</h2>  

            <Swiper
                ref={swiperRef}
                spaceBetween={0}
                modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
                slidesPerView={isMobile ? 1.08 : slidesPerView}
                centeredSlides={false}
                keyboard={{enabled: true}}
                className='grid w-full swiperBullets swiperNavigation'
                onSlideChange={(swiper: any) => {
                    handleChangeSlide(swiper.activeIndex)
                }}
                autoplay={{
                    delay: 3500,
                    disableOnInteraction: true,
                }}
                pagination={isMobile && {
                    clickable: true,
                        renderBullet: function (index: number, className: string) {
                        return '<span class="' + className + '"></span>';
                    },
                }}
                navigation={!isMobile}
                >
                {
                    list.map((product: any, index: number) => (
                        <SwiperSlide
                            key={index} data-hash={`slide1${index}`}
                            className={'flex px-1 md:px-2 py-2'}
                            onClick={() => handleChangeSlide(index)}
                            >
                            <CardProductFeatured key={index} product={product} loading={loading} />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    )
}

export default FeaturedProducts;
