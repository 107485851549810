// import React from 'react';
// import { HiLink, HiMiniChevronRight, HiOutlineCurrencyDollar } from 'react-icons/hi2';
// import { AiOutlineFacebook, AiOutlineInstagram, AiOutlineLinkedin, AiOutlineTwitter } from "react-icons/ai";
// import { TbClock } from 'react-icons/tb';
// import { useLayoutContext } from '../../Contexts/LayoutContext';
import { useOrderTypeContext } from '../../Contexts/OrderTypeContext';
// import { useStoreContext } from '../../Contexts/StoreContext';

const FooterLinks = () => {

    // const { company } = useLayoutContext();
    // const { currentStore } = useStoreContext();
    const { orderType } = useOrderTypeContext();

    return (
        <footer className={`p-4 flex justify-center bg-custom-footer text-custom-footer-text text-sm rounded-t-xl md:rounded-none ${orderType === 'store_counter' ? 'pb-20 md:p-6' : 'pb-36 md:p-6'}`}>
            <div className="grid gap-4 w-full md:max-w-[1460px] text-xs">

                {/* CONTENT DYNAMIC FOOTER */}
                {/* <section className='grid gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
                    <div className='gap-1'>
                        <h4 className='flex items-center gap-0.5 text-lg font-bold'><TbClock /> Horários</h4>
                        <div className='grid gap-2 p-2'>
                            <div className='grid'>
                                <span className='font-bold'>Domingo</span>
                                <span>de 12:00 às 22:30</span>
                            </div>
                            <div className='grid'>
                                <span className='font-bold'>Segunda, Terça, Quarta, Quinta</span>
                                <span>de 10:00 às 22:00</span>
                            </div>
                            <div className='grid'>
                                <span className='font-bold'>Sexta, Sábado</span>
                                <span>de 10:00 às 23:30</span>
                            </div>
                        </div>
                    </div>

                    <div>
                        <h4 className='flex items-center gap-0.5 text-lg font-bold'><HiLink /> Links úteis</h4>
                        <div className='grid gap-2 p-2'>
                            <a href="/" target="_blank" rel="noreferrer" className='flex items-center gap-0.5 hover:opacity-80'><HiMiniChevronRight />Politica de Cookies</a>
                            <a href="/" target="_blank" rel="noreferrer" className='flex items-center gap-0.5 hover:opacity-80'><HiMiniChevronRight />Política de privacidade</a>
                            <a href="/" target="_blank" rel="noreferrer" className='flex items-center gap-0.5 hover:opacity-80'><HiMiniChevronRight />Seja um Fornecedor</a>
                            <a href="/" target="_blank" rel="noreferrer" className='flex items-center gap-0.5 hover:opacity-80'><HiMiniChevronRight />Seja um Franqueado</a>
                            <a href="/" target="_blank" rel="noreferrer" className='flex items-center gap-0.5 hover:opacity-80'><HiMiniChevronRight />Termos de Uso</a>
                        </div>
                    </div>

                    <div>
                        <h4 className='flex items-center gap-0.5 text-lg font-bold'><HiOutlineCurrencyDollar />Pagamentos</h4>
                        <div className='grid gap-2 p-2'>Pague online: Apenas crédito</div>
                    </div>

                    <div>
                        <h4 className='flex items-center gap-0.5 text-lg font-bold'>Conecte-se</h4>
                        <div className='flex items-center gap-2 p-2'>
                            <a href="/" target="_blank" rel="noreferrer" className='flex items-center gap-0.5 hover:opacity-80'><AiOutlineInstagram size={25} /></a>
                            <a href="/" target="_blank" rel="noreferrer" className='flex items-center gap-0.5 hover:opacity-80'><AiOutlineFacebook size={25} /></a>
                            <a href="/" target="_blank" rel="noreferrer" className='flex items-center gap-0.5 hover:opacity-80'><AiOutlineLinkedin size={25} /></a>
                            <a href="/" target="_blank" rel="noreferrer" className='flex items-center gap-0.5 hover:opacity-80'><AiOutlineTwitter size={25} /></a>
                        </div>
                    </div>
                </section> */}

                {/* <hr className='border-custom-footer-text opacity-20 block md:hidden' /> */}

                {/* <h4 className='font-bold'>{ currentStore.name }</h4>
                <h4 className='font-bold'>{ company.name }</h4> */}

                <div className='text-xs font-medium md:text-center opacity-50 hover:opacity-100 transition-color ease-in-out duration-200'>
                    <hr className="hidden md:block w-full border-dashed opacity-50 my-4" />
                    Desenvolvido por <a href="http://shopfood.io" target="_blank" rel="noreferrer" className='font-bold'>Shopfood</a>
                </div>
            </div>
        </footer>
    );
};

export default FooterLinks;
