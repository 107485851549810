import React, { useEffect, useRef, useState } from "react";
import FeaturedProducts from "../Components/Layout/FeaturedProducts";
import Products from "../Components/Layout/Product/Products";
import Modal from "../Components/Layout/Modal";
import { useAddProductContext } from "../Contexts/AddProductContext";
import AddProduct from "../Components/Layout/Product/AddProduct";
import DefaultLayout from "../Layouts/DefaultLayout";
import { useCartContext } from "../Contexts/CartContext";
import { useLoginContext } from "../Contexts/LoginContext";
import LoginSuccessful from "../Components/Layout/LoginSuccessful";
import StoreService from "../Services/StoreService";
import { useOrderTypeContext } from "../Contexts/OrderTypeContext";
import { useStoreContext } from "../Contexts/StoreContext";
import { useLayoutContext } from "../Contexts/LayoutContext";
import { isArraysEqual } from "../Utils/Helpers";
import WelcomeStoreCounter from "../Components/Layout/WelcomeStoreCounter";
import { useCheckoutContext } from "../Contexts/CheckoutContext";
import { useNavigate } from 'react-router-dom';
import { PRODUCT_TYPE_COMPOSITE } from "../Utils/Constants/Product";
import { useStoreHook } from "../Hooks/StoreHook";

interface HomeProps {
    bannersList: any;
    loading: boolean;
}
const Home = ({
    bannersList,
    loading,
}: HomeProps) => {

    const { showAddProduct, setShowAddProduct, product, category} = useAddProductContext();
    const { setCartTemp, showCartIsFinished } = useCartContext();
    const { showLoginSuccessful, setShowLoginSuccessful, token } = useLoginContext();
    const { orderType, getOrderTypeIdByName } = useOrderTypeContext();
    const { currentStore, setTables } = useStoreContext();
    const { company, setProductsToSearch, welcomeScreenRead } = useLayoutContext();
    const { order } = useCheckoutContext();

    //* fake list for Skeleton
    const dataFakeForLoading = [
        {id: 1, products: [{id: 1}, {id: 2},{id: 3},{id: 4}, {id: 5}, {id: 6},{id: 7},{id: 8}]},
        {id: 2, products: [{id: 1}, {id: 2},{id: 3},{id: 4}, {id: 5}, {id: 6},{id: 7},{id: 8}]},
        {id: 3, products: [{id: 1}, {id: 2},{id: 3},{id: 4}, {id: 5}, {id: 6},{id: 7},{id: 8}]},
        {id: 4, products: [{id: 1}, {id: 2},{id: 3},{id: 4}, {id: 5}, {id: 6},{id: 7},{id: 8}]}
    ]

    const [ categories, setCategories ] = useState<any>(dataFakeForLoading);
    const [ loadingCategories, setLoadingCategories ] = useState<boolean>(true);
    const [ featuredProducts, setFeaturedProducts ] = useState<any>(dataFakeForLoading);
    const [ loadingFeaturedProducts, setLoadingFeaturedProducts ] = useState<boolean>(true);

    const { getCashier } = useStoreHook();

    const navigate = useNavigate();
    useEffect(() => {
        if (order) {
            navigate('/checkout');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order])

    //! const previousStoreRef = useRef(currentStore);
    const previousTokenRef = useRef(token);
    useEffect(() => {
        if (currentStore) {
            document.title = currentStore.name;

            //! TEST if (previousStoreRef.current !== currentStore) {
                setLoadingCategories(true);
                const loadProducts = async () => {
                    try {
                        const storeSevice = new StoreService(orderType);
                        const payload = {
                            store_id: currentStore.id,
                            order_type: Number(getOrderTypeIdByName(orderType))
                        }
                        const products = await storeSevice.products(payload);
                        setCategories(products);
                    } catch (error) {
                        console.error('Erro ao carregar products:', error);
                    } finally {
                        setLoadingCategories(false)
                    }
                }
                loadProducts();

                setLoadingFeaturedProducts(true);
                const loadFeaturedProducts = async () => {
                    try {
                        const storeSevice = new StoreService(orderType);
                        const payload = {
                            store_id: currentStore.id,
                            order_type: Number(getOrderTypeIdByName(orderType))
                        }
                        const data = await storeSevice.featuredProducts(payload);
                        setFeaturedProducts(data)
                    } catch (error) {
                        console.error('Erro featuredProducts:', error);
                    } finally {
                        setLoadingFeaturedProducts(false)
                    }
                };
                loadFeaturedProducts();
            //! TEST }

            if (token && previousTokenRef.current !== token) {
                const loadTables = async () => {
                    try {
                        const storeSevice = new StoreService(orderType, token);
                        const payload = {
                            store_id: currentStore.id,
                            tables: true
                        }
                        const tables = await storeSevice.tables(payload);
                        setTables(tables)

                    } catch (error) {
                        console.error('Erro ao carregar tables:', error);
                    } 
                }
                loadTables();
            }

            getCashier();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStore, orderType, token]);

    useEffect(() => {
        if (categories.length > 0 && !isArraysEqual(categories, dataFakeForLoading)) {
            const allProducts: any[] = [];
            categories.forEach((category: any) => {
                category.products.forEach((product: any) => {
                    if (!allProducts.some((prod) => prod.id === product.id)) {
                        allProducts.push(product);
                    }
                });
            });
            setProductsToSearch(allProducts);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories])

    return (
        <>
            <DefaultLayout categories={categories}>
                {/* LIST BANNERS */}
                {/* <Banners list={bannersList} loading={loading} /> */}
                {featuredProducts && <FeaturedProducts list={featuredProducts} loading={loadingFeaturedProducts} />}
                {categories && <Products categories={categories} loading={loadingCategories} />}
            </DefaultLayout>


            {/* MODAL ADD PRODUCT */}
            {product && !showCartIsFinished && (
                <Modal open={showAddProduct} className={`w-full rounded-none md:rounded-lg m-0 h-dvh md:h-auto ${product.type === PRODUCT_TYPE_COMPOSITE ? 'md:max-w-3xl lg:max-w-4xl' : 'md:max-w-lg'}`} 
                    onClose={() => {
                        setShowAddProduct(false);
                        setCartTemp([]);
                    }}>
                    <AddProduct product={product} category={category} />
                </Modal>
            )}

            <Modal open={showLoginSuccessful} closeable={false} className="max-w-sm m-4" onClose={() => setShowLoginSuccessful(false)}>
                <LoginSuccessful />
            </Modal>

            <Modal open={Boolean(!welcomeScreenRead && company)} closeable={false} className="flex items-center justify-center min-h-dvh w-full rounded-none" onClose={() => setShowLoginSuccessful(false)}>
                <WelcomeStoreCounter />
            </Modal>

            {/* <div
                aria-live="assertive"
                className="fixed flex px-4 py-6 sm:p-6 z-50 bottom-0 min-w-full"
                >
                <div className="flex w-full flex-col items-center space-y-4">
                <Transition show={true}>
                    <div className="pointer-events-auto w-full rounded-lg shadow-xl bg-gray-700 text-white transition data-[closed]:data-[enter]:translate-y-2 data-[enter]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0">
                        <div className="p-4 flex gap-4 items-center text-sm">
                            <div className="grid gap-1 leading-6">
                                <span className="font-extrabold text-nowrap text-md">Uso de cookies</span>
                                <span className="text-gray-300">Este site está em conformidade com a Lei Geral de Proteção de Dados (LGPD) e utiliza cookies para oferecer uma melhor experiência ao visitante. Ao navegar em nosso site, você concorda com a utilização de cookies e com a nossa</span>                
                            </div>
                            <ButtonPrimary onClick={() => {}} className="text-nowrap text-sm">Concordar e fechar</ButtonPrimary>
                        </div>
                    </div>
                </Transition>
                </div>
            </div> */}
            
        </>
    );
};

export default Home;