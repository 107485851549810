import React from 'react';
import { TbMapPin, TbMapPinExclamation } from 'react-icons/tb';
import Button from '../Form/Button';

interface SlotStoreServiceChoiceProps {
  store: any;
  onHandleClick: CallableFunction;
}

const SlotStoreServiceChoice = ({
  onHandleClick = () => {},
  store,

}: SlotStoreServiceChoiceProps) => {

  const handleClick = (store: any) => {
    onHandleClick(store)
  }

  return (
    <Button className='flex rounded-md gap-3 items-center font-normal text-sm' disabled={!store.open} onClick={() => handleClick(store)}>
        <div>
          {store.open
            ? <TbMapPin size={25} />
            : <TbMapPinExclamation size={25} className='text-shopfood-error' />
          }
        </div>
        <div className='grid text-left w-full'>
          <span className='font-semibold'>{store.name}</span>
          <span className='text-gray-400 text-xs'>{store.address}</span>
          <span className='font-semibold text-xs'>
            {!store.open && <span className='flex items-center gap-0.5 text-shopfood-error'>Fechada</span>}
          </span>
        </div>
        {store.phone &&
          <div className='text-xs'>
            <span className='text-nowrap font-semibold'>{store.phone}</span>
          </div>
        }
    </Button>

  )
}

export default SlotStoreServiceChoice;