import React, { PropsWithChildren } from 'react';
import { vibrate } from '../../Utils/Helpers';

interface ButtonPrimaryProps {
  onClick: CallableFunction;
  disabled?: boolean;
  className?: string;
  outline?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

const ButtonPrimary = ({
  children,
  onClick = () => {},
  disabled,
  className,
  outline = false,
  type = 'button',

}: PropsWithChildren<ButtonPrimaryProps>) => {

  const handleClick = () => {
    onClick();
    vibrate();
  }

  return (
    <button
      type={type}
      onClick={handleClick}
      disabled={disabled}
      className={`
      ${className}
      flex rounded-full px-4 py-2.5 font-semibold 
      ring-2 
      ring-custom-primary 
      ${outline ? 'text-custom-primary text-custom-primary hover:text-custom-primary-hover' : 'text-white bg-custom-primary hover:bg-custom-primary-hover'}
      hover:ring-custom-primary-hover 
      disabled:opacity-50
      disabled:pointer-events-none
      transition-color ease-in-out duration-200
      `}
    >
      {children}
    </button>
  )
}

export default ButtonPrimary;