import React, { useState, useEffect } from 'react';
import Badge from './Badge';

interface ProgressBarProps {
  initialTimestamp: number;
  expirationTimestamp: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({initialTimestamp, expirationTimestamp }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const startTime = initialTimestamp;
    const endTime = expirationTimestamp;
    const totalDuration = endTime - startTime;

    if (totalDuration <= 0) {
      setProgress(100);
      return;
    }

    const interval = setInterval(() => {
      const currentTime = Date.now();
      const timeElapsed = currentTime - startTime;

      if (timeElapsed >= totalDuration) {
        setProgress(100);
        clearInterval(interval);
      } else {
        const progress = Math.round((timeElapsed / totalDuration) * 100);
        setProgress(progress);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [initialTimestamp, expirationTimestamp]);


  return (
    <div className="relative flex items-center justify-between rounded-full bg-gray-100 shadow-inner h-1.5 w-full">
      <div style={{ width: `${progress}%` }} className={`h-full rounded-full transition-all duration-300 ease-in-out ${progress > 60 ? 'bg-shopfood-error' : 'bg-custom-primary'}`} />
      <Badge className='absolute h-4 right-1.5 opacity-90 rounded-full'>{progress}%</Badge>
    </div>
  )
}

export default ProgressBar;
