import React, { useEffect, useState } from 'react';
import { useLoginContext } from '../../Contexts/LoginContext';
import ButtonPrimary from '../Form/ButtonPrimary';
import { TbMoodHappy, TbMoodPuzzled } from "react-icons/tb";
import { formatCartId } from '../../Utils/Formatters';
import { useCartContext } from '../../Contexts/CartContext';
import { useLayoutContext } from '../../Contexts/LayoutContext';
import StepsStoreCounter from './StepsStoreCounter';


const LoginSuccessful = () => {

    const { user, setShowLoginSuccessful } = useLoginContext();
    const { setWelcomeScreenRead } = useLayoutContext();
    const { cartDisplay } = useCartContext();
    const [timeToReadIcon, setTimeToReadIcon] = useState<boolean>(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeToReadIcon(true);
        }, 4000);

        return () => {
            clearTimeout(timer);
        }
    }, []);

    const handleStart = () => {
        setShowLoginSuccessful(false);
        setWelcomeScreenRead(true);
    }

    return (
        <section className='flex flex-col items-center w-full text-sm w-full'>
            <h4 className='p-6 pb-4 flex flex-col items-center'>
                <span className='mb-1 text-gray-500'>
                    {user 
                        ? <TbMoodHappy size={45} />
                        : <>
                            <TbMoodHappy size={45} className={`transition ${!timeToReadIcon && 'opacity-0 w-0 h-0' }`} />
                            <TbMoodPuzzled size={45} className={`transition ${timeToReadIcon && 'opacity-0 w-0 h-0' }`} />
                        </>
                    }
                </span>
                <span className='font-bold text-2xl text-custom-primary'>
                    {user
                        ? <>Boa, {user.first_name}!</>
                        : <>Fique tranquilo, é fácil.</>
                    }
                </span>
                <span className='text-lg leading-5'>
                    {cartDisplay
                        ? <>Sua comanda é a <span className='font-bold'>{ formatCartId(cartDisplay?.id) }</span>.</>
                        : <>Basta seguir os passos abaixo.</>
                    }
                </span>
                <span className='text-sm text-gray-400 mt-1'>Qualquer dúvida, é só chamar o garçom.</span>
            </h4>

            <div className='bg-gray-50 rounded-xl'>
                <StepsStoreCounter />
            </div>

            <div className='flex justify-center p-6'>
                <ButtonPrimary onClick={handleStart} className='justify-center items-center w-full px-6'>
                    {!cartDisplay 
                        ? <>Beleza, entendi!</>
                        : <>Perfeito, vamos começar!</>
                    }
                </ButtonPrimary>
            </div>
        </section>
    );
}

export default LoginSuccessful;
