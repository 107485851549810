import React from 'react';
// import { TbShoppingBag, TbShoppingBagCheck } from 'react-icons/tb';
import ButtonScrollToTop from './ButtonScrollToTop';
import MenuMobile from './MenuMobile';
// import ButtonPrimary from '../Form/ButtonPrimary';
import FooterLinks from './FooterLinks';
import { useStoreContext } from '../../Contexts/StoreContext';

interface FooterProps {
    onShowCart: CallableFunction;
}

const Footer = ({
    onShowCart = () => {},
}: FooterProps) => {

    const { currentStore } = useStoreContext();

    const handleClick = (e: any) => {
        console.log(e)
    }

    return (
        <section className='flex flex-col w-full'>

            <ButtonScrollToTop />

            {currentStore &&
                <>
                    <FooterLinks />

                    {/* CART */}
                    {/* {!cart.empty &&
                        <section className='fixed md:hidden z-30 flex gap-4 items-center justify-between w-full h-14 bottom-16 pl-4 p-3 bg-white text-sm ring-t-1 ring-white/50 rounded-t-xl shadow-reverse-md'>

                            <div className='flex flex-grow items-center gap-1'>
                                { !cart.empty
                                                ? <TbShoppingBagCheck size={30} className='text-custom-primary' />
                                                : <TbShoppingBag size={30} />
                                }
                                <div className="text-left text-nowrap">
                                    <div className="font-bold leading-4">R$ 0,00</div>
                                    <div className="text-xs leading-4 opacity-75">0 itens</div>
                                </div>
                            </div>

                            <ButtonPrimary onClick={() => onShowCart(true)} disabled={cart.empty} className='py-1'>
                                Ver carrinho
                            </ButtonPrimary>
                        </section>
                    } */}

                    {/* MENU MOBILE */}
                    <section className='fixed md:hidden z-30 w-full h-16 bottom-0'>
                        <MenuMobile onHandleClick={handleClick} />
                    </section>
                </>
            }
        </section>
            
    );
};

export default Footer;
