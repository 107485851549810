import React, { useEffect, useState } from 'react';
import { TbClock } from 'react-icons/tb';

interface CountdownExpirationDateProps {
  timestamp: number; // timestamp em milissegundos
  onComplete?: () => void; // função opcional a ser chamada quando o tempo expirar
}

const CountdownExpirationDate: React.FC<CountdownExpirationDateProps> = ({ timestamp, onComplete }) => {
  const [timeLeft, setTimeLeft] = useState<number>(calculateTimeLeft(timestamp));

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft(timestamp);
      setTimeLeft(newTimeLeft);
      if (newTimeLeft <= 0) {
        clearInterval(timer);
        if (onComplete) {
          onComplete(); // Chama a função onComplete ao finalizar
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [timestamp, onComplete]); // Inclui onComplete nas dependências

  function calculateTimeLeft(targetTimestamp: number): number {
    const difference = targetTimestamp - Date.now();
    return Math.max(0, difference);
  }

  const formatTime = (milliseconds: number): string => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  return (
  <span className='flex items-center gap-0.5'>
    <TbClock />
    {timeLeft > 0 ? formatTime(timeLeft) : '00:00'}
  </span>);
};

export default CountdownExpirationDate;
