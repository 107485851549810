import React, { useRef, useState } from 'react';
import CardProduct from './CardProduct';
import { useLayoutContext } from '../../../Contexts/LayoutContext';
import CardProductGrid from './CardProductGrid';

import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { TbArrowBigLeftLines, TbArrowBigRightLines } from 'react-icons/tb';

interface ProductsProps {
    categories: any;
    loading: boolean;
}

const Products = ({
    categories = [],
    loading
}: ProductsProps) => {

    const { viewMode } = useLayoutContext();

    const swiperRefMenu = useRef<SwiperRef>(null);
    const swiperRefProducts = useRef<SwiperRef>(null);
    const [currentSwiperSlide, setCurrentSwiperSlide] = useState(0);

    const handleCurrentSlide = (index: number) => {
        setCurrentSwiperSlide(index);
        if (swiperRefProducts.current && swiperRefProducts.current.swiper && swiperRefMenu.current && swiperRefMenu.current.swiper) {
            swiperRefProducts.current.swiper.slideTo(index);
            swiperRefMenu.current.swiper.slideTo(index);

            //* Scroll to position menu on change slide
            const boxProductsSwiper = document.getElementById('boxProductsSwiper');
            if (boxProductsSwiper) {
                const boxHeaderStoreCounter = document.getElementById('boxHeaderStoreCounter');
                const boxFeaturedProducts = document.getElementById('boxFeaturedProducts');
                const swiperMenuCategories = document.getElementById('swiperMenuCategories');

                let sumSections = 0;
                if (boxHeaderStoreCounter) sumSections += boxHeaderStoreCounter.clientHeight;
                if (boxFeaturedProducts) sumSections += boxFeaturedProducts.clientHeight;
                if (swiperMenuCategories) sumSections += swiperMenuCategories.clientHeight;
                sumSections -= 80; // espaçamentos gaps

                window.scrollTo({
                    top: sumSections,
                    behavior: 'smooth'
                });
            }
        }
    }

    return (
        <>
            {viewMode === 'swiper' &&
                <div className='grid'>
                    <>
                        <section className='overflow-hidden sticky top-20 bg-white z-10 pb-2'>
                            <Swiper
                                ref={swiperRefMenu}
                                spaceBetween={0}
                                centeredSlides={currentSwiperSlide > 0}
                                slideToClickedSlide={true}
                                slidesPerView={'auto'}
                                id='swiperMenuCategories'
                                >
                                {
                                    categories.map((category: any, index: number) => (
                                        <SwiperSlide
                                            key={category.id} data-hash={`slide${category.id}`}
                                            data-active={currentSwiperSlide === index}
                                            onClick={() => handleCurrentSlide(index)} 
                                            className={`
                                                w-auto
                                                cursor-pointer pb-2 p-4 border-b-4 
                                                transition-border ease-in-out duration-200
                                                leading-4
                                                flex items-center h-full justify-center text-nowrap
                                                max-w-fit
                                                min-w-content
                                                ${currentSwiperSlide === index ? 'border-custom-primary text-custom-primary font-bold' : 'border-transparent font-medium'}`}
                                            >
                                            {loading ? <div className="flex w-32 h-4 bg-gray-200 rounded-lg animate-pulse" /> : category.name}
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        </section>

                        <Swiper
                            ref={swiperRefProducts}
                            spaceBetween={0}
                            slideToClickedSlide={true}
                            slidesPerView={'auto'}
                            autoHeight={true}
                            onSlideChange={(e: any) => handleCurrentSlide(e.activeIndex)}
                            id='boxProductsSwiper'
                        >
                            {categories?.map((category: any, index: number) =>
                                <SwiperSlide
                                    key={`${index}_${category.id}`}
                                    data-hash={`slide${category.id}`}
                                    data-active={currentSwiperSlide === index}
                                    className={`w-full cursor-pointer p-1 min-h-lvh`}
                                    >
                                    <section className='grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7 gap-2 md:gap-3 lg:gap-4 xl:gap-5 transition-width ease-in-out duration-200'>
                                        {category.products.map((product: any, index: number) =>
                                            <CardProductGrid product={product} category={category} loading={loading} key={index} />
                                        )}

                                        <section className='col-span-3 sm:col-span-4 md:col-span-5 lg:col-span-6 xl:col-span-7 py-6'>
                                            <div className='flex justify-between'>
                                                {index > 0 && 
                                                    <div className='flex flex-col items-center gap-1 justify-center w-full' onClick={() => handleCurrentSlide(index - 1)}>
                                                        <div className='flex text-sm text-center items-center gap-0.5 leading-4 text-gray-400'>
                                                            <TbArrowBigLeftLines className="animate-drag-left" />
                                                            <span className='font-bold'>{categories[index - 1].name}</span>
                                                        </div>
                                                    </div>
                                                }
                                                {(index < categories.length - 1) && 
                                                    <div className='flex flex-col items-center gap-1 justify-center w-full' onClick={() => handleCurrentSlide(index + 1)}>
                                                         <div className='flex text-sm text-center items-center gap-0.5 leading-4 text-gray-400'>
                                                            <span className='font-bold'>{categories[index + 1].name}</span>
                                                            <TbArrowBigRightLines className="animate-drag-right" />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </section>
                                    </section>
                                    
                                    
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </>
                </div>
            }

            {viewMode !== 'swiper' &&
                <div id='boxProducts' className='p-2 grid gap-12'>
                    {categories?.map((category: any, index: number) =>
                        <div key={index} className='categoryListScroll' id={`_${index}`}>
                            <h2 id={`category_${category.id}`} className={`text-xl font-extrabold mb-6 text-custom-primary`}>{category.name}</h2>

                            {viewMode === 'list' &&
                                <section className='grid md:grid-cols-2 xl:grid-cols-3 gap-3 md:gap-4 lg:gap-6 transition-width ease-in-out duration-200'>
                                    {category.products.map((product: any, index: number) =>
                                        <CardProduct product={product} category={category} loading={loading} key={index} />
                                    )}
                                </section>
                            }

                            {viewMode === 'grid' &&
                                <section className='grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7 gap-2 md:gap-3 lg:gap-4 xl:gap-5 transition-width ease-in-out duration-200'>
                                    {category.products.map((product: any, index: number) =>
                                        <CardProductGrid product={product} category={category} loading={loading} key={index} />
                                    )}
                                </section>
                            }
                        </div>
                    )}
                </div>
            }
        </>
    )
}

export default Products;
