import React, { useState } from 'react';
import { MdOutlineDeliveryDining } from 'react-icons/md';
import { TbBowlChopsticks, TbCalendarMonth, TbMapPinFilled, TbShoppingBag } from 'react-icons/tb';
import SlotStoreServiceChoice from './SlotStoreServiceChoice';
import Button from '../Form/Button';
import { useLoginContext } from '../../Contexts/LoginContext';
import ButtonClose from './ButtonClose';

interface ServicesChoiceProps {
    onClose: CallableFunction;
  }
  
  const ServicesChoice = ({
    onClose = () => {}
  
  }: ServicesChoiceProps) => {

    const { setShowLogin } = useLoginContext();
  
    const services = [
        { name: 'delivery', label: 'Delivery', icon: <MdOutlineDeliveryDining />, active: true },
        { name: 'take_away', label: 'Buscar na loja', icon: <TbShoppingBag />, active: false },
        { name: 'dine_in', label: 'Comer na loja', icon: <TbBowlChopsticks />, active: false },
        { name: 'scheduling', label: 'Encomenda', icon: <TbCalendarMonth />, active: false },
    ];

    const storeAddresses = [
        { name: 'Bob\'s Praia de Botafogo', address: 'Praia Botafogo, 360 - Botafogo - Rio de Janeiro, RJ - CEP: 22250-040', phone: '(11) 4003-6049', open: true},
        { name: 'Bob\'s Largo da Carioca', address: 'Avenida Rio Branco, 156218/220SSL - Centro - Rio De Janeiro, RJ - CEP: 20040-003', phone: '(11) 4003-6049', open: false},
        { name: 'Bob\'s Nova Iguaçu Amaral Peixoto', address: 'Avenida Governador Amaral Peixoto, 386 - Centro - Nova Iguaçu, RJ - CEP: 26210-060', phone: null, open: true},
        { name: 'Bob\'s Rua Garcia D\'ávila', address: 'Rua Garcia Davila, 66 - Ipanema - Rio De Janeiro, RJ - CEP: 22421-010', phone: '(11) 4003-6049', open: true},
    ];

    const [currentService, setCurrentService] = useState('delivery')

    const handleService = (serviceName: string) => {
        setCurrentService(serviceName);
    }
    
    const handleStore = (store: string) => {
        onClose()
    }

    const handleLogin = () => {
        setShowLogin(true)
        onClose()
    }

    return (
        <section className='flex flex-col gap-8 w-full h-full items-center justify-center text-center'>

            <ButtonClose onClick={onClose} />

            <h3 className='text-2xl text-custom-primary font-extrabold mt-5'>Como deseja fazer seu pedido?</h3>

            <div className="flex gap-3 md:gap-4">
                {services.map((service: any, index: number) => (
                    <button
                        key={index}
                        type="button"
                        onClick={() => handleService(service.name)}
                        className={`
                            flex flex-col items-center
                            rounded-lg w-full font-medium
                            text-xs md:text-sm gap-3
                            transition-all ease-in-out duration-200
                            hover:text-custom-primary
                        `}
                        >
                        <span className={`flex items-center justify-center rounded-full w-16 h-16 md:w-20 md:h-20 text-3xl md:text-5xl transition-all ease-in-out duration-100 ring-1 ${service.name === currentService ? 'ring-custom-primary bg-custom-primary text-white pointer-events-none' : 'ring-gray-200'}`}>{service.icon}</span>
                        <span className={`${service.label.trim().split(/\s+/).length === 1 && 'break-all'} ${service.name === currentService && 'text-custom-primary'}`}>{service.label}</span>
                    </button>
                ))}
            </div>

            <div className='grid w-full gap-2 p-4 pt-2'>
                {currentService === 'delivery' &&
                    <>
                        <h3 className='font-bold text-sm'>Onde você quer receber seu pedido?</h3>
                        <div className='bg-gray-100 rounded-lg p-2'>
                            <span className='text-gray-400 text-sm'>
                                Form delivery
                            </span>
                        </div>
                    </>
                }

                {currentService === 'take_away' &&
                    <>
                        <h3 className='font-bold text-sm'>Escolha o restaurante para buscar seu pedido</h3>
                        <div className='bg-gray-100 rounded-lg p-2'>
                            <span className='text-gray-400 text-sm'>
                                Form take_away
                            </span>
                        </div>
                        <div className='grid gap-2 mt-2'>
                            {storeAddresses.map((store: any) => 
                                <SlotStoreServiceChoice store={store} onHandleClick={() => handleStore(store)} />
                            )}
                        </div>
                    </>
                }

                {currentService === 'dine_in' &&
                    <>
                        <h3 className='font-bold text-sm'>Escolha o restaurante onde deseja comer</h3>
                        <div className='bg-gray-100 rounded-lg p-2'>
                            <span className='text-gray-400 text-sm'>
                                Form dine_in
                            </span>
                        </div>
                        <div className='grid gap-2 mt-2'>
                            {storeAddresses.map((store: any) => 
                                <SlotStoreServiceChoice store={store} onHandleClick={() => handleStore(store)} />
                            )}
                        </div>
                    </>
                }

                {currentService === 'scheduling' &&
                    <>
                        <h3 className='grid gap-0.5 text-sm'>
                            <span className='text-gray-400'>Encomende hoje e receba a partir de 16/08/2024</span>
                            <span className='font-bold'>Onde você quer receber sua encomenda?</span>
                        </h3>
                        <div className='bg-gray-100 rounded-lg p-2'>
                            <span className='text-gray-400 text-sm'>
                                Form scheduling
                            </span>
                        </div>
                    </>
                }
            </div>

            {(currentService === 'delivery' || currentService === 'scheduling') &&
                <div className='flex flex-col items-center w-full p-4 gap-3 bg-gray-50'>
                    <div className='flex flex-col'>
                        <span className='font-semibold text-sm'>Já tem um endereço salvo?</span>
                        <span className='text-gray-500 text-xs'>Entre na sua conta para selecionar seu endereço.</span>
                    </div>
                    <div className='flex text-xs gap-2'>
                        <Button onClick={() => {}} className='items-center gap-1 text-custom-primary'><TbMapPinFilled /> Usar minha localização atual</Button>
                        <Button onClick={handleLogin}>Entrar ou cadastrar</Button>
                    </div>
                </div>
            }
        </section>
    );
}

export default ServicesChoice;