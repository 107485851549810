import React, { useEffect, useState } from 'react';
import { useCartContext } from '../../Contexts/CartContext';
import { TbArrowBearRight, TbClockCheck, TbMoodSearch, TbSend, TbShoppingBagPlus } from 'react-icons/tb';

const StepsStoreCounter = () => {

    const { cartDisplay } = useCartContext();
    const [showStep1, setShowStep1] = useState<boolean>(false)
    const [showStep2, setShowStep2] = useState<boolean>(false)
    const [showStep3, setShowStep3] = useState<boolean>(false)
    const [showStep4, setShowStep4] = useState<boolean>(false)

    useEffect(() => {
        const totalSteps = 4;
        let currentStep = 1;
        const interval = setInterval(() => {
            if (currentStep <= totalSteps) {
                if (currentStep === 1) setShowStep1(true);
                if (currentStep === 2) setShowStep2(true);
                if (currentStep === 3) setShowStep3(true);
                if (currentStep === 4) setShowStep4(true);
                currentStep++;
            } else {
                clearInterval(interval);
            }
        }, 500);
        return () => {
            clearInterval(interval);
        }
    }, []);

    return (
        <div className='flex gap-1 text-sm text-gray-500 p-2 py-6 w-full'>
          {!cartDisplay &&
              <div className={`relative w-full max-w-24 flex flex-col gap-2 items-center justify-center w-full transition ${showStep1 ? 'opacity-100' : 'opacity-0' }`}>
                  <TbArrowBearRight size={cartDisplay ? 30 : 20} className='absolute -top-4 -right-2 rotate-90 text-gray-200' />
                  <div><TbMoodSearch size={30} /></div>
                  <span className='h-full leading-4'>Abra sua comanda</span>
              </div>
          }
          <div className={`relative w-full max-w-24 flex flex-col gap-2 items-center justify-center w-full transition ${showStep2 ? 'opacity-100' : 'opacity-0' }`}>
              <TbArrowBearRight size={cartDisplay ? 30 : 20} className='absolute -top-4 -right-2 rotate-90 text-gray-200' />
              <div><TbShoppingBagPlus size={30} /></div>
              <span className='h-full leading-4'>Adicione os produtos</span>
          </div>
          <div className={`relative w-full max-w-24 flex flex-col gap-2 items-center justify-center w-full transition ${showStep3 ? 'opacity-100' : 'opacity-0' }`}>
              <TbArrowBearRight size={cartDisplay ? 30 : 20} className='absolute -top-4 -right-4 rotate-90 text-gray-200' />
              <div><TbSend size={30} /></div>
              <span className='h-full leading-4'>
                  {cartDisplay 
                      ? <>Confira e envie seu pedido</>
                      : <>Envie seu pedido</>
                  }
              </span>
          </div>
          <div className={`w-full max-w-24 flex flex-col gap-2 items-center justify-center w-full transition ${showStep4 ? 'opacity-100' : 'opacity-0' }`}>
              <div><TbClockCheck size={30} /></div>
              <span className='h-full leading-4'>
                  {cartDisplay 
                      ? <>Aguarde seu pedido na mesa</>
                      : <>Aguarde na mesa</>
                  }
              </span>
          </div>
      </div>
    )
}

export default StepsStoreCounter;
