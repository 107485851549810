import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';

interface LoginType {
  showLogin: boolean; 
  setShowLogin: (value: boolean) => void;
  closeableLogin?: boolean;
  setCloseableLogin?: (value: boolean) => void;

  showBasicLogin: boolean;
  setShowBasicLogin: (value: boolean) => void;
  token: string;
  setToken: CallableFunction;

  geolocation: {latitude: number, longitude: number};
  setGeolocation: CallableFunction;
  geolocationIsBlocked: boolean;
  setGeolocationIsBlocked: CallableFunction;
  
  user: any;
  setUser: CallableFunction;

  generateToken: any;
  setGenerateToken: CallableFunction;

  expiratedCode: any;
  setExpiratedCode: CallableFunction;
  
  showLoginSuccessful: any;
  setShowLoginSuccessful: CallableFunction;

}

const LoginContext = createContext<LoginType | undefined>(undefined);

export const LoginProvider = ({ children }: { children: ReactNode }) => {

  const [token, setToken] = useState<string>(() => {
    const storedToken = localStorage.getItem('token')
    return storedToken ? storedToken : ''
  })

  const [generateToken, setGenerateToken] = useState(() => {
    const storedGenerateToken = localStorage.getItem('generated_token')
    return storedGenerateToken ? JSON.parse(storedGenerateToken) : null
  })

  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user')
    return storedUser ? JSON.parse(storedUser) : null
  })

  const [showLogin, setShowLogin] = useState(false);
  const [showBasicLogin, setShowBasicLogin] = useState(false);
  const [closeableLogin, setCloseableLogin] = useState(true);
  const [expiratedCode, setExpiratedCode] = useState(false);
  const [showLoginSuccessful, setShowLoginSuccessful] = useState(false);

  const [geolocationIsBlocked, setGeolocationIsBlocked] = useState(false);
  const [geolocation, setGeolocation] = useState(() => {
    const storedGeolocation = localStorage.getItem('position')
    return storedGeolocation ? JSON.parse(storedGeolocation) : null
  })

  useEffect(() => {
    if (geolocation) {
      localStorage.setItem('geolocation', JSON.stringify(geolocation));
      setGeolocationIsBlocked(false);
    } else {
      localStorage.removeItem('geolocation');
    }
  }, [geolocation]);

  useEffect(() => {
    if (showBasicLogin) {
      setShowLogin(true)
      setCloseableLogin!(false)
    }
  }, [showBasicLogin]);

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }, [user]);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
    }
  }, [token]);

  useEffect(() => {
    if (generateToken) {
      localStorage.setItem('generated_token', JSON.stringify(generateToken));
    } else {
      localStorage.removeItem('generated_token');
    }
  }, [generateToken]);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
    }
  }, [token]);


  return (
    <LoginContext.Provider value={{ geolocation, setGeolocation, geolocationIsBlocked, setGeolocationIsBlocked, showLogin, setShowLogin, closeableLogin, setCloseableLogin, showBasicLogin, setShowBasicLogin, token, setToken, user, setUser, generateToken, setGenerateToken, expiratedCode, setExpiratedCode, showLoginSuccessful, setShowLoginSuccessful }}>
      {children}
    </LoginContext.Provider>
  );
};

export const useLoginContext = () => {
  const context = useContext(LoginContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
