import React, { PropsWithChildren } from 'react';

interface ButtonProps {
  onClick: CallableFunction;
  disabled?: boolean;
  className?: string;
  outline?: boolean;
}

const Button = ({
  children,
  onClick = () => {},
  disabled = false,
  className,
  outline = false

}: PropsWithChildren<ButtonProps>) => {

  const handleClick = () => {
    onClick()
  }

  return (
    <button
      type="button"
      onClick={handleClick}
      disabled={disabled}
      className={`
      ${className}
      flex rounded-full px-4 py-2.5 font-semibold ring-1
      ${outline ? 'ring-gray-400 hover:ring-gray-300' : 'ring-gray-100 hover:ring-gray-200 hover:bg-gray-50 '}
      disabled:opacity-50
      disabled:pointer-events-none
      transition-color ease-in-out duration-200
      `}
    >
      {children}
    </button>
  )
}

export default Button;