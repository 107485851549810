import BaseService from './BaseService';

export interface CustomerProps {
    cpf: string;
    first_name: string;
    id: number;
    is_confirmed: boolean;
    last_name: string;
    name: string;
}

export interface RegisterProps {
  mobile_phone: string;
  first_name: string;
  last_name: string;
}

export interface GenerateTokenProps {
  number: string;
}

export interface ValidateTokenProps {
  user_token: string;
  user_hash: string;
  phone_number: string;
}

export interface GetCardsProps {
  store_id: string;
}

export interface GetCartOpenProps {
  store_id: number;
  order_type: number;
}

export interface RegisterCardProps {
  name: string;
  number: number;
  expiration_month: number;
  expiration_year: number;
  cvc: number;
  holder_document: number;
  billing_country: string;
  billing_zip_code: number;
  billing_state: string;
  billing_city: string;
  billing_neighborhood: string;
  billing_street: string;
  billing_street_number: number;
  store_id: number;
  main: boolean;
  card?: any;
}

export default class CustomerService {
  baseService: BaseService;

  constructor(orderType: string, token?: string) {
    this.baseService = new BaseService(orderType, token);
  }

  async searchByPhone(phone: string): Promise<any> {
    try {
      const payload = { phone_number: phone };
      const response = await this.baseService.http.post<{ customer: CustomerProps }>('customers/validate', payload);
      return response.data.customer;
    } catch (error) {
      console.error('Erro customer:', error);
      throw error;
    }
  }

  async register(payload: RegisterProps): Promise<any> {
    try {
      const response = await this.baseService.http.post<{ payload: RegisterProps }>('customers/register', payload);
      return response.data;
    } catch (error) {
      console.error('Erro register:', error);
      throw error;
    }
  }

  async generateToken(payload: GenerateTokenProps): Promise<any> {
    try {
      const response = await this.baseService.http.post<{ payload: GenerateTokenProps }>('customers/generate-token', payload);
      return response.data;
    } catch (error) {
      console.error('Erro generate token:', error);
      throw error;
    }
  }

  async validateToken(payload: ValidateTokenProps): Promise<any> {
    try {
      const response = await this.baseService.http.post<{ payload: ValidateTokenProps }>('customers/validate-token', payload);
      return response.data;
    } catch (error) {
      console.error('Erro validate token:', error);
      throw error;
    }
  }

  async getCards(params: GetCardsProps): Promise<any> {
    try {
      const response = await this.baseService.http.get<{ params: GetCardsProps }>(`customers/cards?store_id=${params.store_id}`);
      return response.data;
    } catch (error) {
      console.error('Erro getCards:', error);
      throw error;
    }
  }

  async registerCard(payload: RegisterCardProps): Promise<any> {
    try {
      const response = await this.baseService.http.post<RegisterCardProps>('customers/cards/create', payload);
      return response.data.card;
    } catch (error) {
      console.error('Erro registerCard:', error);
      throw error;
    }
  }

  async getCartOpen(params: GetCartOpenProps): Promise<any> {
    try {
      const response = await this.baseService.http.get<{ params: GetCartOpenProps }>(`cart/open-cart?store_id=${params.store_id}&order_type=${params.order_type} `);
      return response.data;
    } catch (error) {
      console.error('Erro getCartOpen:', error);
      throw error;
    }
  }

  async getOrders(): Promise<any> {
    try {
      const response = await this.baseService.http.get('customers/orders');
      return response.data.orders;
    } catch (error) {
      console.error('Erro getOrders:', error);
      throw error;
    }
  }

}
