import React, { Fragment, useEffect, useState } from 'react';
import ButtonClose from '../ButtonClose';
import { TbCircleCheckFilled } from 'react-icons/tb';
import { useStoreContext } from '../../../Contexts/StoreContext';
import { useCartContext } from '../../../Contexts/CartContext';
import Scrollbars from 'react-custom-scrollbars-2';
import ButtonPrimary from '../../Form/ButtonPrimary';
import Button from '../../Form/Button';
import { MdTableRestaurant } from 'react-icons/md';

interface TableChoiceProps {
    onShowConfirmTable: CallableFunction;
    onHandleSend: CallableFunction;
}

const TableChoice = ({
    onShowConfirmTable,
    onHandleSend
}: TableChoiceProps) => {

    const { tables, table, setTable } = useStoreContext();
    const { cartDisplay, setCartDisplay, showTablesToChoice, setShowTablesToChoice } = useCartContext();
    const [showShadow, setShowShadow] = useState(false);
    const [currentTable, setCurrentTable] = useState<any | null>(null);

    const handleScrollFrame = (values: any) => {
        const { scrollTop, scrollHeight, clientHeight } = values;
        // Verifica se o scroll está no início ou no fim para controlar a sombra
        if (scrollTop >= 0 && scrollTop + clientHeight < scrollHeight) {
            setShowShadow(true);
        } else {
            setShowShadow(false);
        }
    }

    useEffect(() => {
        //* Exibição da sombra dos itens quando houver scroll
        const boxScroll = document.getElementById('boxScroll');
        const scrollContent = document.getElementById('scrollContent');

        if (boxScroll && scrollContent) {
            const boxScrollHeight = boxScroll.getBoundingClientRect().height;
            const scrollContentHeight = scrollContent.getBoundingClientRect().height;
            setShowShadow(scrollContentHeight > boxScrollHeight)
        }

        setShowTablesToChoice(!cartDisplay?.table); //* Utilizado para exibir seleção de mesas quando não houve mesas selecionada no state
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tables])

    const handleChangeTable = (table: any) => {
        setCurrentTable(table);
        setShowTablesToChoice(false);
        if (cartDisplay) {
            // setShowTablesToChoice(false);
            setCartDisplay((prevState: any) => ({
                ...prevState,
                table: {
                    ...prevState.table,
                    id: table.id,
                    name: table.name
                }
            }));
        } else {
            setTable(table.id);
        }
    }

    return (
        <>
            {cartDisplay && <ButtonClose onClick={() => onShowConfirmTable(false)} />}
            <div className='flex flex-col justify-between'>
                <div className='p-4 pt-6 flex flex-col items-center'>
                    <MdTableRestaurant size={40} className='zoomInIcon' />
                    <div className='flex flex-col w-full gap-2 mt-2'>
                        <span className="font-extrabold w-full text-center text-2xl leading-6 text-shopfood-alert">
                            {!showTablesToChoice ? 'Confirme a sua mesa' : 'Informe em qual mesa você está no momento.'}
                        </span>
                        <span className="font-bold w-full text-center text-base leading-5 px-2">
                            Precisamos desta informação para que o pedido chegue até você ;)
                        </span>
                        {(showTablesToChoice && cartDisplay?.table?.name) &&
                            <span className='text-sm font-medium w-full text-center leading-4 text-gray-500 px-2'>
                                Sua mesa atual: {cartDisplay?.table?.name}
                            </span>
                        }
                    </div>
                </div>
                {!showTablesToChoice &&
                    <div className='flex flex-col items-center justify-center w-full p-6 bg-gray-100'>
                        <span className='text-3xl font-extrabold text-custom-primary fadeInIcon'>{cartDisplay ? cartDisplay?.table?.name : currentTable ? currentTable.name : null}</span>
                        <div className='text-sm w-full text-center leading-4'>Este será o local de entrega do seu pedido.</div>
                    </div>
                }

                {(!showTablesToChoice && cartDisplay?.table?.name) &&
                    <span className='text-sm font-medium w-full text-center leading-4 text-gray-500 px-4 mt-3 mb-1'>
                        O nome ou número da sua mesa deve estar em um display ou colado na própria mesa.
                    </span>
                }

                {showTablesToChoice &&
                    <div className='relative h-full min-h-24' id="boxScroll">
                        <Scrollbars autoHeight={true} autoHeightMin="100%" autoHeightMax='50vh' autoHide={true} className='mb-2' onScrollFrame={handleScrollFrame}>
                            <div className="flex flex-col p-4 pt-0 pb-2" id='scrollContent'>
                                {
                                    tables?.map((tableCategory: any, index: number) => (
                                        <Fragment key={index} >
                                            <div className={`text-lg font-extrabold flex items-center gap-4 ${index > 0 && 'mt-6'} mb-3`}>
                                                <hr className='w-full' />
                                                <span className='text-nowrap text-custom-primary'>{tableCategory.name}</span>
                                                <hr className='w-full' />
                                            </div>
                                            <div className='grid grid-cols-3 gap-2 w-full text-xs'>
                                                {tableCategory.tables.map((table: any, index: number) => 
                                                    <Fragment key={index}>
                                                        {(cartDisplay && cartDisplay.table && parseInt(table.id) === parseInt(cartDisplay.table.id)) || (currentTable?.id === parseInt(table.id))
                                                            ? 
                                                                <ButtonPrimary outline onClick={() => {}} className='relative w-full justify-center items-center leading-4 bg-white rounded-md pointer-events-none'>
                                                                    <TbCircleCheckFilled size={18} className='absolute -right-1.5 -top-1.5 bg-white rounded-full' />
                                                                    {table?.name}
                                                                </ButtonPrimary>
                                                            : <Button onClick={() => handleChangeTable(table)} className='w-full justify-center items-center leading-4 bg-white rounded-md font-regular'>{ table.name }</Button>
                                                        }
                                                    </Fragment>
                                                )}
                                            </div>
                                        </Fragment>
                                    ))
                                }
                            </div>
                            <div className='text-xs flex items-center gap-4 my-2'>
                                <hr className='w-full border-gray-100 border-dashed' />
                                <span className='text-nowrap text-gray-200'>Fim das mesas</span>
                                <hr className='w-full border-gray-100 border-dashed' />
                            </div>
                        </Scrollbars>
                        <div className={`absolute bottom-0 w-full h-2 shadow-reverse-md transition ${showShadow ? 'opacity-100' : 'opacity-0'}`}></div>
                    </div>
                }

                {(cartDisplay?.table || table) &&
                    <div className={`flex w-full gap-2 justify-center p-6 ${showTablesToChoice && 'pt-2'}`}>
                        <ButtonPrimary onClick={() => onHandleSend()} className='text-sm w-full items-center justify-center item-center leading-4'>Está correta!</ButtonPrimary>
                        <ButtonPrimary outline onClick={() => setShowTablesToChoice(!showTablesToChoice)} className='text-sm justify-center w-full items-center leading-4'>
                            {!showTablesToChoice ? 'Mudar mesa' : 'Cancelar'}
                        </ButtonPrimary>
                    </div>
                }

            </div>
        </>
    );
};

export default TableChoice;
