import React from 'react';
import { formatCurrency } from '../../../Utils/Formatters';
import { PRODUCT_TYPE_COMPOSITE } from '../../../Utils/Constants/Product';

interface ProductPriceProps {
    product: any;
}

const ProductPrice = ({
    product
}: ProductPriceProps) => {
    return (
        <>
            {product.type === PRODUCT_TYPE_COMPOSITE
                ?   `A partir de ${formatCurrency(product.default_price)}`
                :   <>
                        {parseFloat(product.from_price) > 0 && <><span className='line-through'>{ formatCurrency(product.from_price) }</span> por </> }
                        {formatCurrency(product.price)}
                    </>
            }
        </>
    );
};

export default ProductPrice;
