import React from 'react';

interface SelectedProductProps {
    quantity: number;
    className?: string;
}

const SelectedProduct = ({
    quantity,
    className
}: SelectedProductProps) => {

    return (
        <div className={`absolute w-full h-full flex items-center justify-center font-medium bg-gray-800 bg-opacity-0 rounded-t-lg ${className}`}>
            <span className="bg-shopfood-notify z-25 rounded-full w-6 h-6 md:w-7 md:h-7 flex items-center justify-center absolute -top-1 -right-1 md:-top-1.5 md:-right-1.5 zoomInIcon">
                <span className="text-xs md:text-sm font-extrabold leading-none text-white">{quantity}</span>
            </span>
            <span className="bg-shopfood-notify z-20 rounded-full w-6 h-6 md:w-7 md:h-7 flex items-center justify-center absolute -top-1 -right-1 md:-top-1.5 md:-right-1.5 animate-ping"></span>
        </div>
    );
};

export default SelectedProduct;
