export const METHOD_PAYMENT_TYPE_CARD_ONLINE = 1;
export const METHOD_PAYMENT_TYPE_CARD_DELIVERY = 2;
export const METHOD_PAYMENT_TYPE_MONEY = 3;
export const METHOD_PAYMENT_TYPE_VOUCHER = 4;
export const METHOD_PAYMENT_TYPE_PIX = 5;
export const METHOD_PAYMENT_TYPE_WALLET = 6;

export const ORDER_STATUS_CANCELED = 0;
export const ORDER_STATUS_PENDING = 1;
export const ORDER_STATUS_OPEN = 2;
export const ORDER_STATUS_MOUNTING = 3;
export const ORDER_STATUS_RELEASED_SHIPMENT = 4;
export const ORDER_STATUS_EXPEDITED = 5;
export const ORDER_STATUS_RETURNED = 6;
export const ORDER_STATUS_RELEASED_TAKEAWAY = 8;
export const ORDER_STATUS_DONE = 7;
export const ORDER_STATUS_SCHEDULED = 9;
export const ORDER_STATUS_AWAITING_PAYMENT = 10;
export const ORDER_STATUS_EXPIRED = 11;

export const ORDER_STATUS_NAME: { [key: number]: string | null } = {
    [ORDER_STATUS_CANCELED]: 'Cancelado',
    [ORDER_STATUS_PENDING]: 'Novo',
    [ORDER_STATUS_OPEN]: 'Aberto',
    [ORDER_STATUS_MOUNTING]: 'Montagem',
    [ORDER_STATUS_RELEASED_SHIPMENT]: 'Liberado para entrega',
    [ORDER_STATUS_EXPEDITED]: 'Expedido',
    [ORDER_STATUS_RETURNED]: 'Retornado',
    [ORDER_STATUS_RELEASED_TAKEAWAY]: 'Liberado para retirada',
    [ORDER_STATUS_DONE]: 'Finalizado',
    [ORDER_STATUS_SCHEDULED]: 'Agendado',
    [ORDER_STATUS_AWAITING_PAYMENT]: 'Aguardando pagamento',
    [ORDER_STATUS_EXPIRED]: 'Expirado',
}